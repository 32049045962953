import { Box, Typography } from '@mui/material';
import {
  ImplementedContentModel,
  Question,
  QuestionContentModel,
  Question_QuestionEnum,
} from '@protos/content_management/content.db_pb';
import {
  QAttemptResultEnum,
  StudentTaskResponse,
  TaskEvaluationStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { useEffect, useState } from 'react';
import { pxToRem, pxTovW } from '../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../commonUtils/styleUtils';
import { getDifficultyLevelString } from '../../../../commonUtils/utilFunctions';
import { IconWrapper } from '../../../elements/IconWrapper/Index';
import ImageWrapper from '../../../elements/ImageWrapper';
import ElementRenderer from '../../ElementRenderer';
import { SelectedResourceInfo } from '../../VideoPlayerWrapper/VideoPlayerWrapper';
import { FlagHintBox } from '../FlagHintBox';
import { HintPopup } from '../FlagHintBox/HintPopup';
import { QuestionAnswerAccordion } from '../QuestionAnswerAccordion';
import QuestionContainer, {
  QuestionContainerProps,
} from '../QuestionContainer';
import { GeneoLottie } from '../QuestionContainer/lottie/GeneoLottie';
import { correctTick } from '../QuestionContainer/lottie/correctTick';
import { wrongTick } from '../QuestionContainer/lottie/wrongTick';
import TypographyHtml from '../TypographyHtml';
import { getSolutionText, isCorrectAnswer } from '../question-utils';
import { AnswerStatusMarksEdit } from './AnswerStatusMarksEdit/AnswerStatusMarksEdit';
import { RemarksDisplay } from './AnswerStatusMarksEdit/RemarksDisplay';
import {
  changeBgColor,
  changeBgColorReverse,
  easeIn,
  hidebox,
  moveForward,
  showBox,
} from './animationKeyframes';

export interface IUpdateHWTeacherReviewRequestParams {
  questionId: string;
  sessionId: number;
  remarks: string[];
  obtainedMarks: number;
}
export interface QuestionEventCollection {
  hintBoxOpen?: () => void;
  hintBoxOk?: () => void;
  answerSelection?: () => void;
  submitAnswer?: () => void;
}
interface QuestionContainerWithSolutionProps
  extends Omit<QuestionContainerProps, 'questionContent'> {
  question?: Question;
  accordionPosition?: 'inside' | 'outside';
  showHintIcon?: boolean;
  showAnswerAnimation?: boolean;
  showQuestionStats?: boolean;
  taskEvaluationStatus?: TaskEvaluationStatusEnum;
  currentResponse?: StudentTaskResponse;
  saveClickHandlerInsideModal?: (
    reqObj: IUpdateHWTeacherReviewRequestParams
  ) => void;
  selected_resource_info?: SelectedResourceInfo;
  eventCollection?: QuestionEventCollection;
  questionTypeDisplay?: boolean;
  instuctionsDisplay?: boolean;
  defaultExpandedAccordion?: boolean;
}

const quesWrapper = {
  width: 'auto',
  border: `1px solid #CED2FC`,
  borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
  backgroundColor: 'common.white',
};

const quesBox = {
  padding: {
    xs: `${pxToRem(20)} ${pxToRem(10)}`,
    md: `${pxTovW(20)} ${pxTovW(50)}`,
  },
  minHeight: '200px',
};

const answerStatus = {
  backgroundColor: '#E3FEEF',
  borderTopLeftRadius: { xs: pxToRem(15), md: pxTovW(15) },
  borderTopRightRadius: { xs: pxToRem(15), md: pxTovW(15) },
  paddingX: { xs: pxToRem(10), md: pxTovW(50) },
  paddingY: { xs: pxToRem(10), md: pxTovW(8) },
  display: 'flex',
  alignItems: 'center',
};

const styles: IStyles = {
  questionWrapper: quesWrapper,
  questionWrapperAnimate: {
    ...quesWrapper,
    animation: `${changeBgColor} 1s linear`,
    animationFillMode: 'forwards',
    position: 'relative',
  },
  questionWrapperAnimateOut: {
    ...quesWrapper,
    animation: `${changeBgColorReverse} 1s linear`,
    animationFillMode: 'forwards',
    position: 'relative',
  },
  outerAccordionBox: {
    width: 'auto',
    border: `1px solid #CED2FC`,
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    marginTop: { xs: pxToRem(10), md: pxTovW(10) },
    overflow: 'hidden',
  },
  questionBox: quesBox,
  hiddenQuestionBox: {
    ...quesBox,
    animation: `${hidebox} 1s linear`,
    pointerEvents: 'none',
    animationFillMode: 'forwards',
  },
  showQuestionBox: {
    ...quesBox,
    animation: `${showBox} 1s linear`,
    pointerEvents: 'none',
    animationFillMode: 'forwards',
  },
  lottiePosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '160px',
    textAlign: 'center',
  },
  lottiePositionEaseOut: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animationName: `${moveForward}`,
    animationDuration: '1s',
    animationFillMode: 'forwards',
    height: '160px',
    textAlign: 'center',
  },
  accordionEaseIn: {
    animationName: `${easeIn}`,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  textAppear: {
    animation: `${showBox} 4s linear`,
    animationFillMode: 'forwards',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  answerStatusWrapper: {
    ...answerStatus,
  },
  answerStatusEaseIn: {
    ...answerStatus,
    animationName: `${easeIn}`,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  correctIcon: {
    height: { xs: pxToRem(25), md: pxTovW(40) },
    width: { xs: pxToRem(25), md: pxTovW(40) },
  },
  questionStatsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: { xs: pxToRem(20), md: pxTovW(30) },
  },
  questionStats: {
    display: 'flex',
    alignItems: 'center',
  },
  questionTitle: {
    width: 'max-content',
    borderRadius: '20px',
    padding: '2px 10px',
    backgroundColor: '#007CDC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: pxToRem(4),
  },
  questionTitleIcon: {
    height: {
      xs: pxToRem(14),
      md: pxToRem(12),
      lg: pxTovW(18),
    },
  },
  instructions: {
    borderTop: '1px solid #CED2FC',
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(10)}`,
      md: `${pxTovW(20)} ${pxTovW(50)}`,
    },
  },
};

export const QuestionContainerWithSolution = (
  props: QuestionContainerWithSolutionProps
) => {
  const {
    question,
    accordionPosition,
    showHintIcon,
    showAnswerAnimation,
    showQuestionStats,
    currentResponse,
    saveClickHandlerInsideModal,
    selected_resource_info,
    eventCollection,
    questionTypeDisplay,
    instuctionsDisplay,
    defaultExpandedAccordion,
    ...questionContainerProps
  } = props;
  // console.log('currentResponse:', currentResponse);

  const [showHint, setShowHint] = useState<boolean>(false);
  const [showAnimation, setShowAnimation] = useState<
    'starting' | 'ending' | undefined
  >(undefined);

  const [editMarksModal, setEditMarksModal] = useState(false);
  // const [remarks, setRemarks] = useState(currentResponse?.remarks || []);

  const checkSubjectiveQuestion = () => {
    if (
      question?.questionType ===
        Question_QuestionEnum.QUESTION_TYPE_VERY_SHORT ||
      question?.questionType === Question_QuestionEnum.QUESTION_TYPE_SHORT ||
      question?.questionType === Question_QuestionEnum.QUESTION_TYPE_LONG
    ) {
      return true;
    } else {
      return false;
    }
  };

  const userAttemptedAnswer = questionContainerProps.userAttemptedAnswer;

  const isCorrect = isCorrectAnswer(
    question,
    userAttemptedAnswer,
    currentResponse?.isCorrect
  );
  const tickImageName =
    isCorrect === QAttemptResultEnum.RESPONSE_CORRECT ? 'correct' : 'wrong';
  const correctAnswerText =
    isCorrect === QAttemptResultEnum.RESPONSE_CORRECT
      ? 'Correct'
      : isCorrect === QAttemptResultEnum.RESPONSE_INCORRECT
      ? 'Wrong'
      : isCorrect === QAttemptResultEnum.RESPONSE_PARTIALLY_CORRECT
      ? 'Partially Correct'
      : 'Not Attempted';

  const showMarksEditor = () => {
    // return true;
    // return userRole === 'teacher' && checkSubjectiveQuestion();
    // return checkSubjectiveQuestion();
    return checkSubjectiveQuestion() && correctAnswerText !== 'Not Attempted';
  };

  useEffect(() => {
    return () => {
      setShowAnimation(undefined);
      setEditMarksModal(false);
    };
  }, []);
  useEffect(() => {
    //closing model first on question change cuz we are using one variable instead of mapping
    setEditMarksModal(false);
  }, [question]);

  if (!question) {
    return null;
  }

  const solution =
    new QuestionContentModel(question.question).model.value
      ?.commonQuestionContent?.solution || [];
  const hint =
    question.question?.model.value?.commonQuestionContent?.hint || [];

  const getAccordionHeading = () => {
    return (
      <Typography variant="elementH3">
        {accordionPosition === 'outside' ? 'Answer' : 'Correct Answer'}
      </Typography>
    );
  };

  const getAccordionBody = () => {
    const correctAns = getSolutionText(
      new Question(props.question),
      props.resourceCategory
    );
    return (
      <>
        {typeof correctAns === 'string' ? (
          <TypographyHtml variant="elementH3">{correctAns}</TypographyHtml>
        ) : (
          correctAns
        )}
        {solution.length > 0 && (
          <Box sx={{ marginTop: '20px' }}>
            {
              <ElementRenderer
                elements={solution}
                resourceCategory={props.resourceCategory}
              />
            }
          </Box>
        )}
      </>
    );
  };

  const renderAccordion = () => {
    const solutionText = getSolutionText(
      new Question(props.question),
      props.resourceCategory
    );
    const solutionExists = solution.length > 0 ? true : false;
    if (!solutionText && !solutionExists) {
      return null;
    }
    if (showAnimation === 'starting') {
      return null;
    }
    if (showAnimation === 'ending') {
      return (
        <Box sx={styles.accordionEaseIn}>
          <QuestionAnswerAccordion
            key={'accordion_' + props.questionNumber}
            panelNumber={'accordion_' + props.questionNumber}
            accordionHeading={getAccordionHeading()}
            accordionBody={getAccordionBody()}
            defaultExpanded={defaultExpandedAccordion}
          />
        </Box>
      );
    }
    return (
      <QuestionAnswerAccordion
        key={'accordion_' + props.questionNumber}
        panelNumber={'accordion_' + props.questionNumber}
        accordionHeading={getAccordionHeading()}
        accordionBody={getAccordionBody()}
        defaultExpanded={defaultExpandedAccordion}
      />
    );
  };

  const renderAnswerStatus = () => {
    if (showAnimation === 'starting') {
      return null;
    }
    return (
      <Box
        sx={
          showAnimation === 'ending'
            ? styles.answerStatusEaseIn
            : styles.answerStatusWrapper
        }
      >
        {currentResponse &&
        checkSubjectiveQuestion() &&
        currentResponse?.taskEvaluationStatus !==
          TaskEvaluationStatusEnum.TASK_EVALUATION_STATUS_COMPLETED ? (
          <>
            <ImageWrapper
              name="loading_lines_lightblue"
              type="png"
              parentFolder="icons"
              styles={{
                height: { xs: pxToRem(35), md: pxTovW(45) },
                mr: { xs: pxToRem(15), md: pxTovW(15) },
              }}
            />
            <Typography variant="elementH3" fontWeight="bold">
              Answer is being evaluated. Check again in some time.
            </Typography>
          </>
        ) : (
          <>
            <ImageWrapper
              name={tickImageName}
              type="png"
              parentFolder="icons"
              styles={styles.correctIcon}
            />
            <Typography
              variant="elementBodyText"
              sx={{
                flexGrow: 1,
                textAlign: showMarksEditor() ? 'left' : 'center',
                pl: showMarksEditor() ? pxTovW(10) : '0px',
              }}
              fontWeight="bold"
            >
              {/* {answerStatus} */}
              {correctAnswerText}
            </Typography>
            {/* auto eval part */}
            <AnswerStatusMarksEdit
              sessionId={currentResponse?.sessionId}
              questionId={currentResponse?.questionId}
              editMarksModal={editMarksModal}
              setEditMarksModal={setEditMarksModal}
              receivedMarks={currentResponse?.responseScore || 0}
              totalMarks={currentResponse?.maxScore || 0}
              // remarks={remarks}
              // setRemarks={setRemarks}
              responseRemarks={currentResponse?.remarks || []}
              editClickHandler={() => {
                // testing function
                console.log('editClickHandler');
              }}
              saveClickHandlerInsideModal={saveClickHandlerInsideModal}
              rootStyle={{
                display: showMarksEditor() ? 'flex' : 'none',
              }}
            />
          </>
        )}
      </Box>
    );
  };

  // const renderEvaluatingAnswerStatus = () => {
  //   if (showAnimation === 'starting') {
  //     return null;
  //   }
  //   return (
  //     <Box sx={{ ...styles.answerStatusWrapper }}>
  //       <ImageWrapper
  //         name="loading_lines_lightblue"
  //         type="png"
  //         parentFolder="icons"
  //         styles={{
  //           height: { xs: pxToRem(35), md: pxTovW(45) },
  //           mr: { xs: pxToRem(15), md: pxTovW(15) },
  //         }}
  //       />
  //       <Typography variant="h3" fontWeight="bold">
  //         Answer is being evaluated. Check again in some time.
  //       </Typography>
  //     </Box>
  //   );
  // };

  const renderQuestionStats = () => {
    return (
      <Box sx={styles.questionStatsWrapper}>
        <Box sx={styles.questionStats}>
          <IconWrapper
            name="clock"
            size="small"
            type="png"
            parentFolder="icons"
          />
          <Typography variant="h5" sx={{ marginLeft: '5px' }}>
            {Number(
              (
                (new Question(question).question?.model.value
                  ?.commonQuestionContent?.time || 0) / 60
              ).toFixed(2)
            )}{' '}
            Mins
          </Typography>
        </Box>
        <Box sx={styles.questionStats}>
          <IconWrapper
            name="level"
            size="small"
            type="png"
            parentFolder="icons"
          />
          <Typography variant="h5" sx={{ marginLeft: '5px' }}>
            {getDifficultyLevelString(
              new Question(question).questionMeta?.difficultyLevel
            )}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={
          showAnimation === 'starting'
            ? styles.questionWrapperAnimate
            : showAnimation === 'ending'
            ? styles.questionWrapperAnimateOut
            : styles.questionWrapper
        }
      >
        {questionContainerProps.showAnswer &&
          questionContainerProps.isSubmitted &&
          renderAnswerStatus()}
        {!!showAnimation && ['starting', 'ending'].includes(showAnimation) && (
          <Box
            sx={
              showAnimation === 'ending'
                ? styles.lottiePositionEaseOut
                : styles.lottiePosition
            }
          >
            <GeneoLottie
              animationData={
                tickImageName === 'correct' ? correctTick : wrongTick
              }
              loop={false}
              animationDelay={500}
              onLoopComplete={() => {
                // console.log('completed loop');
                setShowAnimation('ending');
                setTimeout(() => {
                  setShowAnimation(undefined);
                }, 600);
              }}
              style={{
                height: '100px',
              }}
            />
            <Typography variant="h2" sx={styles.textAppear}>
              {correctAnswerText}
              {/* {answerStatus} */}
            </Typography>
          </Box>
        )}
        <Box
          sx={
            showAnimation === 'starting'
              ? styles.hiddenQuestionBox
              : showAnimation === 'ending'
              ? styles.showQuestionBox
              : styles.questionBox
          }
        >
          <Box>
            {questionTypeDisplay &&
              questionTypeTitle(new Question(question).question) && (
                <Box sx={styles.questionTitle}>
                  <ImageWrapper
                    name="questionType"
                    type="png"
                    parentFolder="icons"
                    styles={styles.questionTitleIcon}
                  />
                  <Typography variant="h4" color={'white'}>
                    {questionTypeTitle(new Question(question).question)}
                  </Typography>
                </Box>
              )}
            <QuestionContainer
              questionContent={new Question(question).question}
              {...questionContainerProps}
              handleNext={async () => {
                if (questionContainerProps.handleNext) {
                  await questionContainerProps.handleNext();
                  if (showAnswerAnimation) {
                    setShowAnimation('starting');
                  }
                }
              }}
              selected_resource_info={selected_resource_info}
              eventCollection={eventCollection}
            />
          </Box>
          {showQuestionStats && renderQuestionStats()}

          {checkSubjectiveQuestion() && currentResponse && (
            <Box
              sx={{
                mt: pxToRem(10),
              }}
            >
              <Typography variant="h2">Student's Attempt:</Typography>
              <Box
                sx={{
                  p: pxToRem(10),
                  border: '1px solid #E0DFDE',
                  maxHeight: '350px',
                  overflow: 'auto',
                }}
              >
                {currentResponse.responses.map((e) => (
                  <Typography variant="bodyText">{e.answer}</Typography>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        {questionContainerProps.showAnswer &&
          accordionPosition !== 'outside' &&
          renderAccordion()}

        {instuctionsDisplay &&
          questionTypeInstructions(new Question(question).question) && (
            <Box sx={styles.instructions}>
              <span>
                <b>Instructions:</b>
              </span>
              <Typography variant="h4">
                {questionTypeInstructions(new Question(question).question)}
              </Typography>
            </Box>
          )}
        <RemarksDisplay
          remarks={currentResponse?.remarks || []}
          showRemarksEditIcon={
            currentResponse?.taskEvaluationStatus ===
            TaskEvaluationStatusEnum.TASK_EVALUATION_STATUS_COMPLETED
          }
          editMarksModal={editMarksModal}
          setEditMarksModal={setEditMarksModal}
        />
      </Box>

      {showHintIcon && hint.length > 0 && (
        <Box>
          <FlagHintBox
            hintClickHandler={() => {
              setShowHint(true);
              eventCollection?.hintBoxOpen && eventCollection?.hintBoxOpen();
            }}
          />
        </Box>
      )}
      {questionContainerProps.showAnswer && accordionPosition === 'outside' && (
        <Box sx={styles.outerAccordionBox}>{renderAccordion()}</Box>
      )}
      <HintPopup
        text={hint.join(' ')}
        modalState={showHint}
        setModalState={(arg) => {
          setShowHint(arg);
          eventCollection?.hintBoxOk && eventCollection?.hintBoxOk();
        }}
      />
    </>
  );
};

export const questionTypeTitle = (QuestionContent?: QuestionContentModel) => {
  if (!QuestionContent) {
    return undefined;
  }
  const quesType = QuestionContent.model.case;
  switch (quesType) {
    case 'mcqSingleContentModel':
      return 'MCQ Single';
    case 'mcqMultipleContentModel':
      return 'MCQ Multiple';
    case 'tfContentModel':
      return 'True/False';
    default:
      return undefined;
  }
};

export const questionTypeInstructions = (
  QuestionContent?: QuestionContentModel
) => {
  if (!QuestionContent) {
    return undefined;
  }
  const quesType = QuestionContent.model.case;
  switch (quesType) {
    case 'mcqSingleContentModel':
      return 'To choose your answer press 1A, 2B, 3C, 4D. e.g: Press "1A" for option "A".';
    case 'mcqMultipleContentModel':
      return 'To choose your answer press 1A, 2B, 3C, 4D. e.g: Press "1A" for option "A" and "1B" for option "B" etc. After choosing the answer Press OK to submit the answer.';
    case 'tfContentModel':
      return 'To choose your answer press 1A, 2B. e.g: Press "1A" for "TRUE" and "2B" for "FALSE".';
    default:
      return undefined;
  }
};
