import React, { useEffect } from 'react';

import VideoPlayer from '../../elements/VideoPlayer';

import {
  videoPlay,
  videoStop,
} from '@events/student/eventsCreator/resourcePageContent';
import {
  Content,
  PageContentInfo,
  Question,
  ResourceCategoryEnum,
  ResourceTeacherInstruction,
} from '@protos/content_management/content.db_pb';
import { QAttemptResultEnum } from '@protos/learning_management/lms.db_pb';

export interface SelectedResourceInfo {
  //refer from learn.slice
  pageNumber?: number;
  resourceId?: string;
  pageContent?: PageContentInfo[];
  resourceAnswerStatus: QAttemptResultEnum;
  currentAnswer: string[];
}
interface IProps {
  videoUrl: string;
  options?: any;
  selected_resource_info?: SelectedResourceInfo;
  resourceCategory?: ResourceCategoryEnum;
}
export const VideoPlayerWrapper = (props: IProps) => {
  const { videoUrl, options, selected_resource_info, resourceCategory } = props;
  let videoPlayed = false;

  // console.log(
  //   'selected_resource_info: VideoPlayerWrapper',
  //   selected_resource_info
  // );

  // rpc - cvc - vw*

  // const [respectiveId, setRespectiveId] = useState<string | undefined>();

  useEffect(() => {
    // let pageContent = selected_resource_info?.pageContent;
    // if (pageContent) {
    //   pageContent = pageContent.map((val) => new PageContentInfo(val));
    // }
    // const selectedPageContent = selected_resource_info?.pageNumber
    //   ? pageContent?.[selected_resource_info.pageNumber - 1]
    //   : pageContent?.[0];
    // selectedPageContent?.contents.forEach((con) => {
    //   const contentValue = con.model.value;
    //   // for now only one id is being taken
    //   if (contentValue instanceof Content) {
    //     console.log('contentValue.contentId:', contentValue.contentId);
    //     setRespectiveId(contentValue.contentId);
    //   } else if (contentValue instanceof Question) {
    //     console.log('contentValue.questionId:', contentValue.questionId);
    //     setRespectiveId(contentValue.questionId);
    //   } else if (contentValue instanceof ResourceTeacherInstruction) {
    //     console.log('contentValue.resourceId:', contentValue.resourceId);
    //     setRespectiveId(contentValue.resourceId);
    //   }
    // });
  }, [selected_resource_info]);

  const getIdFromData = (selected_resource_info?: SelectedResourceInfo) => {
    let pageContent = selected_resource_info?.pageContent;
    if (pageContent) {
      pageContent = pageContent.map((val) => new PageContentInfo(val));
    }
    const selectedPageContent = selected_resource_info?.pageNumber
      ? pageContent?.[selected_resource_info.pageNumber - 1]
      : pageContent?.[0];

    let retValue;
    selectedPageContent?.contents.forEach((con) => {
      const contentValue = con.model.value;
      // for now only one id is being taken
      if (contentValue instanceof Content) {
        // setRespectiveId(contentValue.contentId);
        retValue = contentValue.contentId;
      } else if (contentValue instanceof Question) {
        // setRespectiveId(contentValue.questionId);
        retValue = contentValue.questionId;
      } else if (contentValue instanceof ResourceTeacherInstruction) {
        // setRespectiveId(contentValue.resourceId);
        retValue = contentValue.resourceId;
      }
    });

    return retValue;
  };

  const idReceived = getIdFromData(selected_resource_info);

  const playerRef = React.useRef(null);

  let isPlaying = false;
  let previousPlayPosition = 0;
  let currentPlayPosition = 0;
  let previousSavedPosition = 0;
  let seekStartPosition: number | null = null;

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // -----------------------------------------------------

    player.on('waiting', () => {
      // console.log('player - Waiting');
    });

    // -----------------------------------------------------

    player.on('play', () => {
      // console.log('player - PLAY');
      videoPlayed = true;

      // const idReceived = getIdFromData(selected_resource_info);
      // console.log('idReceived:', idReceived);
      const playSpeed = player?.playbackRate();
      if (!isPlaying && idReceived) {
        isPlaying = true;
        videoPlay({
          contentId: idReceived,
          playPosition: player.currentTime(),
          speed: playSpeed,
        });
        previousSavedPosition = player.currentTime();
      }
    });

    // -----------------------------------------------------

    player.on('pause', () => {
      // console.log('player - PAUSE');

      // const idReceived = getIdFromData(selected_resource_info);
      // const pauseTime = player.currentTime();
      const pauseTime = previousPlayPosition;
      // console.log('pauseTime:', pauseTime);
      if (isPlaying && idReceived) {
        isPlaying = false;
        videoStop({
          contentId: idReceived,
          stopPosition: pauseTime,
        });
      }
    });

    // -----------------------------------------------------

    player?.on('seeking', () => {
      // console.log('player - Seeking');

      // const idReceived = getIdFromData(selected_resource_info);
      if (seekStartPosition === null) {
        seekStartPosition = previousPlayPosition;
        if (isPlaying && idReceived) {
          isPlaying = false;
          videoStop({
            contentId: idReceived,
            stopPosition: previousPlayPosition,
          });
        }
      }
    });

    // -----------------------------------------------------

    player?.on('seeked', () => {
      // console.log('player - Seeked');

      // const idReceived = getIdFromData(selected_resource_info);
      if (!isPlaying && idReceived) {
        isPlaying = true;
        videoPlay({
          contentId: idReceived,
          playPosition: player.currentTime(),
          speed: player.playbackRate(),
        });
        previousSavedPosition = player.currentTime();
      }
    });

    // -----------------------------------------------------

    player.on('ratechange', () => {
      // console.log('player - Ratechange');
    });

    player?.on('timeupdate', () => {
      // console.log('player - Timeupdate');

      // const idReceived = getIdFromData(selected_resource_info);
      previousPlayPosition = currentPlayPosition;
      currentPlayPosition = player?.currentTime();

      if (isPlaying) {
        if (
          currentPlayPosition === 0 &&
          previousPlayPosition > 0 &&
          idReceived
        ) {
          videoStop &&
            videoStop({
              contentId: idReceived,
              stopPosition: previousPlayPosition,
            });
          //console.error("videoStopBeforeClose", eventData);
          isPlaying = false;
        }
      }
    });

    // -----------------------------------------------------

    player?.on('ended', () => {
      // console.log('player - Ended');

      // const idReceived = getIdFromData(selected_resource_info);
      const endTime = player.currentTime();
      isPlaying = false;
      if (idReceived) {
        videoStop({
          contentId: idReceived,
          stopPosition: endTime,
        });
      }
    });

    // -----------------------------------------------------

    player?.on('error', () => {
      // console.log('player - Error');
    });

    // -----------------------------------------------------
    player.on('dispose', function () {
      // Get the current position of the player
      const currentTime = player.currentTime();
      if (idReceived && videoPlayed) {
        videoStop({
          contentId: idReceived,
          stopPosition: currentTime,
        });
      }
    });
  };

  return (
    <div>
      <VideoPlayer
        options={options}
        videoUrl={videoUrl}
        onReady={(player: any) => handlePlayerReady(player)}
        resourceCategory={resourceCategory}
      />
    </div>
  );
};
