import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import getDeviceType from 'libs/shared-ui/src/commonUtils/getDeviceType';
const styles: IStyles = {
  selectContainer: {
    width: { xs: '100%', md: pxTovW(230) },
    height: { xs: 'max-content', md: pxTovW(75) },
    background: '#DAF5FF',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    '&:focus-visible .MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    borderRadius: { xs: pxToRem(5), md: pxTovW(15) },
    fontSize: { xs: pxToRem(14), md: pxTovW(21) },
    fontWeight: '500',
  },
  clearButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 0,
  },
};

interface IProps {
  placeholder?: string;
  value: string;
  optionList: IOptionList[];
  onChange: (value: string) => void;
  disabled?: boolean;
  clearIcon?: boolean;
  rootStyles?: SxProps;
}

interface IOptionList {
  name: string | number;
  id: number;
  nonEditableItem?: boolean;
  selectedNumber?: number;
}

export const SelectMenu = (props: IProps) => {
  const {
    placeholder,
    value,
    optionList,
    onChange,
    disabled,
    rootStyles,
    clearIcon,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const deviceType = getDeviceType();
  const android = window.Android;
  const handleChange = (event: SelectChangeEvent) => {
    console.log('tttt', event.target);
    onChange(event.target.value);
  };

  const handleClear = () => {
    onChange('');
  };

  const handleOpen = () => {
    try {
      if (deviceType === 'android' && android?.disableAppRefresh) {
        console.log('App refresh disabled on Android for home page');
        android.disableAppRefresh();
      }
    } catch (error) {
      console.error(
        'An error occurred while attempting to disable app refresh on Android:',
        error
      );
    }
  };

  const handleClose = () => {
    try {
      if (deviceType === 'android' && android?.enableAppRefresh) {
        console.log('App refresh enabled on Android for home page');
        android.enableAppRefresh();
      }
    } catch (error) {
      console.error(
        'An error occurred while attempting to enable app refresh on Android:',
        error
      );
    }
  };

  let rootSx = {
    ...styles.selectContainer,
  };
  if (rootStyles) rootSx = { ...rootSx, ...rootStyles };

  return (
    <Box sx={styles.root}>
      <Select
        disabled={disabled ? true : false}
        displayEmpty
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onOpen={handleOpen} // Call handleOpen when Select is opened
        onClose={handleClose} // Call handleClose when Select is closed
        endAdornment={
          clearIcon && (
            <InputAdornment
              sx={{
                marginRight: { xs: pxToRem(15), md: pxTovW(30) },
                marginTop: '5px',
              }}
              position="end"
            >
              <Box
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleClear}
              >
                {value !== '' && (
                  <IconWrapper
                    name="white_x_in_gray"
                    size="md"
                    parentFolder="icons"
                    type="png"
                  />
                )}
              </Box>
            </InputAdornment>
          )
        }
        renderValue={(selected) => {
          if (value === '') {
            return <em>{placeholder}</em>;
          } else {
            return selected;
          }
        }}
        // endAdornment={
        //   clearIcon && value ? (
        //     <InputAdornment position="end">
        //       <IconButton
        //         aria-label="clear selection"
        //         onClick={handleClear}
        //         sx={{ padding: 0 }}
        //       >
        //         <CloseIcon />
        //       </IconButton>
        //     </InputAdornment>
        //   ) : null
        // }
        sx={{
          ...rootSx,
          boxShadow: isFocused
            ? `inset 0 0 1px 0 #61BAFF, inset 0 0 2px 1px rgba(97, 186, 255, 0.6), inset 0 0 3px 2px rgba(97, 186, 255, 0.4), inset 0 0 4px 3px rgba(97, 186, 255, 0.2), inset 0 0 5px 4px rgba(97, 186, 255, 0.1)`
            : 'none',
          // '.MuiSelect-icon': value && clearIcon ? { display: 'none' } : {}, // Hide the dropdown icon when a value is selected
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: { xs: pxToRem(150), md: pxTovW(300) },
              minHeight: { xs: 'max-content', md: 'max-content' },
              backgroundColor: '#F8FCFF',
              '&::-webkit-scrollbar': { display: 'none' },
              padding: 0,
              borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
              '& .MuiList-root': {
                backgroundColor: '#F8FCFF',
                maxHeight: { xs: pxToRem(150), md: pxTovW(300) },
                padding: 0,
              },
              scrollbarWidth: 'thin',
              minWidth: { xs: '100%', md: pxTovW(230) },
              maxWidth: { xs: '100%', md: pxTovW(650) },
            },
          },
        }}
      >
        {optionList.map((e) => (
          <MenuItem
            key={e.id}
            disabled={e.nonEditableItem ? true : false}
            sx={{
              borderBottom: '1px solid #96FFC5',
              wordBreak: 'break-word',
              // height: { xs: pxToRem(30), md: pxTovW(52) },
              '&:last-child': {
                borderBottom: 'none',
              },
              '&:hover': {
                background: '#ECFFF5',
              },
            }}
            value={e.name}
          >
            {e.selectedNumber ? `${e.selectedNumber}. ${e.name}` : e.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
