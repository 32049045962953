import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import {
  ResponseStatusCodeFunction,
  cmsTransport,
  cmsTransportWithLogoutHandler,
} from './transports';
import { TeacherUploadContentAPIServiceV1 } from '@protos/content_management/cms.teacher.content.apis_connect';

export const TeacherAnalysisAPIServiceV1Client = createPromiseClient(
  TeacherUploadContentAPIServiceV1,
  cmsTransport
);

export const useTeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    TeacherUploadContentAPIServiceV1,
    cmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
