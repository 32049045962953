import {
  Button,
  Checkbox,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Box } from '@mui/system';
import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import { useEffect, useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { AnyAction } from '@reduxjs/toolkit';
import { getMediaBasePath } from 'libs/shared-ui/src/commonUtils/images';

interface IlockedPopupData {
  image?: string;
  title?: string;
  noofTopics?: string;
}

const styles: IStyles = {
  radioDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(0) },
  },

  radio: {
    color: '#CCE6FE',
    '& .MuiSvgIcon-root': {
      fontSize: { xs: pxToRem(22), md: pxTovW(34) },
    },
    '&.MuiRadio-root': {
      padding: '0',
    },
  },
};

interface ContentLockPopupProps {
  role?: ProfileRolesEnum;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  selectedValue?: ContentLockStatusType;
  onRadioChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  lockDataObj?: IlockedPopupData;
  handleLockedCheckboxChange?: (check: boolean) => void;
  checked?: boolean;
}
const ContentLockPopup: React.FC<ContentLockPopupProps> = ({
  open,
  onClose,
  onSubmit,
  onRadioChange,
  selectedValue,
  role,
  lockDataObj,
  checked,
  handleLockedCheckboxChange,
}) => {
  const categoryType = (category: string) => {
    switch (category) {
      case 'MODULE_CATEGORY_CHAPTER':
        return Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER;
      case 'MODULE_CATEGORY_TOPIC':
        return Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC;
      default:
        return 'UNDEFINED';
    }
  };

  function getLockStatus(selectedValue: ContentLockStatusType | undefined) {
    switch (selectedValue) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'partial_lock';
      default:
        return 'unknown'; // You can define a default return value if needed
    }
  }

  // --------------------------------------------------------------------------

  // useEffect(() => {
  //   console.log('lockDataObj', lockDataObj);
  // }, [lockDataObj]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: pxToRem(20), md: pxToRem(20) },
        width: { xs: 'unset', md: 'max-content' },
        alignItems: { xs: 'center', md: 'unset' },
        height: 'max-content',
        margin: 'auto',
      }}
    >
      <>
        <Slide
          direction="up"
          in={open}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Box
            sx={{
              margin: 'auto',
              width: { xs: '80%', md: pxTovW(1000), lg: pxTovW(813) },
              // height: { xs: pxToRem(500), md: pxTovW(630) },
              // minHeight: { xs: pxToRem(400), md: pxTovW(670) },
              backgroundColor: 'common.white',
              borderRadius: { xs: pxToRem(10), md: pxToRem(15) },
              paddingX: { xs: pxToRem(20), md: pxToRem(40) },
              paddingY: { xs: pxToRem(20), md: pxToRem(40) },
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: { xs: pxToRem(10), md: pxTovW(25) },
                width: '100%',
              }}
            >
              <ImageWrapper
                name="chapterImage"
                type="png"
                parentFolder="tempAssets"
                styles={{
                  height: { xs: pxToRem(68), md: pxTovW(190) },
                  width: { xs: pxToRem(68), md: pxTovW(190) },
                  objectFit: 'cover',
                  borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
                  opacity:
                    selectedValue ===
                    ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                      ? 0.5
                      : 1,
                }}
                path={getMediaBasePath(
                  lockDataObj?.image,
                  'processedMediaBucket'
                )}
              />
              <Box
                sx={{
                  width: '100%',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  wordBreak: 'break-word',
                }}
              >
                <Typography
                  variant="h1" // Changed to a more appropriate variant for titles
                  gutterBottom
                  sx={{
                    fontWeight: 'bold',
                    textAlign: 'start',
                    marginBottom: 2, // Using theme.spacing
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    opacity:
                      selectedValue ===
                      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                        ? 0.5
                        : 1,
                  }}
                >
                  {lockDataObj?.title}
                </Typography>

                {lockDataObj?.noofTopics && (
                  <Typography variant="h2" color="primary">
                    {lockDataObj?.noofTopics} Topics
                  </Typography>
                )}
              </Box>
              <IconButton
                size="small"
                sx={{ position: 'relative', top: '-6px', right: '-15px' }}
              >
                <ImageWrapper
                  name={getLockStatus(selectedValue)}
                  type="png"
                  parentFolder="icons"
                  styles={{
                    width: { xs: pxToRem(33), md: pxTovW(38) },
                    height: { xs: pxToRem(33), md: pxTovW(38) },
                  }}
                />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                // gap: '16px',
                marginTop: { xs: pxToRem(10), md: pxTovW(16) },
                alignItems: 'flex-start',
              }}
              onClick={(ev) => {
                ev.stopPropagation();
              }}
            >
              <Typography variant="h2">Choose State</Typography>
              <FormControl
                sx={{
                  mt: { xs: pxToRem(10), md: pxToRem(30) },
                }}
              >
                <RadioGroup
                  row
                  aria-labelledby="choose-state-radio-group-label"
                  name="choose-state-radio-group"
                  value={selectedValue?.toString()}
                  onChange={onRadioChange} // Handle change event
                  sx={{
                    display: 'flex',
                    gap: { xs: pxToRem(9), md: pxTovW(30) },
                    alignItems: 'center',
                  }}
                >
                  <Box sx={styles.radioDetails}>
                    <Radio
                      sx={styles.radio}
                      // {...controlRadioProps('Locked')}
                      value={ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED.toString()}
                      onChange={onRadioChange}
                    />
                    <Typography variant="h2" fontWeight="regular">
                      Locked
                    </Typography>
                  </Box>

                  <Box sx={styles.radioDetails}>
                    <Radio
                      sx={styles.radio}
                      // {...controlRadioProps('Locked')}
                      value={ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED.toString()}
                      onChange={onRadioChange}
                    />
                    <Typography variant="h2" fontWeight="regular">
                      Unlocked
                    </Typography>
                  </Box>

                  {handleLockedCheckboxChange && (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: { xs: pxToRem(5), md: pxTovW(5) },
                        alignItems: 'center',
                        // mt: { xs: pxToRem(10), md: pxTovW(10) },
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={(e) =>
                          handleLockedCheckboxChange &&
                          handleLockedCheckboxChange(e.target.checked)
                        }
                        sx={{
                          color: '#007CDC',
                          '& .MuiSvgIcon-root': {
                            fontSize: { xs: pxToRem(22), md: pxTovW(34) },
                          },
                          '&.Mui-checked': {
                            color: '#007CDC',
                          },
                        }}
                      />
                      <Typography variant="h2" fontWeight="regular">
                        Apply to all
                      </Typography>
                    </Box>
                  )}
                </RadioGroup>
              </FormControl>
            </Box>

            <Button
              disabled={
                selectedValue ===
                ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED
                  ? true
                  : false
              }
              onClick={onSubmit}
              sx={{
                width: { xs: '80%', md: pxTovW(360) },
                height: { xs: pxToRem(50), md: pxTovW(78) },
                borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
                margin: 'auto',
                mt: { xs: pxToRem(20), md: pxTovW(75) },
              }}
              variant="contained"
              color="secondary"
            >
              <Typography variant="h2" color="common.white">
                Submit
              </Typography>
            </Button>
          </Box>
        </Slide>

        <ImageWrapper
          name="close-yellow"
          type="png"
          parentFolder="icons"
          onClick={() => onClose()}
          styles={{
            height: { xs: pxToRem(50), md: pxTovW(50) },
            width: { xs: pxToRem(50), md: pxTovW(50) },
            cursor: 'pointer',
          }}
        />
      </>
    </Modal>
  );
};

export default ContentLockPopup;
