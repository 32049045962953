import {
  ResourceCategoryEnum,
  ResourceTeacherInstruction,
} from '@protos/content_management/content.db_pb';
import { IStyles } from '../../../../commonUtils/styleUtils';
import ElementRenderer from '../../ElementRenderer';
const styles: IStyles = {};
interface IProps {
  teacherInstruction: ResourceTeacherInstruction;
  resourceCategory?: ResourceCategoryEnum;
}
export const ResourceTeacherInstructionElement = (props: IProps) => {
  const { teacherInstruction, resourceCategory } = props;
  const elements = teacherInstruction.instructionContent?.elements;
  if (!elements) {
    return null;
  }
  return (
    <ElementRenderer elements={elements} resourceCategory={resourceCategory} />
  );
};
