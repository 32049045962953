import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, IconButton, Typography } from '@mui/material';
import { shouldIncludeCredentialsInRequest } from '../../../commonUtils/images';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { File } from 'react-pdf/dist/cjs/shared/types';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import getDeviceType from 'libs/shared-ui/src/commonUtils/getDeviceType';
import { FileEnum } from '@protos/content_management/content.db_pb';

interface IProps {
  file: File;
  layoutType: 'single' | 'multiple';
}

declare global {
  interface Window {
    Android: any;
  }
}

const CustomPDFRenderer = (props: IProps) => {
  const { file, layoutType } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState(1.0);
  const [rotation, setRotation] = useState(0);
  const android = window.Android;
  const deviceType = getDeviceType();
  const [pageWidths, setPageWidths] = useState<Record<number, number>>({});
  const minPageWidth =
    Object.values(pageWidths).length > 0
      ? Math.min(...Object.values(pageWidths))
      : 0;

  pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

  const pdfOptions = useMemo(() => {
    if (typeof file === 'string' && shouldIncludeCredentialsInRequest(file)) {
      return { withCredentials: true };
    }
    return undefined;
  }, [file]);

  const handlePageChange = (offset: number) => {
    setPageNumber((prevPage) =>
      Math.min(Math.max(prevPage + offset, 1), numPages || 1)
    );
  };

  const handleZoom = (zoomChange: number) => {
    setScale((prevScale) => Math.max(0.5, prevScale + zoomChange));
  };

  const handleRotate = (angle: number) => {
    setRotation((prevRotation) => (prevRotation + angle) % 360);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const updatePageWidth = useCallback(
    (pageNumber: number, originalWidth: number, originalHeight: number) => {
      const containerW = containerRef.current?.parentElement?.offsetWidth || 0;
      const maxWidth = originalHeight > originalWidth ? 660 : 900;
      setPageWidths((prev) => ({
        ...prev,
        [pageNumber]: Math.min(containerW, maxWidth) - 10,
      }));
    },
    []
  );

  useEffect(() => {
    try {
      if (FileEnum.FILE_TYPE_PDF) {
        if (deviceType === 'android' && android?.disableAppRefresh) {
          android.disableAppRefresh();
        }
      }
    } catch (error) {
      console.log(
        'An error occurred while attempting to disable app refresh on Android:',
        error
      );
    }
    return () => {
      if (deviceType === 'android' && android?.enableAppRefresh) {
        android.enableAppRefresh();
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current?.parentElement) {
        Object.keys(pageWidths).forEach((page) => {
          const width = pageWidths[+page];
          const containerW =
            containerRef.current?.parentElement?.offsetWidth || 0;
          setPageWidths((prev) => ({
            ...prev,
            [+page]: Math.min(containerW, width),
          }));
        });
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [pageWidths]);

  if (!file) return null;

  return (
    <Box
      id="my-pdf-renderer"
      display="flex"
      flexDirection="column"
      alignItems="center"
      ref={containerRef}
      sx={{
        background: '#ddd',
        '& .react-pdf__Page': {
          marginTop: '10px',
          marginBottom: '10px',
          border: '1px solid #ccbaba',
        },
      }}
      onClick={(ev) => ev.stopPropagation()}
    >
      {layoutType === 'single' && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            width="100%"
            py="10px"
            zIndex={1000}
          >
            <IconButton
              onClick={() => handlePageChange(-1)}
              disabled={pageNumber <= 1}
            >
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
            <Typography variant="body1">
              Page {pageNumber} of {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(1)}
              disabled={pageNumber >= (numPages || 1)}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Box>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) =>
              console.error('Error loading document:', error)
            }
            options={pdfOptions}
          >
            <Page
              pageNumber={pageNumber}
              scale={scale}
              rotate={rotation}
              width={minPageWidth * scale || 660 * scale}
              onRenderSuccess={({ width, height }) =>
                updatePageWidth(pageNumber, width, height)
              }
            />
          </Document>
        </>
      )}

      {layoutType === 'multiple' && (
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) =>
            console.error('Error loading document:', error)
          }
          options={pdfOptions}
        >
          {Array.from({ length: numPages || 0 }, (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={scale}
              rotate={rotation}
              width={minPageWidth * scale || 650}
              onRenderSuccess={({ originalWidth, originalHeight }) =>
                updatePageWidth(index + 1, originalWidth, originalHeight)
              }
            />
          ))}
        </Document>
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        py="10px"
        width="100%"
        zIndex={1000}
      >
        <IconButton onClick={() => handleZoom(0.2)}>
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={() => handleZoom(-0.2)}>
          <ZoomOutIcon />
        </IconButton>
        <IconButton onClick={() => handleRotate(-90)}>
          <RotateLeftIcon />
        </IconButton>
        <IconButton onClick={() => handleRotate(90)}>
          <RotateRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

CustomPDFRenderer.fileTypes = ['pdf', 'application/pdf'];
CustomPDFRenderer.weight = 1;

export default CustomPDFRenderer;
