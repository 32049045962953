// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/content_management/cms.teacher.content.apis.proto (package geneo.cms.teacher.content.upload.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteTeacherContentRequest, DeleteTeacherContentResponse, RequestTeacherContentDeletionRequest, RequestTeacherContentDeletionResponse, UpsertTeacherContentRequest, UpsertTeacherContentResponse } from "./cms.teacher.content.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.cms.teacher.content.upload.apis.TeacherUploadContentAPIServiceV1
 */
export const TeacherUploadContentAPIServiceV1 = {
  typeName: "geneo.cms.teacher.content.upload.apis.TeacherUploadContentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.cms.teacher.content.upload.apis.TeacherUploadContentAPIServiceV1.upsertTeacherContent
     */
    upsertTeacherContent: {
      name: "upsertTeacherContent",
      I: UpsertTeacherContentRequest,
      O: UpsertTeacherContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.teacher.content.upload.apis.TeacherUploadContentAPIServiceV1.deleteTeacherContent
     */
    deleteTeacherContent: {
      name: "deleteTeacherContent",
      I: DeleteTeacherContentRequest,
      O: DeleteTeacherContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.teacher.content.upload.apis.TeacherUploadContentAPIServiceV1.requestTeacherContentDeletion
     */
    requestTeacherContentDeletion: {
      name: "requestTeacherContentDeletion",
      I: RequestTeacherContentDeletionRequest,
      O: RequestTeacherContentDeletionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

