import {
  CourseIndexInfo,
  TopicBasicInfo,
} from '@protos/content_management/content.common.apis_pb';
import { AssessmentsByType } from '@protos/learning_management/lms.assessment.apis_pb';
import { createSlice } from '@reduxjs/toolkit';

interface Chapter {
  chapterId: number;
  chapterTitle: string;
}

interface Topic {
  topicId: number;
  topicTitle: string;
}
export interface IAssessmentFlowState {
  assesment_flow_data?: AssessmentsByType;
  chapter_topic_data?: CourseIndexInfo;
  selected_chapter?: Chapter;
  selected_topic?: Topic;
}
const initialState: IAssessmentFlowState = {
  assesment_flow_data: undefined,
  chapter_topic_data: undefined,
  selected_chapter: undefined,
  selected_topic: undefined,
};

export const assessmentFlowSlice = createSlice({
  name: 'assessmentFlow',
  initialState,
  reducers: {
    setAssessmentData: (state, action) => {
      state.assesment_flow_data = action.payload;
    },
    setCourseIndexInfo: (state, action) => {
      state.chapter_topic_data = action.payload;
    },
    setSelectedChapter: (state, action) => {
      state.selected_chapter = action.payload;
    },
    setSelectedTopic: (state, action) => {
      state.selected_topic = action.payload;
    },
    resetSelectedChapter: (state) => {
      state.selected_chapter = undefined;
    },
    resetSelectedTopic: (state) => {
      state.selected_topic = undefined;
    },
    resetAssessmentFlowState: (state) => {
      return initialState;
    },
  },
});

export const {
  setAssessmentData,
  setCourseIndexInfo,
  setSelectedChapter,
  setSelectedTopic,
  resetSelectedChapter,
  resetSelectedTopic,
  resetAssessmentFlowState,
} = assessmentFlowSlice.actions;

export default assessmentFlowSlice.reducer;
