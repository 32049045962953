// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/content_management/cms.teacher.content.apis.proto (package geneo.cms.teacher.content.upload.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Resource_ResourceEnum } from "@protos/content_management/content.db_pb";
import { CloudUpload } from "@protos/learning_management/lms.db_pb";
import { ResourceInfo } from "@protos/content_management/content.common.apis_pb";
import { APIResponse_CodeEnum, APIResponse_StatusEnum } from "@protos/common/common.db_pb";

/**
 * we will soft delete
 *
 * @generated from message geneo.cms.teacher.content.upload.apis.DeleteTeacherContentRequest
 */
export class DeleteTeacherContentRequest extends Message<DeleteTeacherContentRequest> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: optional geneo.content.db.Resource.ResourceEnum resource_type = 8;
   */
  resourceType?: Resource_ResourceEnum;

  constructor(data?: PartialMessage<DeleteTeacherContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.teacher.content.upload.apis.DeleteTeacherContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTeacherContentRequest {
    return new DeleteTeacherContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTeacherContentRequest {
    return new DeleteTeacherContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTeacherContentRequest {
    return new DeleteTeacherContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTeacherContentRequest | PlainMessage<DeleteTeacherContentRequest> | undefined, b: DeleteTeacherContentRequest | PlainMessage<DeleteTeacherContentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteTeacherContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.teacher.content.upload.apis.DeleteTeacherContentResponse
 */
export class DeleteTeacherContentResponse extends Message<DeleteTeacherContentResponse> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  constructor(data?: PartialMessage<DeleteTeacherContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.teacher.content.upload.apis.DeleteTeacherContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTeacherContentResponse {
    return new DeleteTeacherContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTeacherContentResponse {
    return new DeleteTeacherContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTeacherContentResponse {
    return new DeleteTeacherContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTeacherContentResponse | PlainMessage<DeleteTeacherContentResponse> | undefined, b: DeleteTeacherContentResponse | PlainMessage<DeleteTeacherContentResponse> | undefined): boolean {
    return proto3.util.equals(DeleteTeacherContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.teacher.content.upload.apis.UpsertTeacherContentRequest
 */
export class UpsertTeacherContentRequest extends Message<UpsertTeacherContentRequest> {
  /**
   * @generated from field: optional string resource_id = 1;
   */
  resourceId?: string;

  /**
   * @generated from field: int32 chapter_id = 2;
   */
  chapterId = 0;

  /**
   * @generated from field: optional int32 topic_id = 3;
   */
  topicId?: number;

  /**
   * @generated from field: string cover_title = 4;
   */
  coverTitle = "";

  /**
   * should be cloud upload
   *
   * @generated from field: optional geneo.lms.db.CloudUpload cover_image_info = 5;
   */
  coverImageInfo?: CloudUpload;

  /**
   * @generated from field: int32 estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: bool content_lock = 7;
   */
  contentLock = false;

  /**
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 8;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * == course id for backend
   *
   * @generated from field: int32 subject_id = 10;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 section_id = 11;
   */
  sectionId = 0;

  /**
   * Maintain the sequence
   *
   * @generated from field: repeated geneo.lms.db.CloudUpload files_info = 12;
   */
  filesInfo: CloudUpload[] = [];

  /**
   * @generated from field: int64 teacher_id = 13;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 14;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<UpsertTeacherContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.teacher.content.upload.apis.UpsertTeacherContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "cover_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "cover_image_info", kind: "message", T: CloudUpload, opt: true },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "content_lock", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 10, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "files_info", kind: "message", T: CloudUpload, repeated: true },
    { no: 13, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertTeacherContentRequest {
    return new UpsertTeacherContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertTeacherContentRequest {
    return new UpsertTeacherContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertTeacherContentRequest {
    return new UpsertTeacherContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertTeacherContentRequest | PlainMessage<UpsertTeacherContentRequest> | undefined, b: UpsertTeacherContentRequest | PlainMessage<UpsertTeacherContentRequest> | undefined): boolean {
    return proto3.util.equals(UpsertTeacherContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.teacher.content.upload.apis.UpsertTeacherContentResponse
 */
export class UpsertTeacherContentResponse extends Message<UpsertTeacherContentResponse> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: geneo.content.common.apis.ResourceInfo resource_info = 2;
   */
  resourceInfo?: ResourceInfo;

  constructor(data?: PartialMessage<UpsertTeacherContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.teacher.content.upload.apis.UpsertTeacherContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_info", kind: "message", T: ResourceInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertTeacherContentResponse {
    return new UpsertTeacherContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertTeacherContentResponse {
    return new UpsertTeacherContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertTeacherContentResponse {
    return new UpsertTeacherContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertTeacherContentResponse | PlainMessage<UpsertTeacherContentResponse> | undefined, b: UpsertTeacherContentResponse | PlainMessage<UpsertTeacherContentResponse> | undefined): boolean {
    return proto3.util.equals(UpsertTeacherContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.teacher.content.upload.apis.RequestTeacherContentDeletionRequest
 */
export class RequestTeacherContentDeletionRequest extends Message<RequestTeacherContentDeletionRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 3;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<RequestTeacherContentDeletionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.teacher.content.upload.apis.RequestTeacherContentDeletionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestTeacherContentDeletionRequest {
    return new RequestTeacherContentDeletionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestTeacherContentDeletionRequest {
    return new RequestTeacherContentDeletionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestTeacherContentDeletionRequest {
    return new RequestTeacherContentDeletionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestTeacherContentDeletionRequest | PlainMessage<RequestTeacherContentDeletionRequest> | undefined, b: RequestTeacherContentDeletionRequest | PlainMessage<RequestTeacherContentDeletionRequest> | undefined): boolean {
    return proto3.util.equals(RequestTeacherContentDeletionRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.teacher.content.upload.apis.RequestTeacherContentDeletionResponse
 */
export class RequestTeacherContentDeletionResponse extends Message<RequestTeacherContentDeletionResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string resource_id = 4;
   */
  resourceId = "";

  constructor(data?: PartialMessage<RequestTeacherContentDeletionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.teacher.content.upload.apis.RequestTeacherContentDeletionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestTeacherContentDeletionResponse {
    return new RequestTeacherContentDeletionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestTeacherContentDeletionResponse {
    return new RequestTeacherContentDeletionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestTeacherContentDeletionResponse {
    return new RequestTeacherContentDeletionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RequestTeacherContentDeletionResponse | PlainMessage<RequestTeacherContentDeletionResponse> | undefined, b: RequestTeacherContentDeletionResponse | PlainMessage<RequestTeacherContentDeletionResponse> | undefined): boolean {
    return proto3.util.equals(RequestTeacherContentDeletionResponse, a, b);
  }
}

