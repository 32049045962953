import {
  AvatarGroup,
  Box,
  Button,
  Dialog,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import { Close } from '@mui/icons-material';
import AvatarImageWrapper from '../AvatarImageWrapper/AvatarImageWrapper';
import { theme } from 'libs/shared-ui/src/theme/themeProvider';
import {
  JSXElementConstructor,
  ReactComponentElement,
  ReactElement,
  ReactHTMLElement,
  ReactNode,
} from 'react';

interface IProps {
  iconName: string;
  popupComponent: ReactElement;
  open: boolean;
  heading: ReactElement;
  handleClose?: () => void;
}
const styles: IStyles = {
  modal: {
    boxSizing: 'border-box',
    display: { xs: 'flex', md: 'block' },
    alignItems: 'flex-end',
  },
  root: {
    backgroundColor: 'common.white',
    margin: { md: 'auto' },
    width: { xs: '100vw', md: pxToRem(450), lg: pxTovW(537) },
    height: { xs: pxToRem(364), md: pxToRem(350), lg: pxTovW(428) },
    position: { md: 'fixed' },
    top: { md: '30%' },
    right: { md: '20%', lg: '35%' },
    borderRadius: {
      xs: `${pxToRem(30)} ${pxToRem(30)} 0 0`,
      md: pxToRem(15),
    },
    padding: {
      xs: `${pxToRem(10)} ${pxToRem(0)} ${pxToRem(20)} ${pxToRem(0)}`,
    },
    boxSizing: 'border-box',
    outline: 'none',
    boxShadow: `${pxTovW(0)} ${pxTovW(4)} ${pxTovW(18)} #3608918F ,${pxTovW(
      0
    )} ${pxTovW(-3)} ${pxTovW(10)}#292CE696 inset`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  iconBox: {
    height: { xs: pxToRem(113), md: pxToRem(120), lg: pxTovW(206) },
    display: 'flex',
    width: { xs: pxToRem(113), md: pxToRem(120), lg: pxTovW(206) },
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: { xs: '30%', md: '35%', lg: '30%' },
    transform: 'translateY(-50%)',
  },
  actionPopupText: {
    margin: {
      xs: '30% auto 20px auto',

      md: `auto auto ${pxTovW(20)} auto`,
    },
    textAlign: 'center',
    //   alignSelf: 'flex-end',
    height: 'max-content',
    width: { xs: '90%', md: pxTovW(388) },
  },
};

export const ReminderPopup = (props: IProps) => {
  const { open, iconName, handleClose, heading, popupComponent } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Modal
      draggable
      sx={styles.modal}
      open={open}
      onClose={handleClose && handleClose}
    >
      <Box sx={styles.root}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            // backgroundColor: 'red',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          {!isMobile && (
            <Box
              sx={{
                position: 'relative',
                top: '0px',
                left: '0%',
                height: pxTovW(8),
                width: '100%',
              }}
            >
              <IconBox iconName={iconName} />
              <Box
                sx={{
                  display: 'flex',
                  height: '20px',
                  width: '97%',
                  justifySelf: 'right',
                  //   backgroundColor: 'blue',
                  justifyContent: 'right',
                  paddingTop: pxTovW(9),
                }}
              >
                <Close
                  sx={{ fontSize: '15px', cursor: 'pointer' }}
                  onClick={handleClose}
                />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              // backgroundColor: 'blue',
              boxSizing: 'border-box',
              height: '100%',
              marginTop: { xs: '0px', md: pxTovW(90) },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                boxSizing: 'border-box',
                padding: {
                  xs: `${pxToRem(10)} ${pxToRem(26)} ${pxToRem(22)} ${pxToRem(
                    26
                  )}`,
                  md: 'none',
                },
                marginBottom: { xs: pxToRem(22), md: 'unset' },
                textAlign: 'center',
                marginTop: { md: pxToRem(20), lg: 0 },
                borderBottom: { xs: '1px solid #EFEFEF', md: 'none' },
                justifyContent: { xs: 'space-between', md: 'center' },
                // backgroundColor: 'red',
              }}
            >
              {heading}
              {isMobile && (
                <Close
                  sx={{ fontSize: '15px', cursor: 'pointer' }}
                  onClick={handleClose}
                />
              )}
            </Box>
            {popupComponent}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
interface IConProps {
  iconName: string;
}
const IconBox = (props: IConProps) => {
  const { iconName } = props;
  return (
    <Box sx={styles.iconBox}>
      <ImageWrapper
        name={iconName}
        parentFolder="icons"
        type="png"
        styles={{
          height: { xs: pxToRem(52), lg: pxTovW(94) },
          width: { xs: pxToRem(52), lg: pxTovW(94) },
        }}
      />
    </Box>
  );
};
