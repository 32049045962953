import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { ContentApprovalStatusEnum } from '@protos/content_management/content.db_pb';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import ImageWrapper from '../../elements/ImageWrapper';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: pxToRem(12), // Adjust tooltip font size if needed
  },
}));

interface IResourceStatusProps {
  status?: ContentApprovalStatusEnum;
  handleOpenPopup?: (ev?: React.MouseEvent) => void;
}

export const ResourceStatus: React.FC<IResourceStatusProps> = ({
  status,
  handleOpenPopup,
}) => {
  const getImageName = (): string => {
    switch (status) {
      case ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_REJECTED:
        return 'reject';
      case ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_PENDING:
        return 'pending';
      default:
        return '';
    }
  };

  const getTooltipTitle = (): string => {
    switch (status) {
      case ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_REJECTED:
        return 'Rejected';
      case ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_PENDING:
        return 'Approval is Pending from Admin';
      default:
        return 'No Status';
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        border: 'none',
        cursor: 'pointer',
        borderRadius: {
          xs: pxToRem(7),
          md: pxToRem(5),
          lg: pxTovW(7),
        },
        height: {
          xs: pxToRem(30),
          md: pxToRem(20),
          lg: pxTovW(32),
        },
        width: {
          xs: pxToRem(28),
          md: pxToRem(20),
          lg: pxTovW(32),
        },
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BootstrapTooltip title={getTooltipTitle()}>
        <Box // Use a wrapping Box to ensure Tooltip works
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageWrapper
            onClick={handleOpenPopup}
            name={getImageName()}
            type="png"
            parentFolder="icons"
            styles={{
              height: {
                xs: pxToRem(30),
                md: pxToRem(20),
                lg: pxTovW(32),
              },
              width: {
                xs: pxToRem(28),
                md: pxToRem(20),
                lg: pxTovW(32),
              },
            }}
          />
        </Box>
      </BootstrapTooltip>
    </Box>
  );
};
