import {
  Feature,
  FeaturePlanInfo,
} from '@protos/school_management/school.db_pb';

export const useFeatureEnabled = (
  user_info?: FeaturePlanInfo | undefined,
  Feature?: Feature
) => {
  if (user_info) {
    const featureInfo = user_info.featureInfo.find(
      (feature) => feature.feature === Feature
    );

    if (featureInfo) {
      console.log('feature found:', featureInfo.isEnabled);
      return featureInfo.isEnabled; // Return true if the feature is found
    } else {
      console.log('feature not found.');
      return false; // Return false if the feature is not found
    }
  } else {
    console.log('user_info is undefined or does not contain featuresPlanInfo.');
    return false; // Return false if user_info or feature plan is not defined
  }
};

export default useFeatureEnabled;
