// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/analysis_management/analysis.assessment.apis.proto (package geneo.analysis.school.assessment.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchAssessmentReportRequest, FetchAssessmentReportResponse, FetchCCAssessmentReportRequest, FetchCCAssessmentReportResponse, fetchKdtAssessmentStudentsDetailsRequest, fetchKdtAssessmentStudentsDetailsResponse, FetchStudnetKDAssessmentReportRequest, FetchStudnetKDAssessmentReportResonse, KdtAssessmentSendMailRequest, KdtAssessmentSendMailResponse, updateHwPdfRequest, updateHwPdfResponse, updateStudentHwPdfRequest, updateStudentHwPdfResponse } from "./analysis.assessment.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1
 */
export const SchoolAssessmentAPIServiceV1 = {
  typeName: "geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.fetchSchoolAssessmentReport
     */
    fetchSchoolAssessmentReport: {
      name: "fetchSchoolAssessmentReport",
      I: FetchAssessmentReportRequest,
      O: FetchAssessmentReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.updateHwGenPdfRow
     */
    updateHwGenPdfRow: {
      name: "updateHwGenPdfRow",
      I: updateHwPdfRequest,
      O: updateHwPdfResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.fetchSchoolCCReport
     */
    fetchSchoolCCReport: {
      name: "fetchSchoolCCReport",
      I: FetchCCAssessmentReportRequest,
      O: FetchCCAssessmentReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.updateCCGenPdfRow
     */
    updateCCGenPdfRow: {
      name: "updateCCGenPdfRow",
      I: updateHwPdfRequest,
      O: updateHwPdfResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.fetchStundetKdAssessmentReport
     */
    fetchStundetKdAssessmentReport: {
      name: "fetchStundetKdAssessmentReport",
      I: FetchStudnetKDAssessmentReportRequest,
      O: FetchStudnetKDAssessmentReportResonse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.updateStudentKdtHwGenPdfRow
     */
    updateStudentKdtHwGenPdfRow: {
      name: "updateStudentKdtHwGenPdfRow",
      I: updateStudentHwPdfRequest,
      O: updateStudentHwPdfResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.fetchKdtAssessmentStudentsDetails
     */
    fetchKdtAssessmentStudentsDetails: {
      name: "fetchKdtAssessmentStudentsDetails",
      I: fetchKdtAssessmentStudentsDetailsRequest,
      O: fetchKdtAssessmentStudentsDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.sendKdtAssessmentEmailReport
     */
    sendKdtAssessmentEmailReport: {
      name: "sendKdtAssessmentEmailReport",
      I: KdtAssessmentSendMailRequest,
      O: KdtAssessmentSendMailResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

