import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { pxToRem, pxTovW } from '../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../commonUtils/styleUtils';
import { theme } from '../../../../theme/themeProvider';
import { DeleteButtonCommon } from '../../../elements/DeleteButton';
import { IconWrapper } from '../../../elements/IconWrapper/Index';
import InputField from '../../../elements/InputField';
import PrimaryButton from '../../../elements/PrimaryButton';
import SecondaryButton from '../../SecondaryButton';

const styles: IStyles = {
  root: {
    paddingY: { xs: pxToRem(20), md: pxTovW(20) },
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px dashed #333333',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    alignItems: { md: 'center' },
    gap: pxToRem(10),
  },
  titleDetailsBox: {
    display: 'flex',
    gap: { xs: pxToRem(5), md: pxTovW(10) },
    alignItems: 'center',
  },
  verifyAndDetailsBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(10) },
  },
  verifyBox: {
    display: 'flex',
    boxSizing: 'border-box',
    height: { xs: pxToRem(40), md: pxTovW(40) },
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(10) },
    // border: verified ? '1px solid #48FE9A' : '1px solid #FF9A9A',
    borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
    // backgroundColor: verified ? '#D1FFE6' : '#FFDDDD',
    p: { xs: pxToRem(7), md: pxTovW(7) },
  },
  verifyLinkTypo: {
    textDecoration: 'underline',
    cursor: 'pointer',
    borderLeft: '1px solid #FF9A9A',
    pl: { xs: pxToRem(7), md: pxTovW(7) },
  },
};

interface RenderDataWithInputEventCollection {
  addEvent?: () => void;
  changeEvent?: () => void;
}
interface InputContainerProps {
  type?: string;
  title: string;
  placeholder: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // helperText?: string;
  addClickHandler?: (processData: string) => void;
  deleteHandler?: () => void;
  verifyClickHandler?: () => void;
  isVerified?: boolean;
  countryCode?: string;
  eventCollection?: RenderDataWithInputEventCollection;
  isOffline?: boolean;
}
export const RenderDataWithInput = (props: InputContainerProps) => {
  const {
    type,
    title,
    placeholder,
    value,
    onChange,
    addClickHandler,
    deleteHandler,
    verifyClickHandler,
    isVerified,
    countryCode,
    eventCollection,
    isOffline,
  } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isFocused, setIsFocused] = useState(false);
  const [inputData, setInputData] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editInput, setEditInput] = useState(`${value}`);

  return (
    <Box sx={styles.root}>
      <Typography variant="h3">{title}</Typography>

      <Box sx={styles.titleDetailsBox}>
        {!value ? (
          <>
            {countryCode ? '+' + countryCode : null}
            <InputField
              variant="outlined"
              fullWidth
              boldtext
              nonCircular
              placeholder={placeholder}
              type={type ? type : 'text'}
              value={inputData}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(event) => setInputData(event.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: '#FFFFFF',
                  fontSize: { xs: pxToRem(16), md: pxTovW(21) },
                  padding: {
                    xs: `${pxToRem(9)} ${pxToRem(22)}`,
                    md: `${pxTovW(9)} ${pxTovW(22)}`,
                  },
                  border: `${pxTovW(1)} solid red`,
                  borderColor: 'neutral.cornflowerBlue',
                  borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
                  boxShadow: isFocused
                    ? `inset 0 0 1px 0 #61BAFF, inset 0 0 2px 1px rgba(97, 186, 255, 0.6), inset 0 0 3px 2px rgba(97, 186, 255, 0.4), inset 0 0 4px 3px rgba(97, 186, 255, 0.2), inset 0 0 5px 4px rgba(97, 186, 255, 0.1)`
                    : 'none',
                },
              }}
            />
          </>
        ) : (
          <Box sx={styles.verifyAndDetailsBox}>
            <Box
              sx={{
                ...styles.verifyBox,
                border: isVerified ? '1px solid #48FE9A' : '1px solid #FF9A9A',
                backgroundColor: isVerified ? '#D1FFE6' : '#FFDDDD',
              }}
            >
              <IconWrapper
                name={isVerified ? 'green-circle-check' : 'wrong'}
                size={isMobile ? 'small' : 'md'}
                parentFolder="icons"
                type="png"
              />

              <Typography
                variant="h5"
                color={isVerified ? 'success.main' : 'neutral.crimson'}
                fontWeight="bold"
                sx={{ width: { md: 'max-content' } }}
              >
                {isVerified ? 'Verified' : 'Not Verified'}
              </Typography>

              {!isVerified && (
                <Typography
                  variant="h5"
                  color="neutral.crimson"
                  fontWeight="bold"
                  onClick={() => verifyClickHandler && verifyClickHandler()}
                  sx={styles.verifyLinkTypo}
                >
                  Verify
                </Typography>
              )}
            </Box>

            {isEditing ? (
              <>
                {countryCode ? '+' + countryCode : null}
                <InputField
                  variant="outlined"
                  fullWidth
                  boldtext
                  nonCircular
                  placeholder={placeholder}
                  type={type ? type : 'text'}
                  value={editInput}
                  onChange={(event) => setEditInput(event.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      boxSizing: 'border-box',
                      bgcolor: '#FFFFFF',
                      fontSize: { xs: pxToRem(16), md: pxTovW(21) },
                      padding: {
                        xs: `${pxToRem(9)} ${pxToRem(22)}`,
                        md: `${pxTovW(9)} ${pxTovW(22)}`,
                      },
                      height: { xs: pxToRem(40), md: pxTovW(40) },
                      // maxWidth: pxToRem(186),
                      border: `${pxTovW(1)} solid red`,
                      borderColor: 'neutral.cornflowerBlue',
                      borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
                      boxShadow: `inset 0 0 1px 0 #61BAFF, inset 0 0 2px 1px rgba(97, 186, 255, 0.6), inset 0 0 3px 2px rgba(97, 186, 255, 0.4), inset 0 0 4px 3px rgba(97, 186, 255, 0.2), inset 0 0 5px 4px rgba(97, 186, 255, 0.1)`,
                    },
                  }}
                />
              </>
            ) : (
              <Typography
                variant="cardText"
                color="neutral.royalBlue"
                sx={{ wordBreak: 'break-word' }}
              >
                {countryCode ? `+${countryCode} ${value}` : value}
              </Typography>
            )}
          </Box>
        )}

        {!value && !isOffline ? (
          //! ADD Button
          <Box>
            <SecondaryButton
              sx={{ height: { xs: pxToRem(40), md: pxTovW(40) } }}
              onClick={() => {
                addClickHandler && addClickHandler(inputData);
                eventCollection?.addEvent && eventCollection?.addEvent();
              }}
              disabled={inputData ? false : true}
            >
              <Typography variant="h5" color="common.white">
                +Add
              </Typography>
            </SecondaryButton>
          </Box>
        ) : !isOffline ? (
          //! DELETE Button
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(10), md: pxTovW(10) },
            }}
          >
            <PrimaryButton
              sx={{
                height: { xs: pxToRem(40), md: pxTovW(40) },
                width: pxToRem(74),
                boxSizing: 'border-box',
                borderRadius: pxToRem(5),
              }}
              onClick={() => {
                if (isEditing === true && addClickHandler) {
                  addClickHandler(editInput);
                  setIsEditing((prev) => !prev);
                  eventCollection?.changeEvent &&
                    eventCollection?.changeEvent();
                } else {
                  setEditInput(`${value}`);
                  setIsEditing((prev) => !prev);
                }
              }}
              disabled={editInput ? false : true}
            >
              <Typography variant="bodyText" color="white">
                Change
              </Typography>
            </PrimaryButton>
            <DeleteButtonCommon
              onClick={() => {
                deleteHandler && deleteHandler();
                setInputData('');
              }}
              title={title}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

// sx={{ ml: { xs: pxToRem(15), md: pxTovW(15) } }}
