import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';

import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { Box } from '@mui/system';
import { SchoolContentModuleNodeLock } from '@protos/school_management/school.db_pb';
import { ReactNode } from 'react';
import { ListItemButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
interface IProps {
  open: boolean;
  variant: 'small' | 'medium' | 'large';
  chapterData: any[];
  HeaderContent: ReactNode;
  handleExpandmore: () => void;
  handleExpandless: () => void;
  expandDisabled: boolean;
  ListItemArray: React.ReactNode[];
}
export function ContentListAccordian(props: IProps) {
  const {
    open,
    variant,
    chapterData,
    ListItemArray,
    HeaderContent,
    handleExpandmore,
    handleExpandless,
    expandDisabled,
  } = props;

  return (
    <List
      sx={{
        padding: '0px',
        borderRadius: '10px',
        border: '2px solid #A8D3FC',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F5FAFF',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          alignItems: 'center',
          padding: '0px 10px',
          display: 'flex',
          width: '100%',
          height: variantMapping[variant]?.height,
          justifyContent: 'space-between',
          borderBottom: open ? '2px solid #A8D3FC' : 'none',
        }}
      >
        <AccordianHeaderComponent
          children={HeaderContent}
          expanded={open}
          handleExpandless={handleExpandless}
          handleExpandmore={handleExpandmore}
          disabled={expandDisabled}
        ></AccordianHeaderComponent>
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
        <List component="div" disablePadding>
          {ListItemArray.map((item, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: {
                    xs: `calc(${variantMapping[variant]?.height.xs}-${pxToRem(
                      10
                    )})`,
                    md: `calc(${variantMapping[variant]?.height.md}-${pxTovW(
                      5
                    )})`,
                  },
                  padding: '0px 10px',
                  boxSizing: 'border-box',
                  alignItems: 'center',
                  borderBottom:
                    i === chapterData.length - 1 ? 'none' : '2px solid #A8D3FC',
                  backgroundColor: '#EBF5FF',
                  borderBottomLeftRadius:
                    i === chapterData.length - 1 ? '10px' : '0px',
                  borderBottomRightRadius:
                    i === chapterData.length - 1 ? '10px' : '0px',
                  paddingRight: '63px',
                }}
              >
                {item}
              </Box>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
}

interface AccordianHeaderprops {
  children: ReactNode;
  expanded?: boolean;
  handleExpandmore?: () => void;
  handleExpandless?: () => void;
  disabled?: boolean;
}

export const AccordianHeaderComponent = (props: AccordianHeaderprops) => {
  const { children, expanded, handleExpandmore, handleExpandless, disabled } =
    props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        // backgroundColor: 'red',
      }}
    >
      {children}
      <ListItemButton
        // onClick={() => handleExpand(chapterData)}
        disabled={disabled}
      >
        {expanded ? (
          <ExpandLess onClick={handleExpandless} />
        ) : (
          <ExpandMore onClick={handleExpandmore} />
        )}
      </ListItemButton>
    </Box>
  );
};

const variantMapping = {
  small: {
    height: { xs: pxToRem(25), md: pxTovW(30) },
  },
  medium: {
    height: { xs: pxToRem(35), md: pxTovW(60) },
  },

  large: {
    height: { xs: pxToRem(54), md: pxTovW(90) },
  },
};
