import { Box, Button, Dialog, Typography } from '@mui/material';

import React from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import PrimaryButton from '../../elements/PrimaryButton';
import { InstructionsPanel } from '../InstructionsPanel';
import {
  ApprovalInfo,
  ContentRejectionEnum,
} from '@protos/content_management/content.db_pb';

interface IProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  open: boolean;
  handleClose?: () => void;
  tryAgainClickHandler: (ev?: React.MouseEvent) => void;
  removeClickHandler: (ev?: React.MouseEvent) => void;
  fontSmall?: boolean;
  approvalInfo?: ApprovalInfo;
}
const styles: IStyles = {
  root: {},
  iconBox: {
    height: { xs: pxToRem(113), md: pxTovW(206) },
    display: 'flex',
    width: { xs: pxToRem(113), md: pxTovW(206) },
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '30%',
    transform: 'translateY(-50%)',
  },
  actionPopupText: {
    margin: {
      xs: '30% auto 20px auto',
      md: `auto auto ${pxTovW(10)} auto`,
    },
    textAlign: 'center',
    //   alignSelf: 'flex-end',
    height: 'max-content',
    width: { xs: '90%', md: pxTovW(388) },
  },
  actionPopupTextSmall: {
    margin: {
      xs: `${pxToRem(30)} auto 0 auto`,
      md: `${pxTovW(24)} auto 0 auto`,
    },
    textAlign: 'center',
    //   alignSelf: 'flex-end',
    // height: '100%',
    width: { xs: '80%', md: pxTovW(600), lg: pxTovW(400) },
    zIndex: 1,
  },
};

export const ResourceRejectedPopup = (props: IProps) => {
  const {
    open,
    iconName,
    popupText,
    splitText,
    handleClose,
    tryAgainClickHandler,
    removeClickHandler,
    fontSmall,
    approvalInfo,
  } = props;
  const showReason = () => {
    switch (approvalInfo?.remark?.reason) {
      case ContentRejectionEnum.CONTENT_REJECTION_REASON_MISSING_INFO:
        return 'Some Content is Missing';
      case ContentRejectionEnum.CONTENT_REJECTION_REASON_INCORRECT_DATA:
        return 'Content has Incorrect Data';
      default:
        return 'No Reason';
    }
  };
  return (
    <Dialog open={open} onClose={handleClose && handleClose}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            height: 'max-content',
            maxHeight: { xs: pxToRem(430), md: pxTovW(1200), lg: pxTovW(700) },
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            width: { xs: pxToRem(295), md: pxTovW(900), lg: pxTovW(660) },
            borderRadius: '10%',
            position: 'fixed',
            top: '20%',
            justifyContent: 'space-between',
            // left: { xs: '10%', sm: '16%', md: '30%' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: pxToRem(10), md: pxTovW(26) },
            }}
          >
            <Box
              sx={{
                position: 'relative',
                top: '0px',
                left: { xs: '0%', md: '7%' },
                height: pxTovW(8),
                width: '100%',
              }}
            >
              <IconBox iconName={iconName} />
            </Box>
            <Box
              sx={
                fontSmall ? styles.actionPopupTextSmall : styles.actionPopupText
              }
            >
              {!splitText ? (
                <Typography
                  variant={fontSmall ? 'h2' : 'h1'}
                  fontWeight="700"
                  fontFamily="Lato"
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    wordWrap: 'break-word',
                  }}
                >
                  {popupText}
                </Typography>
              ) : (
                <Box sx={{ width: '106%' }}>
                  <Typography
                    variant={fontSmall ? 'h2' : 'h1'}
                    fontWeight={'regular'}
                    fontFamily="Lato"
                  >
                    {popupText}
                  </Typography>
                  <Typography variant={fontSmall ? 'h2' : 'h1'}>
                    {splitText}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Reasons */}
            <Box
              sx={{
                // backgroundColor: '#F6FBFF',
                // borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
                padding: { xs: pxToRem(20), md: pxTovW(5) },
                textAlign: 'left',
                marginBottom: { xs: pxToRem(20), md: pxTovW(20) },
                marginX: { xs: pxToRem(10), md: pxTovW(20) },
                marginTop: { xs: pxToRem(10), md: pxTovW(5) },
                // border: '1px solid #E0E0E0',
              }}
            >
              <Typography
                variant="subtitle1"
                fontWeight="700"
                sx={{ marginBottom: pxToRem(10) }}
              >
                Reason: {showReason()}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // gap: pxToRem(10),
                  overflowY: 'auto',
                  height: 'max-content',
                  width: '100%',
                  maxHeight: { xs: pxToRem(120), md: pxTovW(200) },
                  scrollbarWidth: 'thin',
                }}
              >
                <InstructionsPanel
                  customSx={{
                    display: 'flex',
                    flexGrow: 1,
                    width: { xs: '100%', md: pxTovW(900), lg: pxTovW(590) },
                    // padding: pxToRem(20),
                    // height: pxToRem(450),
                  }}
                  Katexvalue={approvalInfo?.remark?.comments?.[0]}
                  title="Comments"
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: { xs: pxToRem(13), md: pxTovW(25) },
              marginBottom: { xs: pxToRem(40), md: pxTovW(40), lg: pxTovW(20) },
              bottom: '0px',
            }}
          >
            <PrimaryButton
              onClick={tryAgainClickHandler}
              // sx={styles.customButton}
              sx={{
                // backgroundColor: '#007CDC',
                height: { xs: pxToRem(48), md: pxTovW(88) },
                width: { xs: pxToRem(120), md: pxTovW(218) },
                color: '#FFFFFF',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                fontSize: {
                  xs: pxToRem(18),
                  md: pxToRem(14),
                  lg: pxTovW(21),
                },
              }}
            >
              TRY AGAIN
            </PrimaryButton>

            <Button
              variant="outlined"
              sx={{
                height: { xs: pxToRem(48), md: pxTovW(88) },
                width: { xs: pxToRem(120), md: pxTovW(218) },
              }}
              onClick={removeClickHandler}
            >
              <Typography variant="h2" fontFamily="Lato" color="#007CDC">
                Remove
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
interface IConProps {
  iconName: string;
}
const IconBox = (props: IConProps) => {
  const { iconName } = props;
  return (
    <Box sx={styles.iconBox}>
      <ImageWrapper
        name={iconName}
        parentFolder="icons"
        type="png"
        styles={{
          height: { xs: pxToRem(52), md: pxTovW(94) },
          width: { xs: pxToRem(52), md: pxTovW(94) },
        }}
      />
    </Box>
  );
};
