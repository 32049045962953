import { Box, Dialog, IconButton, Typography } from '@mui/material';
import React from 'react';
import {
  ImageWrapper,
  PrimaryButton,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Value } from '@bufbuild/protobuf';
import { AssessmentEnum } from '@protos/learning_management/lms.db_pb';

interface IProps {
  open: boolean;
  selectedValue?: AssessmentEnum;
  optionClickHandler: (assessmentType: AssessmentEnum) => void;
  submitClickHandler: () => void;
  handleClose?: () => void;
  classInfo?: string;
}
interface AssessmentOptions {
  [key: string]: OptionItemValue;
}
export default function AssementSelectionPopup(props: IProps) {
  const assessmentOptions: AssessmentOptions = {
    [AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT]: {
      typeKey: AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT,
      label: 'Formative Assessment',
      icon: 'checklist',
      phrase: 'Chapter-level test for learning.',
      backgroundColor: '#F2F9FF',
      borderColor: '#007CDC',
      disabled: true,
    },
    [AssessmentEnum.ASSESSMENT_HOMEWORK]: {
      typeKey: AssessmentEnum.ASSESSMENT_HOMEWORK,
      label: 'Homework',
      icon: 'homeschooling',
      phrase: 'Daily practice for quick revision.',
      backgroundColor: '#F0FFF7',
      borderColor: '#0AA34F',
      disabled: false,
    },
  };
  const {
    open,
    selectedValue,
    handleClose,
    optionClickHandler,
    submitClickHandler,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose && handleClose}
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          // position: 'absolute',
          // top: '10%',
          borderRadius: '20px',
          background: 'transparent',
          border: '0px solid transparent',
          boxShadow: 'none',
          width: '100%',
          maxWidth: { xs: '100%', md: pxTovW(1000), lg: pxTovW(800) },
          // height: '495px',
          '@media (max-width:600px)': {
            width: '100%', // Adjust width for extra-large screens (lg and above)
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: pxToRem(10), md: pxTovW(23) },
        }}
      >
        <Box
          sx={{
            borderRadius: '20px',
            background: 'white',
            padding: {
              xs: `${pxToRem(17)} ${pxToRem(20)}`,
              md: `${pxTovW(35)} ${pxTovW(48)}`,
              lg: `${pxTovW(35)} ${pxTovW(48)}`,
            },
            width: '100%',
            maxWidth: {
              xs: `calc(100% - ${pxToRem(40)})`,
              md: `calc(100% - ${pxTovW(116)})`,
              lg: `calc(100% - ${pxTovW(116)})`,
            },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(24), md: pxToRem(55) },
            alignItems: 'center',
            marginX: 'auto',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: pxToRem(16) },
            }}
          >
            Choose Type of Assessment
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: { xs: pxToRem(12), md: pxToRem(28) },
              marginTop: { xs: pxToRem(9), md: pxTovW(12) },
            }}
          >
            {Object.entries(assessmentOptions).map(([key, value], index) => (
              <OptionItem
                key={index}
                selectedValue={selectedValue}
                value={value}
                onClick={optionClickHandler}
                classInfoDisplay={props.classInfo}
              />
            ))}
          </Box>
          <Box
            sx={{
              width: { xs: pxToRem(230), md: pxTovW(650), lg: pxTovW(497) },
            }}
          >
            <PrimaryButton
              fullWidth
              onClick={submitClickHandler}
              disabled={selectedValue ? false : true}
            >
              Submit
            </PrimaryButton>
          </Box>
        </Box>
        <IconButton
          color="inherit"
          onClick={handleClose}
          sx={{
            marginX: 'auto',
          }}
        >
          <ImageWrapper
            name="close-yellow"
            type="png"
            parentFolder="icons"
            styles={{
              top: '10%',
              width: '33px', // Small white "X"
              height: '33px',
            }}
          />
        </IconButton>
      </Box>
    </Dialog>
  );
}

interface OptionItemValue {
  icon: string;
  label: string;
  phrase: string;
  typeKey: AssessmentEnum;
  backgroundColor?: string;
  borderColor?: string;
  disabled?: boolean;
}
interface OptionItemProps {
  value: OptionItemValue;
  onClick?: (assessmentKey: AssessmentEnum) => void;
  key: number;
  selectedValue?: AssessmentEnum;
  classInfoDisplay?: string;
}

const OptionItem: React.FC<OptionItemProps> = ({
  key,
  value,
  onClick,
  selectedValue,
  classInfoDisplay,
}) => {
  return (
    <Box
      key={key}
      className="option"
      onClick={() => {
        if (value.disabled === false && onClick) {
          if (value.typeKey) {
            onClick(value.typeKey);
          }
        }
      }}
      sx={{
        cursor: value.disabled ? 'not-allowed' : 'pointer',
        width: '100%',
        maxWidth: {
          xs: `calc(100% - ${pxToRem(18)})`,
          md: `calc(100% - ${pxTovW(40)})`,
          lg: `calc(100% - ${pxTovW(40)})`,
        },
        display: 'flex',
        gap: { xs: pxToRem(10), md: pxToRem(15) },
        padding: {
          xs: `${pxToRem(12)} ${pxToRem(9)} ${pxToRem(16)}`,
          md: `${pxTovW(25)} ${pxTovW(20)} ${pxTovW(29)}`,
        },
        borderRadius: '10px',
        opacity: value.disabled ? 0.5 : 1,
        backgroundColor: value.backgroundColor
          ? value.backgroundColor
          : 'white',
        border: `1px solid ${value.borderColor ? value.borderColor : 'black'}`,
        // '&:hover': {
        //   boxShadow: value.disabled ? 'none' : '0 2px 5px rgba(0, 0, 0, 1)',
        // },
        boxShadow:
          selectedValue === value.typeKey
            ? '0 5px 5px rgba(0, 0, 0, 1)'
            : 'none',
      }}
    >
      <ImageWrapper
        name={value.icon}
        type="png"
        parentFolder="icons"
        styles={{
          width: { xs: pxToRem(34), md: pxTovW(83) },
          height: 'auto',
          marginY: 'auto',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: { xs: pxToRem(7), md: pxTovW(8) },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: pxToRem(13), md: pxTovW(35) },
            fontWeight: 600,
            fontFamily: 'poppins',
          }}
        >
          {value.label}
        </Typography>
        <Typography
          variant="bodyText"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordWrap: 'break-word',
            maxWidth: { xs: pxToRem(200), md: pxTovW(450), lg: pxTovW(452) },
            fontSize: { xs: pxToRem(11), md: pxTovW(30) },
          }}
        >
          {value.phrase}
        </Typography>
        <Typography variant="bodyText" color={'primary.main'}>
          {classInfoDisplay}
        </Typography>
      </Box>
    </Box>
  );
};
const isAssessmentType = (key: any): key is AssessmentEnum => {
  return Object.values(AssessmentEnum).includes(key);
};
