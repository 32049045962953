import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
interface IProps {
  label: string;
  value: string;
  withoutBorder?: boolean;
}
export default function UserDetailField(props: IProps) {
  const { label, value, withoutBorder } = props;
  return (
    <>
      <Box
        sx={{
          paddingY: { xs: pxToRem(20), md: pxTovW(20) },
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: withoutBorder ? 'none' : '2px dashed grey',
        }}
      >
        <Box
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            width: '40%',
          }}
        >
          <Typography variant="bodyText" fontWeight={'bold'}>
            {label}
          </Typography>
        </Box>
        <Box
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            width: '60%',
            textAlign: 'end',
          }}
        >
          <Typography
            variant="bodyText"
            fontWeight={'bold'}
            color={'neutral.royalBlue'}
            paddingRight={2}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
