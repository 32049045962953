import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import {
  FileEnum,
  FileExtensionEnum,
} from '@protos/content_management/content.db_pb';
import { useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { DocumentViewer } from '../DocumentViewer';
import { theme } from 'libs/shared-ui/src/theme/themeProvider';
import getDeviceType from 'libs/shared-ui/src/commonUtils/getDeviceType';
// Assuming you have a DocumentViewer component
declare global {
  interface Window {
    Android: any;
  }
}
const styles: IStyles = {
  externalResourceWrapper: {
    backgroundColor: 'common.white',
    borderRadius: '10px',
    width: {
      xs: '100%',
      md: '100%',
      lg: pxTovW(362),
    },
    padding: {
      xs: pxToRem(10),
      md: `${pxTovW(12)} ${pxTovW(14)}`,
    },
    display: 'flex',
    flexDirection: 'row',
    gap: {
      xs: pxToRem(10),
      md: pxTovW(14),
    },
    boxShadow: {
      md: '0px 3px 15px #0000001F',
      xs: '0px 3px 15px #00000029',
    },
    cursor: 'pointer',
    maxWidth: { xs: '90%', md: '90%', lg: '80%' },
  },
  externalResourceImageWrapper: {
    backgroundColor: '#EFEFEF',
    width: {
      xs: pxToRem(73),
      lg: pxTovW(75),
    },
    height: {
      xs: pxToRem(73),
      lg: pxTovW(75),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  externalResourceImage: {
    maxWidth: '80%',
    maxHeight: '80%',
  },
  urlImageWrapper: {
    backgroundColor: '#EFEFEF',
    width: {
      xs: pxToRem(110),
      md: pxTovW(75),
    },
    height: {
      xs: pxToRem(110),
      md: pxTovW(75),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  urlImage: {
    width: {
      xs: pxToRem(64),
      md: pxTovW(43),
    },
  },
  externalResourceTextWrapper: {
    display: 'flex',
    // backgroundColor: 'red',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  externalResourceFileSize: {
    color: '#828282',
    fontSize: {
      xs: pxToRem(14),
      lg: pxTovW(18),
    },
  },
  externalResourceTextHeading: {
    display: '-webkit-box',
    WebkitLineClamp: {
      xs: 4,
      md: 2,
    },
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  urlTextHeading: {
    display: '-webkit-box',
    WebkitLineClamp: {
      xs: 4,
      md: 2,
    },
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  urlResourceWrapper: {
    backgroundColor: 'common.white',
    borderRadius: '10px',
    width: {
      md: pxTovW(570),
    },
    padding: {
      xs: pxToRem(10),
      md: `${pxTovW(12)} ${pxTovW(14)}`,
    },
    display: 'flex',
    flexDirection: 'row',
    gap: {
      xs: pxToRem(10),
      md: pxTovW(14),
    },
    boxShadow: {
      md: '0px 3px 15px #0000001F',
      xs: '0px 3px 15px #00000029',
    },
    cursor: 'pointer',
  },
  fileURLText: {
    color: 'primary.main',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textDecoration: 'underline',
    fontSize: {
      xs: pxToRem(14),
      md: pxToRem(14),
    },
  },
};

const getFileExtension = (ext: FileExtensionEnum) => {
  switch (ext) {
    case FileExtensionEnum.FILE_EXTENSION_TXT:
      return 'txt';
    case FileExtensionEnum.FILE_EXTENSION_PDF:
      return 'pdf';
    case FileExtensionEnum.FILE_EXTENSION_JPG:
      return 'jpg';
    case FileExtensionEnum.FILE_EXTENSION_GIF:
      return 'gif';
    case FileExtensionEnum.FILE_EXTENSION_PNG:
      return 'png';
    case FileExtensionEnum.FILE_EXTENSION_MP3:
      return 'mp3';
    case FileExtensionEnum.FILE_EXTENSION_MP4:
      return 'mp4';
    case FileExtensionEnum.FILE_EXTENSION_DOCX:
      return 'docx';
    case FileExtensionEnum.FILE_EXTENSION_XLSX:
      return 'xlsx';
    case FileExtensionEnum.FILE_EXTENSION_PPTX:
      return 'pptx';
    case FileExtensionEnum.FILE_EXTENSION_ZIP:
      return 'zip';
    case FileExtensionEnum.FILE_EXTENSION_HTTPS:
      return 'https';
    default:
      return '';
  }
};

interface IResourceType {
  fileExtensionType: FileExtensionEnum;
  downloadUrl?: string;
  fileSizeInMb?: number;
  fileName: string;
  fileType: FileEnum;
  filePreviewUrl?: string;
  gcpFileUrl?: string;
}

interface IProps {
  element: IResourceType;
}

const getIconImage = (fileType: FileEnum) => {
  switch (fileType) {
    case FileEnum.FILE_TYPE_TEXT:
    case FileEnum.FILE_TYPE_IMAGE:
    case FileEnum.FILE_TYPE_AUDIO:
    case FileEnum.FILE_TYPE_VIDEO:
    case FileEnum.FILE_TYPE_DOCUMENT:
      return '/assets/shared-ui/tempAssets/doc-icon.png';
    case FileEnum.FILE_TYPE_SPREADSHEET:
    case FileEnum.FILE_TYPE_PRESENTATION:
      return '/assets/shared-ui/icons/fileIcons/ppt.png';
    case FileEnum.FILE_TYPE_ARCHIVE:
    case FileEnum.FILE_TYPE_PDF:
      return '/assets/shared-ui/icons/fileIcons/pdf.png';
    case FileEnum.FILE_TYPE_URL:
      return '/assets/shared-ui/icons/fileIcons/url.png';
    case FileEnum.FILE_TYPE_UNKNOWN:
      return '/assets/shared-ui/icons/unknown_file.png';
    default:
      return '';
  }
};

export const FilePreviewCard = (props: IProps) => {
  const { element } = props;
  // const { fileUrl } = element;
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const android = window.Android;
  const deviceType = getDeviceType();
  const toggleFile = () => {
    setOpen(!open);
  };
  function redirectToBrowser(urlForDownload: string): void {
    console.log('Pushing user details to Android');
    try {
      if (deviceType === 'android' && android?.redirectToBrowser) {
        android.redirectToBrowser(urlForDownload);
      }
    } catch (error) {
      console.log(
        'An error occurred while redirecting to the browser on Android:',
        error
      );
    }
  }
  const renderFilePreview = () => {
    switch (element.fileType) {
      case FileEnum.FILE_TYPE_TEXT:
      case FileEnum.FILE_TYPE_AUDIO:
      case FileEnum.FILE_TYPE_SPREADSHEET:
      case FileEnum.FILE_TYPE_ARCHIVE:
      case FileEnum.FILE_TYPE_VIDEO:
      case FileEnum.FILE_TYPE_URL:
      case FileEnum.FILE_TYPE_DOCUMENT:
      case FileEnum.FILE_TYPE_PRESENTATION:
      case FileEnum.FILE_TYPE_UNKNOWN:
        return (
          <>
            <Box sx={styles.externalResourceWrapper} onClick={toggleFile}>
              <Box sx={styles.externalResourceImageWrapper}>
                <Box
                  component={'img'}
                  src={getIconImage(element.fileType)}
                  sx={styles.externalResourceImage}
                />
              </Box>
              <Box sx={styles.externalResourceTextWrapper}>
                <Typography
                  sx={styles.externalResourceTextHeading}
                  variant={isIpadOnly ? 'h2' : 'h3'}
                  fontWeight={'medium'}
                >
                  {element.fileName}
                </Typography>
                {element.fileSizeInMb && (
                  <Typography
                    sx={styles.externalResourceFileSize}
                    variant={'h4'}
                  >
                    {element.fileSizeInMb + 'MB'}
                  </Typography>
                )}
                {element.fileType === FileEnum.FILE_TYPE_URL &&
                  element.gcpFileUrl && (
                    <Typography
                      sx={styles.fileURLText}
                      variant={'elementBodyText'}
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        if (element.gcpFileUrl) {
                          if (deviceType === 'android') {
                            redirectToBrowser(element?.gcpFileUrl);
                          } else {
                            window.open(element.gcpFileUrl);
                          }
                        }
                      }}
                    >
                      {element.gcpFileUrl}
                    </Typography>
                  )}
              </Box>
            </Box>
            {element.gcpFileUrl && open && (
              <DocumentViewer
                downloadUrl={element.downloadUrl}
                previewUrl={element.filePreviewUrl}
                gcpUrl={element.gcpFileUrl}
                type={element.fileType}
                fileName={element.fileName}
                open={open}
                closeFile={toggleFile}
              />
            )}
          </>
        );
      case FileEnum.FILE_TYPE_PDF:
      case FileEnum.FILE_TYPE_IMAGE:
        return (
          <Box
            sx={styles.externalResourceWrapper}
            onClick={() => {
              toggleFile();
            }}
          >
            <Box sx={styles.externalResourceImageWrapper}>
              <Box
                component={'img'}
                src={
                  element.fileType === FileEnum.FILE_TYPE_IMAGE
                    ? element.gcpFileUrl
                    : getIconImage(element.fileType)
                }
                sx={styles.externalResourceImage}
              />
            </Box>
            <Box sx={styles.externalResourceTextWrapper}>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
                variant={isIpadOnly ? 'h2' : 'h3'}
                fontWeight={'medium'}
              >
                {element.fileName}
              </Typography>
              {element.fileSizeInMb && (
                <Typography sx={styles.externalResourceFileSize} variant={'h4'}>
                  {element.fileSizeInMb + 'MB'}
                </Typography>
              )}
            </Box>
            {element.gcpFileUrl && open && (
              <DocumentViewer
                downloadUrl={element.downloadUrl}
                type={element.fileType}
                fileName={element.fileName}
                open={open}
                closeFile={toggleFile}
                gcpUrl={element.gcpFileUrl}
              />
            )}
          </Box>
        );
        return null;
      default:
        return null;
    }
  };
  return renderFilePreview();
};
