import { Button, Popover, styled, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import { MouseEvent, useState } from 'react';
import { IconWrapper } from '../../elements/IconWrapper/Index';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
interface LockToggleButtonProps {
  isLocked?: boolean | ContentLockStatusType;
  // onClick: (event: React.MouseEvent<HTMLDivElement>, lessonId: string) => void;
  lessonId?: string;
  handleOpenPopup?: (ev?: React.MouseEvent) => void;
  toggleFunction?: {
    lockClickHandler?: () => void;
    unlockClickHandler?: () => void;
  };
}
export const LockToggleButton: React.FC<LockToggleButtonProps> = ({
  isLocked,
  // onClick,
  lessonId,
  handleOpenPopup,
  toggleFunction,
}) => {
  const getImageName = () => {
    if (typeof isLocked === 'boolean') {
      return isLocked ? 'lock' : 'unlock';
    }
    switch (isLocked) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'partial_lock';
      default:
        return ''; // or a default image name
    }
  };

  const getTooltipTitle = () => {
    if (typeof isLocked === 'boolean') {
      return isLocked ? 'Lock' : 'Unlock';
    }
    switch (isLocked) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'Unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'Lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'Partially Locked';
      default:
        return 'Status Unknown'; // or another default tooltip title
    }
  };

  // ----------------------------------------------------------------------------

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        border: 'none',
        cursor: 'pointer',
        borderRadius: { xs: pxToRem(7), md: pxToRem(5), lg: pxTovW(7) },
        height: { xs: pxToRem(30), md: pxToRem(20), lg: pxTovW(32) },
        width: { xs: pxToRem(28), md: pxToRem(20), lg: pxTovW(32) },
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BootstrapTooltip title={getTooltipTitle()}>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleClick(event);
            handleOpenPopup && handleOpenPopup(event);
          }}
        >
          <ImageWrapper
            // onClick={handleOpenPopup}
            name={getImageName()}
            type="png"
            parentFolder="icons"
            styles={{
              height: { xs: pxToRem(30), md: pxToRem(20), lg: pxTovW(32) },
              width: { xs: pxToRem(28), md: pxToRem(20), lg: pxTovW(32) },
            }}
          />
        </Button>
      </BootstrapTooltip>

      {toggleFunction && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{
            width: { xs: 'unset', md: pxTovW(320), lg: pxTovW(240) },
            // height: 'max-content',
            '& .MuiPopover-root': {},
            '& .MuiPopover-paper': {
              // width: { xs: pxTovW(277), md: pxTovW(183), lg: pxTovW(183) },
              borderRadius: { xs: pxToRem(10), md: pxToRem(10) },
            },
          }}
        >
          <Box>
            {toggleFunction.lockClickHandler && (
              <>
                <RenderPopoverButton
                  label="Keep Unlocked"
                  onClick={(event) => {
                    handleClose(event);
                  }}
                />
                <RenderPopoverButton
                  label="Lock"
                  onClick={(event) => {
                    handleClose(event);
                    if (toggleFunction.lockClickHandler) {
                      toggleFunction.lockClickHandler();
                    }
                  }}
                />
              </>
            )}

            {toggleFunction.unlockClickHandler && (
              <>
                <RenderPopoverButton
                  label="Keep Locked"
                  onClick={(event) => {
                    handleClose(event);
                  }}
                />
                <RenderPopoverButton
                  label="Unlock"
                  onClick={(event) => {
                    handleClose(event);
                    if (toggleFunction.unlockClickHandler) {
                      toggleFunction.unlockClickHandler();
                    }
                  }}
                />
              </>
            )}
          </Box>
        </Popover>
      )}
    </Box>
  );
};

interface IRenderPopoverButton {
  onClick: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  label: string;
}
const RenderPopoverButton = ({ onClick, label }: IRenderPopoverButton) => {
  return (
    <Button
      onClick={(event) => onClick(event)}
      sx={{
        borderBottom: '1px solid #96FFC5',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: { xs: pxToRem(10), md: pxTovW(10) },
        width: { xs: pxToRem(180), md: pxTovW(280), lg: pxTovW(210) },
        padding: `${pxToRem(11)} ${pxToRem(20)}`,
        cursor: 'pointer',
      }}
    >
      <IconWrapper
        name={label === 'Lock' || label === 'Keep Locked' ? 'lock' : 'unlock'}
        // size={isIpadOnly ? 'large' : 'md'}
        size={'md'}
        parentFolder="icons"
        type="png"
      />
      <Typography variant="h4" fontWeight="medium">
        {label}
      </Typography>
    </Button>
  );
};
