// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/content_management/cms.admin.content.apis.proto (package geneo.cms.admin.content.upload.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteAdminContentRequest, DeleteAdminContentResponse, UpsertAdminContentRequest, UpsertAdminContentResponse } from "./cms.admin.content.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.cms.admin.content.upload.apis.AdminUploadContentAPIServiceV1
 */
export const AdminUploadContentAPIServiceV1 = {
  typeName: "geneo.cms.admin.content.upload.apis.AdminUploadContentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.cms.admin.content.upload.apis.AdminUploadContentAPIServiceV1.upsertAdminContent
     */
    upsertAdminContent: {
      name: "upsertAdminContent",
      I: UpsertAdminContentRequest,
      O: UpsertAdminContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.admin.content.upload.apis.AdminUploadContentAPIServiceV1.deleteAdminContent
     */
    deleteAdminContent: {
      name: "deleteAdminContent",
      I: DeleteAdminContentRequest,
      O: DeleteAdminContentResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

