// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.student.events.apis.proto (package geneo.ai.student.events.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";

/**
 * @generated from message geneo.ai.student.events.apis.StudentJourneyRequest
 */
export class StudentJourneyRequest extends Message<StudentJourneyRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp;

  constructor(data?: PartialMessage<StudentJourneyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.StudentJourneyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_date", kind: "message", T: Timestamp },
    { no: 3, name: "end_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentJourneyRequest {
    return new StudentJourneyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentJourneyRequest {
    return new StudentJourneyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentJourneyRequest {
    return new StudentJourneyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StudentJourneyRequest | PlainMessage<StudentJourneyRequest> | undefined, b: StudentJourneyRequest | PlainMessage<StudentJourneyRequest> | undefined): boolean {
    return proto3.util.equals(StudentJourneyRequest, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.apis.StudentModuleScore
 */
export class StudentModuleScore extends Message<StudentModuleScore> {
  /**
   * @generated from field: optional int32 module_id = 1;
   */
  moduleId?: number;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: float score = 3;
   */
  score = 0;

  /**
   * @generated from field: optional int32 number_of_resources_accessed = 4;
   */
  numberOfResourcesAccessed?: number;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 5;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  constructor(data?: PartialMessage<StudentModuleScore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.StudentModuleScore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "number_of_resources_accessed", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentModuleScore {
    return new StudentModuleScore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentModuleScore {
    return new StudentModuleScore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentModuleScore {
    return new StudentModuleScore().fromJsonString(jsonString, options);
  }

  static equals(a: StudentModuleScore | PlainMessage<StudentModuleScore> | undefined, b: StudentModuleScore | PlainMessage<StudentModuleScore> | undefined): boolean {
    return proto3.util.equals(StudentModuleScore, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.apis.StudentJourneyEvent
 */
export class StudentJourneyEvent extends Message<StudentJourneyEvent> {
  /**
   * @generated from field: string event = 1;
   */
  event = "";

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: string details = 3;
   */
  details = "";

  constructor(data?: PartialMessage<StudentJourneyEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.StudentJourneyEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentJourneyEvent {
    return new StudentJourneyEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentJourneyEvent {
    return new StudentJourneyEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentJourneyEvent {
    return new StudentJourneyEvent().fromJsonString(jsonString, options);
  }

  static equals(a: StudentJourneyEvent | PlainMessage<StudentJourneyEvent> | undefined, b: StudentJourneyEvent | PlainMessage<StudentJourneyEvent> | undefined): boolean {
    return proto3.util.equals(StudentJourneyEvent, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.apis.Statistics
 */
export class Statistics extends Message<Statistics> {
  /**
   * @generated from field: string total_time_spent = 1;
   */
  totalTimeSpent = "";

  /**
   * @generated from field: string active_time = 2;
   */
  activeTime = "";

  /**
   * @generated from field: int32 number_of_resources_accessed = 4;
   */
  numberOfResourcesAccessed = 0;

  /**
   * @generated from field: optional int32 number_of_lesson_plan_accessed = 5;
   */
  numberOfLessonPlanAccessed?: number;

  /**
   * @generated from field: optional int32 number_of_mcqs_practiced = 6;
   */
  numberOfMcqsPracticed?: number;

  /**
   * @generated from field: optional int32 number_of_correct_mcqs_practiced = 7;
   */
  numberOfCorrectMcqsPracticed?: number;

  /**
   * @generated from field: optional int32 number_of_wrong_mcqs_practiced = 8;
   */
  numberOfWrongMcqsPracticed?: number;

  constructor(data?: PartialMessage<Statistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.Statistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_time_spent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "active_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "number_of_resources_accessed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "number_of_lesson_plan_accessed", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "number_of_mcqs_practiced", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "number_of_correct_mcqs_practiced", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "number_of_wrong_mcqs_practiced", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Statistics {
    return new Statistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Statistics {
    return new Statistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Statistics {
    return new Statistics().fromJsonString(jsonString, options);
  }

  static equals(a: Statistics | PlainMessage<Statistics> | undefined, b: Statistics | PlainMessage<Statistics> | undefined): boolean {
    return proto3.util.equals(Statistics, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.apis.StudentJourneyResponse
 */
export class StudentJourneyResponse extends Message<StudentJourneyResponse> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: repeated geneo.ai.student.events.apis.StudentJourneyEvent student_journey = 3;
   */
  studentJourney: StudentJourneyEvent[] = [];

  /**
   * @generated from field: repeated geneo.ai.student.events.apis.StudentModuleScore module_score = 4;
   */
  moduleScore: StudentModuleScore[] = [];

  /**
   * @generated from field: geneo.ai.student.events.apis.Statistics daily_statistics = 7;
   */
  dailyStatistics?: Statistics;

  /**
   * @generated from field: string summary = 8;
   */
  summary = "";

  constructor(data?: PartialMessage<StudentJourneyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.StudentJourneyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "student_journey", kind: "message", T: StudentJourneyEvent, repeated: true },
    { no: 4, name: "module_score", kind: "message", T: StudentModuleScore, repeated: true },
    { no: 7, name: "daily_statistics", kind: "message", T: Statistics },
    { no: 8, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentJourneyResponse {
    return new StudentJourneyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentJourneyResponse {
    return new StudentJourneyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentJourneyResponse {
    return new StudentJourneyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentJourneyResponse | PlainMessage<StudentJourneyResponse> | undefined, b: StudentJourneyResponse | PlainMessage<StudentJourneyResponse> | undefined): boolean {
    return proto3.util.equals(StudentJourneyResponse, a, b);
  }
}

