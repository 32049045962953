import { Box, Chip, Tab, Tabs, Typography } from '@mui/material';

import { deserify, IStyles, pxToRem, pxTovW } from '@geneo2-web/shared-ui';
import { ListType } from '@protos/user_management/ums.self_registration.apis_pb';

const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `${pxTovW(1)} solid #E0E0E0`,
    borderBottom: `${pxTovW(1)} solid #E0E0E0`,
    backgroundColor: '#F7F6F6',

    width: { xs: '100vw', md: '100%' },
  },

  tabs: {
    '&.MuiTabs-root': {
      bgcolor: 'unset',

      '& > div > div': {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '100%',
        // backgroundColor: 'blue',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },

  tab: {
    '&.MuiButtonBase-root': {
      fontSize: { xs: pxToRem(25), md: '1vw' },
      color: '#FFFFFF',
      fontWeight: 'light',
      m: {
        xs: `${pxToRem(10)} ${pxToRem(0)} ${pxToRem(5)} ${pxToRem(0)}`,
        md: `${pxTovW(20)} ${pxTovW(20)} ${pxTovW(8)} ${pxTovW(20)}`,
      },
    },
    '&.Mui-selected': {
      color: '#FFFFFF',
      fontWeight: 'bold',
    },

    // border: '1px solid red',
    p: { xs: `${pxToRem(5)} ${pxToRem(5)}`, md: `0` },
  },

  tabBox: {
    display: 'flex',
    gap: { xs: pxToRem(13), md: pxTovW(13) },
    p: { md: `${pxTovW(0)} ${pxTovW(21)}` },
    // backgroundColor: 'red',
    width: { xs: pxToRem(150), md: pxTovW(150) },

    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface IProps {
  mapData: ListType[];
  handleChange: (arg: ListType) => void;
  tabState: ListType;
}
export const AdminListtypeTabs = ({
  mapData,
  handleChange,
  tabState,
}: IProps) => {
  return (
    <Box sx={styles.root}>
      <Tabs onChange={(e, val) => handleChange(val)} sx={styles.tabs}>
        {mapData.map((elem, index) => (
          <Tab
            key={index}
            sx={styles.tab}
            label={<TabValue elem={elem} stateValue={tabState} />}
          />
        ))}
      </Tabs>
    </Box>
  );
};

interface ITabProps {
  elem: ListType;
  stateValue: ListType;
}
const TabValue = ({ elem, stateValue }: ITabProps) => {
  console.log(elem, stateValue);
  return (
    <Box sx={styles.tabBox}>
      {stateValue === elem ? (
        <Chip
          label={
            <Typography
              variant="h3"
              sx={{
                margin: { xs: '15px', md: '10px' },
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              {elem === ListType.APPROVAL_PENDING ? 'Pending' : 'Completed'}
            </Typography>
          }
          size="small"
          sx={{
            visibility: elem === stateValue ? 'visible' : 'hidden',
            // padding: { xs: `${pxToRem(8)} ${pxToRem(16)}` },
            height: pxToRem(39),
            backgroundColor:
              elem === ListType.APPROVAL_PENDING ? '#F54040' : '#0AA34F',
            transition: {
              visibility: '3s ease-out',
            },
            '& .MuiChip-label': {
              //   backgroundColor: 'red',
              display: 'flex',
              whiteSpace: 'normal',
              width: { xs: pxToRem(150) },
              height: pxToRem(39),
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            },
          }}
        />
      ) : (
        <Typography
          variant="h3"
          fontWeight="light"
          sx={{
            color: elem === ListType.APPROVAL_PENDING ? '#F54040' : '#0AA34F',
            transition: 'color 3s ease-in-out',
          }}
        >
          {elem === ListType.APPROVAL_PENDING ? 'Pending' : 'Completed'}
        </Typography>
      )}
    </Box>
  );
};
