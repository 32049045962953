import { Box } from '@mui/system';
import { IStyles } from '../../../commonUtils/styleUtils';
import React from 'react';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import ImageWrapper from '../ImageWrapper';
import { Typography } from '@mui/material';

const styles: IStyles = {
  root: {
    all: 'unset',
    width: { xs: '100%', md: pxTovW(680) },
    height: { md: pxTovW(179) },
    borderRadius: { xs: pxToRem(15), md: pxTovW(30) },
    display: 'flex',
    cursor: 'pointer'
  },
};
interface IVariants {
  small: string;
  regular: string;
}
const variantWidth = {
  xs: { small: '100%', regular: '100%' },
  md: { small: pxTovW(547), regular: pxTovW(780) },
  lg: { small: pxTovW(447), regular: pxTovW(680) },
};
const variantHeight = {
  xs: { small: pxToRem(75), regular: pxToRem(88) },
  md: { small: pxTovW(120), regular: pxTovW(179) },
  lg: { small: pxTovW(100), regular: pxTovW(179) },
};
interface IAssessmentButtonProps {
  text: string;
  subText: string;
  backgroundColor: string;
  imageName?: string;
  variant: keyof IVariants;
  clickHandler?: () => void;
}
export const AssessmentButton = (props: IAssessmentButtonProps) => {
  const { text, subText, backgroundColor, imageName, clickHandler, variant } =
    props;
  return (
    <Box
      component="button"
      sx={{
        ...styles.root,
        backgroundColor: backgroundColor || 'white',
        width: {
          xs: variantWidth.xs[variant],
          md: variantWidth.md[variant],
          lg: variantWidth.lg[variant],
        },
        height: {
          xs: variantHeight.xs[variant],
          md: variantHeight.md[variant],
          lg: variantHeight.lg[variant],
        },
      }}
      onClick={clickHandler}
    >
      {imageName && (
        <ImageWrapper
          name={imageName}
          type="png"
          parentFolder="illustrations"
          styles={{ height: '100%' }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: { xs: pxToRem(10), md: pxTovW(20) },
          gap: { xs: pxToRem(30), md: pxTovW(45) },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: { xs: pxToRem(2), md: pxTovW(10) },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: pxToRem(17), md: variant === 'regular' ? pxTovW(35) : pxTovW(22) },
              fontWeight: 'bold'
            }}
          >
            {text}
          </Typography>
          <Typography
            variant={variant === 'regular' ? 'bodyText' : 'smallText'}
            sx={{
              fontSize: { xs: pxToRem(12), md: variant === 'regular' ? pxTovW(24) : pxTovW(20) },
            }}
          >
            {subText}
          </Typography>
        </Box>
        <ImageWrapper
          styles={{
            width: { xs: pxToRem(23), md: variant === 'regular' ? pxTovW(45) : pxTovW(30) },
            height: { xs: pxToRem(24), md: variant === 'regular' ? pxTovW(45) : pxTovW(30) },
          }}
          name="right-arrow-circle"
          type="png"
          parentFolder="illustrations"
        />
      </Box>
    </Box>
  );
};
