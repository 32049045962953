// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.student.events.apis.proto (package geneo.ai.student.events.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { StudentEvent, StudentEventResponse } from "@protos/geneo_ai/ai.student.events_pb";
import { MethodKind } from "@bufbuild/protobuf";
import { StudentJourneyRequest, StudentJourneyResponse } from "./ai.student.events.apis_pb";

/**
 * @generated from service geneo.ai.student.events.apis.StudentEventsAPIServiceV1
 */
export const StudentEventsAPIServiceV1 = {
  typeName: "geneo.ai.student.events.apis.StudentEventsAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ai.student.events.apis.StudentEventsAPIServiceV1.studentEventProcess
     */
    studentEventProcess: {
      name: "studentEventProcess",
      I: StudentEvent,
      O: StudentEventResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service geneo.ai.student.events.apis.StudentJourneyAPIServiceV1
 */
export const StudentJourneyAPIServiceV1 = {
  typeName: "geneo.ai.student.events.apis.StudentJourneyAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ai.student.events.apis.StudentJourneyAPIServiceV1.getStudentJourney
     */
    getStudentJourney: {
      name: "getStudentJourney",
      I: StudentJourneyRequest,
      O: StudentJourneyResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

