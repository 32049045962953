import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';

import {
  ActionsPopup,
  ChapterSelectedCard,
  ChipBadge,
  ContentDetailCard,
  ContentLockPopup,
  IStyles,
  IconWrapper,
  Loader,
  LockToggleButton,
  NoContentCard,
  SectionListWithTopCarousel,
  TopicCard,
  cloneObject,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  getEnumKeyByEnumValue,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  resourceTypeName,
  theme,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import {
  ChapterTopicMiniInfo,
  ResourceInfo,
  SubjectChapterMiniInfo,
} from '@protos/content_management/content.common.apis_pb';
import {
  ChapterMetaInfo,
  DownloadedSubject,
  Module_ModuleCategoryEnum,
  Resource_ResourceEnum,
  ResourceCategoryEnum,
} from '@protos/content_management/content.db_pb';
import {
  CloudUpload,
  SessionModeEnum,
} from '@protos/learning_management/lms.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { DownloadButtonWrapper } from '../../../components/DownloadButtonWrapper';
import { v4 as uuidv4 } from 'uuid';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import {
  LESSON_PLAN_LISTS,
  RESOURCE_UPLOAD,
  TEACH_CHAPTER_SELECTION,
} from '../../../routeHandling/RoutesNomenclature';
import { onResourceClick } from '../../../utils/resource';
import { interactionEvent } from '../../Auth/auth.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { ResourceShimmer } from '../LessonPlanLists/shimmer';
import {
  setChapterResources,
  setChapterTopicInfo,
  setCustomResourceData,
  setSelectedTopic,
} from '../reducer/teach.slice';
import { aiChapterCloseEvent, aiChapterOpenEvent } from '../teach.events';
import { CustomUploadFileResponse } from '../../Homework/reducer/homework.slice';

const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    backgroundColor: 'neutral.paleGrey',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: pxTovW(25),
    padding: {
      md: `${pxTovW(14)} ${pxToRem(20)}`,
      lg: `${pxTovW(14)} ${pxTovW(240)}`,
    },
    paddingTop: { md: pxTovW(40) },
  },
  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(14), md: pxTovW(10) },
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(5)} ${pxToRem(20)}`,
      md: 0,
    },
  },
  topicContainer: {
    flexBasis: { xs: '100%', md: '40%', lg: pxTovW(554) },

    // maxWidth: { md: pxTovW(554) },
    // paddingLeft: { md: pxTovW(246) },
    // paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
  },
  chapterBox: {
    padding: { xs: pxToRem(20), md: 0 },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
  },
  resourceContainer: {
    flexBasis: { xs: '100%', md: 1, lg: pxTovW(862) },

    marginBottom: { xs: pxToRem(43), md: 0 },
    // backgroundColor: 'blue',
  },
  chooseTopicBox: {
    display: 'flex',
    flexDirection: 'column',
    // gap: { xs: pxToRem(25) },
    gap: { xs: pxToRem(7), md: pxTovW(18) },
  },
  topicBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    '& > div': { borderBottom: '1px solid #C6C6C6' },
  },
};

interface FetchFuncArg {
  subjectId: string;
  chapterId: string;
}

interface IlockedPopupData {
  resourceId?: number | string;
  image: string;
  noofTopics: string;
  title: string;
  moduleId?: number;
  category?: Module_ModuleCategoryEnum;
  lockedStatus: ContentLockStatusType;
}

export default function TeachTopicSelection() {
  const [topicLoading, setTopicLoading] = useState<
    'loading' | 'completed' | 'error'
  >('completed');
  const [resourceLoading, setResourceLoading] = useState<
    'loading' | 'completed' | 'error'
  >('completed');
  const [isContentLockPopupOpen, setIsContentLockPopupOpen] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState<string>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;

  const [lockedPopupData, setLockedPopData] = useState<IlockedPopupData | null>(
    null
  );
  const [contentLockData, setContentLockData] =
    useState<ContentLockModuleData>();
  const teacher_id = getLocalStorage('userId');
  const { subject_id, chapter_id } = useParams();

  const { setSelectedFunction } = useGlobalContext();
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV2ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  interface DataForDelete {
    resourceId: string;
    resourceTitle: string;
  }
  const [selectedDataForDelete, setSelectedDataForDelete] = useState<
    DataForDelete | undefined
  >();

  useEffect(() => {
    // Back button functionality
    const redirectToTeachDashboard = async () => {
      await aiChapterCloseEvent(Number(chapter_id));
      navigate(`${TEACH_CHAPTER_SELECTION}/${subject_id}`);
    };
    setSelectedFunction(() => redirectToTeachDashboard);
    dispatch(setCustomResourceData(undefined));
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const topicNavigation = async (topicInfo: ChapterTopicMiniInfo) => {
    await interactionEvent({
      url: 'Teacher_teach_topic_selection',
      context: 'Choose_a_topic',
      name: 'VIEW',
    });
    dispatch(setSelectedTopic(topicInfo));
    navigate(
      `${LESSON_PLAN_LISTS}/${subject_id}/${chapter_id}/${topicInfo.topicId}`
    );
  };

  const handleOpenPopup = (
    ev?: React.MouseEvent,
    topicData?: ChapterTopicMiniInfo,
    categories?: ResourceInfo
  ) => {
    ev?.stopPropagation();
    if (topicData && categories) {
      console.log(
        'Both topicData and categories are provided. Please provide only one.'
      );
      return;
    }
    if (topicData) {
      setLockedPopData({
        image: findSelectedChapterData().image, // Assuming topicData contains an image property
        noofTopics: topicData.topicNo ? String(topicData.topicNo) : '',
        title: topicData?.topicTitle || '',
        moduleId: topicData.topicId,
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
        lockedStatus:
          getContentLockedData(topicData.topicId, contentLockData)
            ?.lockStatus ||
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED,
      });
      setIsContentLockPopupOpen(true);
    } else if (categories) {
      setLockedPopData({
        resourceId: categories.resourceId,
        image: categories.posterImageUrl
          ? getMediaBasePath(categories.posterImageUrl, 'processedMediaBucket')
          : '',
        noofTopics: '',
        title: categories.title || '',
        moduleId: Number(chapter_id),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER,
        lockedStatus: getCategoryLockedStatus(
          categories.resourceId,
          contentLockData
        ),
      });

      setIsContentLockPopupOpen(true);
    } else {
      dispatch(
        setToastInfo({
          variant: 'error',
          label: 'Something went wrong',
          open: true,
        })
      );
    }
  };

  const handleClosePopup = () => {
    setIsContentLockPopupOpen(false);
    setLockedPopData(null);
  };

  // Getting Data for ChapterSelectedCard
  const { subject_chapter_info } = deserify(
    useAppSelector((state) => state.teach)
  );
  // Getting Data for ChapterSelectedCard
  const findSelectedChapterData = () => {
    const selectedChapter = subject_chapter_info?.response.find(
      (chapter: SubjectChapterMiniInfo) =>
        chapter_id && +chapter_id === chapter.chapterId
    );
    if (selectedChapter) {
      return {
        name: selectedChapter.chapterTitle,
        image: getMediaBasePath(
          selectedChapter.posterImagesUrl,
          'processedMediaBucket'
        ),
      };
    }
    return { name: '', image: '' };
  };

  // Getting all the Topics in chapter
  const { subject_topic_info } = deserify(
    useAppSelector((state) => state.teach)
  );

  // Getting all the Chapter Resources
  const { chapter_resources } = deserify(
    useAppSelector((state) => state.teach)
  );

  // Getting current class and subject selected
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);

  async function fetchChapterTopicInfo({ subjectId, chapterId }: FetchFuncArg) {
    try {
      setTopicLoading('loading');

      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchChapterTopicInfo(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            chapterId: Number(chapterId),
            sectionId: class_subject_info?.sectionId,
          }
        );
      if (response) {
        if (isContentLockFeature) {
          await getLessonLockedStatus(teacher_id);
        }
        setTopicLoading('completed');
        if (response?.data) {
          const data = response.data;
          await aiChapterOpenEvent(Number(chapter_id), uuidv4());
          data.chapterTopics.sort((a, b) => a.topicNo - b.topicNo);
          dispatch(setChapterTopicInfo(data));
        }
      }
    } catch (err) {
      setTopicLoading('error');
      console.log(err);
    }
  }

  async function fetchChapterResources({ subjectId, chapterId }: FetchFuncArg) {
    try {
      // setLoading(true);
      setResourceLoading('loading');

      const response =
        await ContentCommonAPIServiceV2ClientWithStatusCodeHandler.fetchChapterResources(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            chapterId: Number(chapterId),
            sectionId: class_subject_info?.sectionId,
          }
        );
      if (response) {
        if (isContentLockFeature && contentLockData) {
          await getLessonLockedStatus(teacher_id);
        }
        setResourceLoading('completed');
        if (response?.data) {
          const data = response.data;
          dispatch(setChapterResources(data));
        }
      }

      // setLoading(false);
    } catch (err) {
      setResourceLoading('error');
      // setLoading(false);
      // setError(err);
      console.log(err);
    }
  }

  useEffect(() => {
    if (subject_id && chapter_id) {
      fetchChapterTopicInfo({ subjectId: subject_id, chapterId: chapter_id });

      fetchChapterResources({ subjectId: subject_id, chapterId: chapter_id });
    }
  }, [chapter_id]);

  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const { custom_resource_data } = deserify(
    useAppSelector((state) => state.teach)
  );
  const featureInfo = user_info?.schoolDetails[0]?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const teachClassSubjects = user_info?.teachClassSubjects.find(
    (classData) =>
      class_subject_info?.classname === classData.className &&
      class_subject_info?.section === classData.sectionName
  );
  const selectedSubject = teachClassSubjects
    ? teachClassSubjects.subjects.find(
        (subjectData) => subjectData.subjectId === Number(subject_id)
      )
    : undefined;

  const [fetchingState, setFetchingState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});

  const editResource = async (editProps: {
    resourceId: string;
    coverTitle: string;
  }) => {
    try {
      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchResourceEditDetails(
          {
            personId: BigInt(teacher_id),
            resourceId: editProps.resourceId,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          }
        );
      console.log('response:', response);
      if (response) {
        dispatch(
          setCustomResourceData({
            resourceId: editProps.resourceId,
            selected_chapter: {
              chapter_name: findSelectedChapterData().name,
              chapter_id: Number(chapter_id),
            },
            resourceTitle: response.coverTitle || editProps.coverTitle,
            resourceTime: response.estimatedTimeInMin,
            // contentLock: response. ,
            custom_resource_cover_image: response.coverImageInfo
              ? createCustomUploadFileResponse([response.coverImageInfo])[0]
              : undefined,
            custom_resource_uploaded_files: createCustomUploadFileResponse(
              response.filesInfo
            ),
          })
        );

        navigate(`${RESOURCE_UPLOAD}/${editProps.resourceId}`);
      }
      console.log('response:', response);
    } catch (error) {
      console.log('error:', error);
      dispatch(
        setToastInfo({
          label: `Error Occurred! While trying to get the data.`,
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const createCustomUploadFileResponse = (
    cloudUploadData: CloudUpload[]
  ): CustomUploadFileResponse[] => {
    return cloudUploadData.map((data) => {
      return {
        uploadFileData: data,
        fileUploadStatus: 'success',
        fileSize: (data.fileSizeInMb || 0) * (1024 * 1024),
      };
    });
  };

  const deleteResource = async () => {
    try {
      const response =
        await TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler.deleteTeacherContent(
          {
            teacherId: BigInt(teacher_id),
            resourceId: selectedDataForDelete?.resourceId,
          }
        );
      console.log('response:', response);
      if (response) {
        dispatch(
          setToastInfo({
            label: `${selectedDataForDelete?.resourceTitle} deleted successfully`,
            variant: 'info',
            open: true,
          })
        );

        window.location.reload();
      }
      console.log('response:', response);
    } catch (error) {
      console.log('error:', error);

      dispatch(
        setToastInfo({
          label: `Error Occurred! Unable to delete the resource.`,
          variant: 'error',
          open: true,
        })
      );
    }
  };

  useEffect(() => {
    if (
      subject_id &&
      chapter_id &&
      !isNaN(Number(subject_id)) &&
      !isNaN(Number(chapter_id))
    ) {
      (async () => {
        setFetchingState(true);
        const indexDbData = await findDownloadedSubjectByParams({
          subjectId: Number(subject_id),
        });
        const meta = deepClone(downloadedMetaData);
        if (indexDbData.length > 0) {
          const data = indexDbData[0];
          const offlineData =
            await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
              {
                chapterId: Number(chapter_id),
              }
            );
          meta[Number(chapter_id)] = offlineData.data;
        }
        setDownloadedMetaData(meta);
        setFetchingState(false);
      })();
    }
  }, [subject_id, chapter_id]);

  useEffect(() => {
    const fetchLessonLockedStatus = async () => {
      setIsSubmitted(false);
      try {
        if (isContentLockFeature) {
          await getLessonLockedStatus(teacher_id);
        }
      } catch (error) {
        console.error('Error fetching lesson lock status:', error);
        // Handle the error if necessary
      }
    };

    fetchLessonLockedStatus();
  }, [isSubmitted]);

  // const getLessonLockedStatus = async (teacherId: string) => {
  //   try {
  //     const response =
  //       await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.moduleLockInfoFetch(
  //         {
  //           teacherId: BigInt(teacherId),
  //           subjectId: class_subject_info?.subjectId,
  //           schoolClassSectionId: class_subject_info?.sectionId,
  //         }
  //       );
  //     const data = response?.data;
  //     if (data) {
  //       dispatch(setContentLock(data));
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleLockedRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = getEnumKeyByEnumValue(
      ContentLockStatusType,
      Number(event.target.value)
    );
    const currentData = cloneObject(lockedPopupData);
    if (val && currentData) {
      currentData.lockedStatus = ContentLockStatusType[val];
      setLockedPopData(currentData);
    }
  };

  const handleSubmit = async (ev?: React.MouseEvent) => {
    ev?.stopPropagation();
    try {
      // Ensure essential values are present before making the API call
      if (!lockedPopupData?.moduleId || !lockedPopupData?.category) {
        throw new Error('Module ID and category must be provided');
      }

      // Build the moduleInfo object dynamically, including resourceId only if it exists
      const moduleInfo = {
        moduleId: lockedPopupData?.moduleId,
        category: lockedPopupData?.category,
        lockStatus: lockedPopupData.lockedStatus,
        ...(lockedPopupData?.resourceId && {
          resourceId: String(lockedPopupData.resourceId),
        }),
      };

      console.log('Module info:', moduleInfo);

      // Perform the API call
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.teacherContentLockUpdate(
          {
            teacherId: BigInt(teacher_id),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleInfo,
          }
        );

      // Check if the response is valid and handle accordingly
      if (response) {
        console.log('API response:', response);
        await getLessonLockedStatus(teacher_id); // Refresh lesson lock status
        handleClosePopup(); // Close the popup upon successful submission
        setLockedPopData(null); // Clear the lockedPopupData state
      } else {
        throw new Error('Unexpected API response');
      }
    } catch (error) {
      // Enhanced error handling
      console.error('Submission error:', error);

      // Display a user-friendly error message
      dispatch(
        setToastInfo({
          label: 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const getLessonLockedStatus = async (teacherId: string) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.moduleLockInfoFetch(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleId: Number(chapter_id),
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER,
          }
        );
      const data = response?.data;
      if (data) {
        console.log(data, 'bjkdjbs');
        setContentLockData(data);
        // dispatch(setContentLock(data));
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.topicContainer}>
        <Box sx={styles.chapterBox}>
          <ChapterSelectedCard
            image={findSelectedChapterData().image}
            chapterName={
              findSelectedChapterData().name
                ? findSelectedChapterData().name
                : ''
            }
            className={
              class_subject_info
                ? class_subject_info?.classname + class_subject_info?.section
                : ''
            }
            subject={class_subject_info ? class_subject_info?.subject : ''}
          />
        </Box>

        <Box sx={styles.chooseTopicBox}>
          <Box sx={styles.textWithBadge}>
            <Typography variant="h2">Choose a Topic</Typography>
            <Typography variant="h3">
              <ChipBadge
                label={subject_topic_info?.chapterTopics?.length}
                color="primary"
                size="small"
                sx={{ display: { xs: 'none', md: 'unset' } }}
              />
            </Typography>
          </Box>

          {topicLoading === 'loading' ? (
            <Loader />
          ) : topicLoading === 'error' ? (
            <NoContentCard variant="error" icon="error" text="Error Occured" />
          ) : subject_topic_info ? (
            <Box sx={styles.topicBox}>
              {subject_topic_info.chapterTopics?.length === 0 ? (
                <Box>
                  <NoContentCard
                    variant="white"
                    icon="coming-soon-yellow"
                    text="No topics to show"
                  />
                </Box>
              ) : (
                subject_topic_info?.chapterTopics?.map((topics, index) => {
                  return (
                    <TopicCard
                      key={index}
                      title={topics.topicTitle}
                      // title={`${findChapter()?.chapterNo}.${index + 1} ${topics.topicTitle
                      //   }`}
                      lessonPlanCount={topics.topicContentStats?.lessonCount}
                      questionsCount={topics.topicContentStats?.questionCount}
                      viewAllClickHandler={() => topicNavigation(topics)}
                      rootStyle={{
                        padding: {
                          xs: `${pxToRem(14)} ${pxToRem(5)} ${pxToRem(
                            14
                          )} ${pxToRem(20)}`,
                          md: `${pxTovW(14)} ${pxTovW(32)}`,
                        },
                      }}
                      isLocked={
                        isContentLockFeature &&
                        contentLockData &&
                        getContentLockedData(topics.topicId, contentLockData)
                          ?.lockStatus ===
                          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                          ? true
                          : false
                      }
                    >
                      {!fetchingState && (
                        <Box
                          sx={{
                            marginLeft: '5px',
                            display: 'flex',
                            // marginTop: '10px',
                            gap: { xs: pxToRem(5), md: pxTovW(5) },
                          }}
                        >
                          {isContentLockFeature && (
                            <LockToggleButton
                              isLocked={
                                contentLockData
                                  ? getContentLockedData(
                                      topics.topicId,
                                      contentLockData
                                    )?.lockStatus
                                  : false
                              }
                              handleOpenPopup={(ev) => {
                                handleOpenPopup(ev, topics);
                              }}
                            />
                          )}
                          <DownloadButtonWrapper
                            user={new TeacherLoginResponseType(user_info)}
                            downloadedSubject={
                              downloadedSubject
                                ? new DownloadedSubject(downloadedSubject)
                                : undefined
                            }
                            downloadedMetaData={downloadedMetaData}
                            setDownloadedMetaData={setDownloadedMetaData}
                            subjectId={Number(subject_id)}
                            classId={class_subject_info?.classId}
                            sectionId={class_subject_info?.sectionId}
                            chapterId={Number(chapter_id)}
                            topicId={topics.topicId}
                            title={`Topic - ${topics.topicTitle}`}
                            interactionEventUrl={
                              'Teacher_teach_topics_selection'
                            }
                            interactionEventContext={'topic'}
                          />
                        </Box>
                      )}
                    </TopicCard>
                  );
                })
              )}
            </Box>
          ) : (
            <NoContentCard
              variant="white"
              icon="coming-soon-yellow"
              text="No topics to show"
              rootStyle={{
                flexDirection: 'column',
                gap: { xs: pxToRem(20), md: pxTovW(20) },
              }}
            />
          )}
        </Box>
      </Box>

      {/* right panel */}
      <Box sx={styles.resourceContainer}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              ...styles.textWithBadge,
              paddingY: { xs: pxToRem(20), md: 0 },
            }}
          >
            <Typography variant="h2">Chapter Resources</Typography>
            <Typography variant="h3">
              <ChipBadge
                label={
                  chapter_resources?.categoryResourceMap
                    ?.map((obj) => obj.categoryResources.length)
                    .reduce((a, b) => a + b, 0) || 0
                }
                color="primary"
                size="small"
              />
            </Typography>
          </Box>

          {/* FEATURE_CONTENT_UPLOAD not there in Feature */}
          {useFeatureEnabled(
            user_info?.schoolDetails[0].featuresPlanInfo,
            Feature.CONTENT_UPLOAD
          ) && (
            <Button
              onClick={() => {
                dispatch(
                  setCustomResourceData({
                    ...custom_resource_data,
                    selected_chapter: {
                      chapter_name: findSelectedChapterData().name,
                      chapter_id: Number(chapter_id),
                    },
                  })
                );
                navigate(`${RESOURCE_UPLOAD}/${chapter_id}`);
              }}
              sx={{
                width: { xs: pxToRem(81), md: pxTovW(151) },
                height: { xs: pxToRem(26), md: pxTovW(48) },
                borderRadius: { xs: pxToRem(14), md: pxTovW(30) },
                marginRight: { xs: pxToRem(10) },
                display: 'flex',
                gap: { xs: pxToRem(5), md: pxTovW(6) },
                alignSelf: 'center',
                background: '#0AA34F',
                '&:hover': {
                  background: '#0AA34F',
                },
              }}
            >
              <IconWrapper
                name="upload"
                size="md"
                parentFolder="icons"
                type="png"
              />
              <Typography
                sx={{
                  fontFamily: 'poppins',
                  fontWeight: '600',
                  fontSize: { xs: pxToRem(11), md: pxTovW(21) },
                  color: 'common.white',
                }}
              >
                Upload
              </Typography>
            </Button>
          )}
        </Box>

        {resourceLoading === 'loading' ? (
          <ResourceShimmer />
        ) : resourceLoading === 'error' ? (
          <Box sx={{ p: { xs: pxToRem(20), md: pxTovW(40) } }}>
            <NoContentCard variant="error" icon="error" text="Error Occured" />
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            {chapter_resources &&
            chapter_resources.categoryResourceMap.length > 0 ? (
              <>
                {chapter_resources.categoryResourceMap.map(
                  (resource, resourceIndex) => (
                    <SectionListWithTopCarousel
                      key={resourceIndex}
                      title={resource.categoryTitle}
                      subtitle={resource.categoryDescription}
                      // itemsToShow={isIpadOnly ? 2 : 3}
                      itemsToShow={2}
                      // containerMdWidth={isIpadOnly ? pxTovW(1000) : pxTovW(855)}
                      containerMdWidth={pxTovW(1000)}
                      items={resource.categoryResources.map(
                        (category, categoryIndex) => (
                          <ContentDetailCard
                            isLocked={
                              isContentLockFeature &&
                              contentLockData &&
                              contentLockData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                                category.resourceId
                              )
                            }
                            locked={
                              isContentLockFeature && (
                                <LockToggleButton
                                  isLocked={
                                    contentLockData &&
                                    contentLockData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                                      category.resourceId
                                    )
                                  }
                                  handleOpenPopup={(ev) => {
                                    handleOpenPopup(ev, undefined, category);
                                  }}
                                />
                              )
                            }
                            onClick={async () => {
                              await interactionEvent({
                                url: 'Teacher_teach_topic_selection',
                                context: 'Chapter_resource',
                                name: 'RESOURCE_OPEN',
                              });

                              onResourceClick(
                                navigate,
                                {
                                  resourceId: category.resourceId,
                                  subjectId: Number(subject_id),
                                  chapterId: Number(chapter_id),
                                },
                                SessionModeEnum.SESSION_MODE_RESOURCE
                              );
                            }}
                            key={categoryIndex}
                            // variant={isIpadOnly ? 'large' : 'small'}
                            variant={'large'}
                            image={getMediaBasePath(
                              category.posterImageUrl,
                              category.resourceCategoryType ===
                                ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                                ? 'userDataBucket'
                                : 'processedMediaBucket'
                            )}
                            heading={category.title}
                            iconDetails={iconDetails(category)}
                            showVideoIcon={
                              category.resourceType ===
                              Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                            }
                            editResourceFunction={
                              category.resourceType ===
                                Resource_ResourceEnum.RESOURCE_TYPE_TEACHER_UPLOAD &&
                              category.createdBy === user_info?.teacherProfileId
                                ? {
                                    editResource: () => {
                                      editResource({
                                        resourceId: category.resourceId,
                                        coverTitle: category.title,
                                      });
                                    },
                                    deleteResource: () => {
                                      setSelectedDataForDelete({
                                        resourceId: category.resourceId,
                                        resourceTitle: category.title,
                                      });
                                      setConfirmDeletePopup(true);
                                    },
                                  }
                                : undefined
                            }
                          />
                        )
                      )}
                    />
                  )
                )}
              </>
            ) : (
              <NoContentCard
                variant="soon"
                icon="hourglass-web"
                text="Coming Soon!"
                rootStyle={{
                  height: { xs: pxToRem(150), md: pxTovW(212) },
                  boxSizing: 'border-box',
                  mt: { xs: pxToRem(20), md: pxTovW(40) },
                }}
              />
            )}
          </Box>
        )}
      </Box>

      <ActionsPopup
        open={confirmDeletePopup}
        handleClose={() => {
          setConfirmDeletePopup(false);
        }}
        fontSmall
        iconName="delete"
        popupText={`Are you sure you want to delete - ${selectedDataForDelete?.resourceTitle}?`}
        yesClickHandler={async () => {
          deleteResource();
          setConfirmDeletePopup(false);
        }}
        noClickHandler={async () => {
          setConfirmDeletePopup(false);
        }}
      />
      {isContentLockFeature && lockedPopupData && (
        <ContentLockPopup
          open={isContentLockPopupOpen}
          onClose={handleClosePopup}
          onSubmit={(ev?: React.MouseEvent) =>
            lockedPopupData && handleSubmit(ev)
          }
          selectedValue={lockedPopupData.lockedStatus}
          onRadioChange={handleLockedRadioChange}
          lockDataObj={lockedPopupData}
        />
      )}
    </Box>
  );
}

const iconDetails = (resource: ResourceInfo) => {
  const retValue = [
    {
      iconName: 'clock',
      text: `${resource.estimatedTimeInMin} Min`,
    },

    {
      iconName: resourceTypeName(resource.resourceType).icon,
      text: resourceTypeName(resource.resourceType).name,
    },
  ];

  return retValue;
};

const getContentLockedData = (
  topic_id: number,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.children.find(
    (val) => val.moduleId === topic_id
  );
};

const getCategoryLockedStatus = (
  resourceId: string,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
    resourceId
  )
    ? ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    : ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED;
};
