import { useEffect, useRef, useState } from 'react';

import { Timestamp } from '@bufbuild/protobuf';
import {
  ConnectedClassIndicator,
  ContentIdAttempt,
  HeadingCard,
  IStyles,
  IconWrapper,
  ImageWrapper,
  InPageHeader,
  LinkButton,
  Loader,
  NoContentCard,
  PaginationButton,
  ResourcePageContent,
  SecondaryButton,
  deepClone,
  deserify,
  getMediaBasePath,
  getTeacherSubjectEnum,
  pxToRem,
  pxTovW,
  resourceTypeName,
  shouldIncludeCredentialsInRequest,
  theme,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import {
  Content,
  ContentApprovalStatusEnum,
  McqMultipleContentModel,
  McqSingleContentModel,
  Module_ModuleCategoryEnum,
  PageContentInfo,
  Question,
  ResourceCategoryEnum,
  ResourceContent,
  ResourceTeacherInstruction,
  Resource_ResourceEnum,
  TfContentModel,
} from '@protos/content_management/content.db_pb';
import { UpdateTeacherResourceSessionRequest } from '@protos/learning_management/lms.common.apis_pb';
import {
  ContentAttempt,
  SessionModeEnum,
  SessionStatusEnum,
  TeacherContentResponse,
  TeacherLessonSessionMetrics,
  VisitedResourceContentInfoList,
} from '@protos/learning_management/lms.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
// import contentImage from '../../../../../../libs/shared-ui/src/assets/tempAssets/lessonImage1.png';
import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import { UpdateTeacherLessonSessionRequest } from '@protos/learning_management/lms.lesson.teach.apis_pb';
import { useDispatch } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  EDIT_LESSON_PLAN,
  HOME,
  LESSON_PLAN_LISTS,
  TEACHING_FLOW,
  TEACH_TOPIC_SELECTION,
} from '../../../routeHandling/RoutesNomenclature';
import { findClassSubjectInfoFromClassSection } from '../../../utils/functions';
import { ResourceViewMode, onResourceClick } from '../../../utils/resource';
import { interactionEvent } from '../../Auth/auth.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import {
  setLessonContent,
  setResourceRedirectionPath,
  setSelectedResourceContent,
  setSelectedResourceSessionId,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../reducer/teach.slice';
import { LessonListPopup } from './components/LessonListPopup';

import { useConnectedClassContext } from '../../../app/Context/ConnectedClassContextProvider';
import { Steps } from '../../ConnectedClassRoom/StartClassroom/components/Steps';
import {
  IQuestionSession,
  setConnectedClassQuestionSessionDetails,
} from '../reducer/connectedClass.slice';
import { LeftInfoPanel } from './components/LeftPanel';
import { Feature } from '@protos/school_management/school.db_pb';
import useFetchLockedResourceIds, {
  IModuleLockedInfoParams,
} from './resourceLockFetch';
import { ConnectedClassHeader } from './components/ConnectedClassHeader';

const styles: IStyles = {
  paginationButtonBox: {
    position: 'fixed',
    bottom: { md: pxTovW(30), xs: pxToRem(10) },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  iconTextBoxContainer: {
    width: '100%',
    display: 'flex',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    alignItems: 'center',
    mt: { xs: pxToRem(10), md: pxTovW(10) },
  },
  headingImage: {
    width: { xs: pxToRem(60), md: pxToRem(105) },
    height: { xs: pxToRem(60), md: pxToRem(105) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    objectFit: 'cover',
  },
  resourcePage: {
    paddingBottom: {
      xs: pxToRem(75),
      md: pxTovW(110),
    },
  },
  root: {
    boxSizing: 'border-box',
    marginTop: pxToRem(8),
    marginRight: { md: pxToRem(20), lg: pxTovW(240) },
    marginLeft: { md: pxToRem(20), lg: pxTovW(240) },
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)} `,
      md: 0,
    },
    position: 'relative',
  },
};
interface IExitQuestionInfo {
  status: boolean;
  lastExitid: string | undefined;
}
interface IProps {
  headerButtonText?: string;
  buttonClickHandler?: () => void;
}
export const ResourcePage = (props: IProps) => {
  const navigate = useNavigate();
  const [lessonListPopupState, setLessonListPopupState] = useState(false);
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [showResponse, setShowResponse] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [ViewResponses, setViewResponses] = useState(false);
  const [waitTillQuestionExit, setWaitTillQuestionExit] =
    useState<IExitQuestionInfo>({
      status: false,
      lastExitid: undefined,
    });
  const user_info = deserify(useAppSelector((state) => state.auth.user_info));

  const teacher_id = user_info?.teacherProfileId;
  const selectedResourceContent = deserify(
    useAppSelector((state) => state.teach.selected_resource_content)
  )?.parsedData;
  const rawResourceContent = deserify(
    useAppSelector((state) => state.teach.selected_resource_content)
  )?.rawData;
  const activeResourceSessionId = deserify(
    useAppSelector((state) => state.teach.selected_resource_session_id)
  );
  const { selected_lessons_info } = deserify(
    useAppSelector((state) => state.teach)
  );
  const { base_station_details, question_session_details } = deserify(
    useAppSelector((state) => state.connectedClass)
  );
  const {
    baseStationData,
    upsertConnectedClassQuestionSessionApi,
    unsubscribeQuestionFromFirestore,
    upsertConnectedClassResourceSessionApi,
  } = useConnectedClassContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonTeachAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const [completedResourceIds, setCompletedResourceIds] = useState<string[]>(
    []
  );

  const dispatch = useDispatch();

  const location = useLocation();
  const { approvalStatus } = location.state || {};

  const connectedClassResourceSessionIdFromParams =
    new URLSearchParams(location.search).get(
      'connectedClassResourceSessionId'
    ) || undefined;
  const connectedClass =
    connectedClassResourceSessionIdFromParams &&
    base_station_details?.baseReceiverSerialNo
      ? true
      : false;
  const subjectId =
    new URLSearchParams(location.search).get('subjectId') || undefined;
  const resourceId =
    new URLSearchParams(location.search).get('resourceId') || undefined;
  const lessonId =
    new URLSearchParams(location.search).get('lessonId') || undefined;
  const lessonSessionId =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;

  const session_mode_from_param =
    new URLSearchParams(location.search).get('sessionMode') || undefined;
  const sessionModeFromParam = session_mode_from_param
    ? (Number(session_mode_from_param) as SessionModeEnum)
    : undefined;
  const chapterId =
    new URLSearchParams(location.search).get('chapterId') || undefined;
  const topicId =
    new URLSearchParams(location.search).get('topicId') || undefined;

  const class_id =
    new URLSearchParams(location.search).get('classId') || undefined;
  const section = new URLSearchParams(location.search).get('section');
  const sectionId = new URLSearchParams(location.search).get('sectionId');

  const resource_index =
    new URLSearchParams(location.search).get('resourceIndex') || undefined;
  const resourceIndex = resource_index ? Number(resource_index) : undefined;

  const class_subject_info =
    deserify(
      useAppSelector((state) => state.homeDashboard.class_subject_info)
    ) ||
    findClassSubjectInfoFromClassSection({
      userInfo: user_info,
      classId: Number(class_id),
      sectionId: Number(sectionId),
      subjectId: Number(subjectId),
    });

  const isContentLockFeature = useFeatureEnabled(
    user_info?.schoolDetails[0].featuresPlanInfo,
    Feature.CONTENT_LOCK
  );
  const paramObj: IModuleLockedInfoParams = {
    subjectId: Number(subjectId),
    teacherProfileId: teacher_id,
    category: topicId
      ? Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC
      : Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER,
    moduleId: topicId ? Number(topicId) : Number(chapterId),
    schoolClassSectionId: class_subject_info?.sectionId,
    lessonId: lessonId,
    isFeatureAvailable: isContentLockFeature,
  };
  const {
    lockedResourceIds,
    loading: lockedLoading,
    error: lockedError,
  } = useFetchLockedResourceIds(paramObj);

  const resourceRedirectionPath = useAppSelector(
    (state) => state.teach.resource_redirection_path
  );

  const lesson_session_visited_resource_info =
    deserify(
      useAppSelector(
        (state) => state.teach.lesson_session_visited_resource_info
      )
    ) || {};
  const visitedResourceInfo =
    (lessonSessionId
      ? lesson_session_visited_resource_info[lessonSessionId]
      : []) || [];

  const { lessons_content } = deserify(useAppSelector((state) => state.teach));

  const viewMode =
    (new URLSearchParams(location.search).get(
      'viewMode'
    ) as ResourceViewMode) || undefined;

  const pageContents = (
    new ResourceContent(selectedResourceContent)?.resourceContentData
      ?.pageContent || []
  ).sort((a, b) => a.pageNumber - b.pageNumber);
  const [pageNumber, setPageNumber] = useState(0);
  const [contentIdAttempt, updateContentIdAttempt] = useState<ContentIdAttempt>(
    {}
  );
  const [
    tempUnsubscribeQuestionSessionApiCall,
    setTempUnsubscribeQuestionSessionApiCall,
  ] = useState<(() => void) | undefined>(undefined);
  //to resolve the issue of getting correct question type first time
  const [firstPageContent, setFirstPageContent] = useState<
    PageContentInfo | undefined
  >();
  const [instructionModal, setInstructionModal] = useState(false);
  const pageContent = pageContents[pageNumber];
  const [startTime, setStartTime] = useState(Timestamp.fromDate(new Date()));
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'loading'
  );
  const { setSelectedFunction, setSelectedLogoClickHandler } =
    useGlobalContext();
  const [createLoader, setCreateLoader] = useState(false);
  const initializedRef = useRef(false);

  useEffect(() => {
    fetchResourceData();
    updateContentIdAttempt({});
  }, [resourceId]);

  useEffect(() => {
    if (initializedRef.current) {
      const unsubscribeHandler = async (navigateHome: boolean) => {
        await handleResourceCompleteOrEnd(
          SessionStatusEnum.SESSION_STATUS_EXITED
        );
        const unsubscribeCall =
          tempUnsubscribeQuestionSessionApiCall ||
          unsubscribeQuestionSessionApiCall;
        await unsubscribeCall();
        if (navigateHome) {
          navigate(HOME);
        }
      };

      setSelectedFunction(() => unsubscribeHandler);
      setSelectedLogoClickHandler(() => () => unsubscribeHandler(true));

      return () => {
        setSelectedFunction(null);
        setSelectedLogoClickHandler(null);
      };
    } else {
      initializedRef.current = true;
    }
  }, [
    activeResourceSessionId,
    resourceId,
    contentIdAttempt,
    pageNumber,
    firstPageContent,
    tempUnsubscribeQuestionSessionApiCall,
    completedResourceIds,
  ]);

  useEffect(() => {
    const started = async () => {
      if (
        connectedClassResourceSessionIdFromParams &&
        connectedClass &&
        firstPageContent &&
        getQuestionCategoryTypetoStartSession(pageContent || firstPageContent)
      ) {
        try {
          const questionSessionData =
            await upsertConnectedClassQuestionSessionApi({
              connectedClassResourceSessionId: BigInt(
                connectedClassResourceSessionIdFromParams
              ),
              questionId:
                pageContent.questionIds[0] || firstPageContent.questionIds[0],
              sequence: pageContent.pageNumber || firstPageContent.pageNumber,
              startTime: Timestamp.fromDate(new Date()),
              sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
            });
          if (questionSessionData) {
            const quesSessionId =
              questionSessionData.connectedClassQuestionSessionId;
            setTempUnsubscribeQuestionSessionApiCall(() => () => {
              unsubscribeQuestionSessionApiCall({
                connected_class_question_session_id: quesSessionId,
                question_id: pageContent.questionIds[0],
              });
            });
            dispatch(
              setConnectedClassQuestionSessionDetails({
                connected_class_question_session_id: quesSessionId,
                question_id: pageContent.questionIds[0],
              })
            );
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
    if (!waitTillQuestionExit.status && firstPageContent) {
      const qId = pageContent.questionIds[0] || firstPageContent.questionIds[0];
      if (qId !== waitTillQuestionExit.lastExitid) {
        started();
      }
    }
    // setTimeout(() => started(), 5000);
    console.log('pageContent', pageContent);
    setStartTime(Timestamp.fromDate(new Date()));
  }, [
    pageNumber,
    resourceId,
    firstPageContent,
    connectedClass,
    waitTillQuestionExit,
  ]);

  const fetchResourceData = async () => {
    setPageNumber(0);
    if (resourceId) {
      try {
        setLoading('loading');
        const resourceContent =
          await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchResourceContent(
            {
              personId: teacher_id,
              personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
              resourceId: resourceId,
            }
          );
        const gcpJsonURL = resourceContent.data?.gcpJsonUrl;
        if (!gcpJsonURL) {
          throw new Error('Invalid Resource Data');
          return;
        }
        const modifiedUrl = getMediaBasePath(
          gcpJsonURL,
          resourceContent.data?.resourceCategory ===
            ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
            ? 'userDataBucket'
            : 'resourceContentBucket'
        );
        const options: RequestInit = {};
        if (shouldIncludeCredentialsInRequest(modifiedUrl)) {
          options.credentials = 'include';
        }
        const gcpResponse = await (await fetch(modifiedUrl, options)).json();
        const resContent = new ResourceContent().fromJson(gcpResponse, {
          ignoreUnknownFields: true,
        });
        const firstPageContent = (
          new ResourceContent(resContent)?.resourceContentData?.pageContent ||
          []
        ).sort((a, b) => a.pageNumber - b.pageNumber)[0];
        setFirstPageContent(firstPageContent);
        dispatch(
          setSelectedResourceContent({
            rawData: resourceContent.data,
            parsedData: resContent,
          })
        );
        if (viewMode) {
          setLoading('completed');
          return;
        }
        const resourceSession =
          await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.createTeacherResourceSession(
            {
              teacherId: teacher_id,
              sessionMode: sessionModeFromParam,
              startTime: Timestamp.fromDate(new Date()),
              resourceId: resourceId,
              schoolId: user_info?.schoolDetails[0]?.schoolId,
              academicYear: 0,
              classId: class_subject_info?.classId,
              section: class_subject_info?.section,
              subject:
                subjectId && !isNaN(Number(subjectId))
                  ? getTeacherSubjectEnum(
                      Number(subjectId),
                      user_info?.teachClassSubjects
                    )
                  : undefined,
              moduleId: topicId
                ? Number(topicId)
                : chapterId
                ? Number(chapterId)
                : undefined,
              teacherLessonSessionId:
                lessonSessionId && !isNaN(Number(lessonSessionId))
                  ? Number(lessonSessionId)
                  : undefined,
              sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
            }
          );
        if (lessonId) {
          const prevLessonSessionInfo =
            await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
              {
                teacherId: user_info?.teacherProfileId,
                lessonId: lessonId,
              }
            );
          const resIds = prevLessonSessionInfo.data?.completedResourceIds;
          if (resIds && resIds.length > 0) {
            setCompletedResourceIds(resIds);
          }
        }
        dispatch(
          setSelectedResourceSessionId(resourceSession.teacherResourceSessionId)
        );
        await interactionEvent({
          url: 'Teacher_resource_page',
          context: 'teach',
          name: 'RESOURCE_OPENED',
          lessonSessionId: lessonSessionId,
          resourceSessionId:
            resourceSession.teacherResourceSessionId?.toString(),
        });
        setLoading('completed');
      } catch (err) {
        setLoading('error');
        dispatch(setSelectedResourceContent(undefined));
        dispatch(setSelectedResourceSessionId(undefined));
        console.error(err);
      }
    }
  };

  const handleAnswerSubmit = async (contentId: string, answer: string[]) => {
    const contentIdAttemptCopy = deepClone(contentIdAttempt);
    if (!contentIdAttemptCopy[contentId]) {
      contentIdAttemptCopy[contentId] = new TeacherContentResponse({
        contentId: contentId,
        responses: [],
      });
    }
    contentIdAttemptCopy[contentId].responses.push(
      new ContentAttempt({
        startTime: startTime,
        endTime: Timestamp.fromDate(new Date()),
        answer,
      })
    );
    updateContentIdAttempt(contentIdAttemptCopy);
    const isLastQuestion = pageContent.pageNumber === pageContents.length;
    if (question_session_details) {
      unsubscribeQuestionSessionApiCall(question_session_details);
      if (lessonId && lessonSessionId && !isLastQuestion) {
        setPageNumber(pageNumber + 1);
      } else {
        handleResourceCompleteOrEnd(SessionStatusEnum.SESSION_STATUS_COMPLETED);
      }
    }
  };

  const unsubscribeQuestionSessionApiCall = async (
    questionSessionDetails?: IQuestionSession | undefined
  ) => {
    if (
      connectedClassResourceSessionIdFromParams &&
      connectedClass &&
      questionSessionDetails &&
      getQuestionCategoryTypetoStartSession(pageContent || firstPageContent)
    ) {
      setWaitTillQuestionExit((prev) => ({
        status: true,
        lastExitid: prev.lastExitid,
      }));
      try {
        const questionSessionData =
          await upsertConnectedClassQuestionSessionApi({
            connectedClassResourceSessionId: BigInt(
              connectedClassResourceSessionIdFromParams
            ),
            questionId:
              pageContent.questionIds[0] || firstPageContent?.questionIds[0],
            sequence: pageContent.pageNumber || firstPageContent?.pageNumber,
            endTime: Timestamp.fromDate(new Date()),
            sessionStatus: SessionStatusEnum.SESSION_STATUS_EXITED,
            connectedClassQuestionSessionId:
              questionSessionDetails.connected_class_question_session_id,
          });
        if (questionSessionData) {
          const quesSessionId =
            questionSessionData.connectedClassQuestionSessionId;
          unsubscribeQuestionFromFirestore();
          dispatch(setConnectedClassQuestionSessionDetails(undefined));
        }
        // const isLastQuestion = pageContent.pageNumber === pageContents.length;
        // if (isLastQuestion || endResourceSession) {
        //   if (baseStationData) {
        //     const res = await upsertConnectedClassResourceSessionApi({
        //       connectedClassSessionId: BigInt(baseStationData?.sessionId),
        //       resourceId: resourceId,
        //       sessionStartTime: Timestamp.fromDate(new Date()),
        //       sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
        //     });
        //   }
        // }
        setWaitTillQuestionExit({
          status: false,
          lastExitid:
            pageContent.questionIds[0] || firstPageContent?.questionIds[0],
        });
      } catch (error) {
        console.error('Error:', error);
        setWaitTillQuestionExit((prev) => ({
          status: false,
          lastExitid: prev.lastExitid,
        }));
      }
    }
  };
  const getUpdatedContentAttempt = () => {
    const contentIdAttemptCopy = deepClone(contentIdAttempt);
    pageContent?.contents.forEach((con) => {
      const contentValue = con.model.value;
      const contentId =
        contentValue instanceof Content
          ? contentValue.contentId
          : contentValue instanceof Question
          ? contentValue.questionId
          : contentValue instanceof ResourceTeacherInstruction
          ? contentValue.resourceTeacherInstructionId
          : undefined;
      if (contentId) {
        if (!contentIdAttemptCopy[contentId]) {
          contentIdAttemptCopy[contentId] = new TeacherContentResponse({
            contentId: contentId,
          });
        }
        if (
          !(contentValue instanceof Question) ||
          contentIdAttemptCopy[contentId].responses.length === 0
        ) {
          contentIdAttemptCopy[contentId].responses.push(
            new ContentAttempt({
              startTime: startTime,
              endTime: Timestamp.fromDate(new Date()),
              answer: undefined,
            })
          );
        }
      }
    });
    return contentIdAttemptCopy;
  };

  const makeResponseObject = (
    sessionStatus: SessionStatusEnum
  ): UpdateTeacherResourceSessionRequest => {
    const contentIdAttemptCopy = getUpdatedContentAttempt();
    updateContentIdAttempt(contentIdAttemptCopy);
    return new UpdateTeacherResourceSessionRequest({
      teacherResourceSessionId: activeResourceSessionId,
      teacherId: teacher_id,
      resourceId,
      sessionStatus: sessionStatus,
      endTime:
        sessionStatus === SessionStatusEnum.SESSION_STATUS_EXITED ||
        sessionStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
          ? Timestamp.fromDate(new Date())
          : undefined,
      response: Object.values(contentIdAttemptCopy),
    });
  };

  function getUpdatedVisitedResourceInfo(
    resourceId: string,
    pageNo: number,
    pageContentIds: string[]
  ) {
    const existingIndex = visitedResourceInfo.findIndex(
      (obj) => obj.resourceId === resourceId && obj.pageNo === pageNo
    );

    if (existingIndex > -1) {
      // If the object with the resourceId and pageNo exists, create a new object with the updated pageContentIds
      const updatedObject = new VisitedResourceContentInfoList({
        ...visitedResourceInfo[existingIndex],
        pageContentIds: Array.from(
          new Set([
            ...visitedResourceInfo[existingIndex].pageContentIds,
            ...pageContentIds,
          ])
        ),
      });
      // Return a new array with the updated object
      return [
        ...visitedResourceInfo.slice(0, existingIndex),
        updatedObject,
        ...visitedResourceInfo.slice(existingIndex + 1),
      ];
    } else {
      // If the object does not exist, return a new array with the new object added
      return [
        ...visitedResourceInfo,
        new VisitedResourceContentInfoList({
          resourceId,
          pageNo,
          pageContentIds,
        }),
      ];
    }
  }

  const makeLessonSessionObject = (
    resourceStatus: SessionStatusEnum
  ): UpdateTeacherLessonSessionRequest => {
    const resourceIdsCompletedCopy = completedResourceIds.slice();
    let lessonStatus = resourceStatus;
    // console.log("resourceStatus", resourceStatus)
    if (
      resourceStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED &&
      resourceId
    ) {
      if (!resourceIdsCompletedCopy.includes(resourceId)) {
        resourceIdsCompletedCopy.push(resourceId);
        setCompletedResourceIds(resourceIdsCompletedCopy);
      }
      const resourceIdsOfLesson =
        lessons_content?.resources.map((val) => val.resourceId) || [];
      const notCompletedResources = resourceIdsOfLesson.filter(
        (val) => !resourceIdsCompletedCopy.includes(val)
      );
      lessonStatus =
        notCompletedResources.length > 0 &&
        resourceStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
          ? SessionStatusEnum.SESSION_STATUS_ONGOING
          : lessonStatus;
    }
    const pageNo = pageContent.pageNumber;
    const conIds = pageContent.contents.map((con) => {
      const contentValue = con.model.value;
      const contentId =
        contentValue instanceof Content
          ? contentValue.contentId
          : contentValue instanceof Question
          ? contentValue.questionId
          : contentValue instanceof ResourceTeacherInstruction
          ? contentValue.resourceTeacherInstructionId
          : undefined;
      return contentId || '';
    });
    const updatedResourceInfo = resourceId
      ? getUpdatedVisitedResourceInfo(resourceId, pageNo, conIds)
      : visitedResourceInfo;
    if (lessonSessionId) {
      dispatch(
        setUpdatedLessonSessionVisitedResourceInfo({
          ...lesson_session_visited_resource_info,
          [lessonSessionId]: updatedResourceInfo,
        })
      );
    }
    return new UpdateTeacherLessonSessionRequest({
      teacherId: teacher_id,
      teacherLessonSessionId: Number(lessonSessionId),
      lessonId,
      sessionStatus: lessonStatus,
      endTime:
        lessonStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
          ? Timestamp.fromDate(new Date())
          : undefined,
      metrics: new TeacherLessonSessionMetrics({
        teacherLessonSessionId: Number(lessonSessionId),
        isComplete: lessonStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED,
        completedResourceIds: resourceIdsCompletedCopy,
        maxScore: 0,
        responseScore: 0,
        visitedResourceContentInfoList: updatedResourceInfo,
      }),
    });
  };

  const handleResourceCompleteOrEnd = async (
    sessionStatus: SessionStatusEnum
  ) => {
    if (viewMode) {
      navigate(-1);
      return;
    }
    const resp =
      await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.updateTeacherResourceSession(
        makeResponseObject(sessionStatus)
      );
    if (lessonId && lessonSessionId && topicId && resourceId) {
      const resourceIds =
        lessons_content?.resources.map((val) => val.resourceId) || [];
      const currentIndex = resourceIds.indexOf(resourceId);
      const nextResourceId =
        currentIndex >= 0 ? resourceIds[currentIndex + 1] : undefined;
      const isOngoingLessonSession =
        nextResourceId &&
        sessionStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED;
      const resp2 =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.updateTeacherLessonSession(
          makeLessonSessionObject(sessionStatus)
        );
      // console.log(
      //   'nextResourceId',
      //   nextResourceId,
      //   lessons_content?.resources.find(
      //     (val) => val.resourceId === nextResourceId
      //   )
      // );
      const nextResourceCategoryType = lessons_content?.resources.find(
        (val) => val.resourceId === nextResourceId
      )?.resourceCategoryType;
      let connectedClassResourceSessionId: bigint | undefined;
      if (baseStationData?.sessionId) {
        if (connectedClassResourceSessionIdFromParams) {
          const closeConnectedResSession =
            await upsertConnectedClassResourceSessionApi({
              connectedClassSessionId: BigInt(baseStationData?.sessionId),
              resourceId: resourceId,
              sessionStartTime: Timestamp.fromDate(new Date()),
              connectedClassResourceSessionId: BigInt(
                connectedClassResourceSessionIdFromParams
              ),
              sessionStatus: sessionStatus,
            });
        }
        if (
          nextResourceCategoryType ===
            ResourceCategoryEnum.RESOURCE_CATEGORY_PRACTICE &&
          sessionStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
        ) {
          const startNextConnectedResSession =
            await upsertConnectedClassResourceSessionApi({
              connectedClassSessionId: BigInt(baseStationData?.sessionId),
              resourceId: nextResourceId,
              sessionStartTime: Timestamp.fromDate(new Date()),
              sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
            });
          if (startNextConnectedResSession === undefined) {
            dispatch(
              setToastInfo({
                label: 'upsertConnectedClassResourceSessionApi err',
                variant: 'error',
                open: true,
              })
            );
            return;
          }
          connectedClassResourceSessionId =
            startNextConnectedResSession?.connectedClassResourceSessionId;
        }
      }
      setFirstPageContent(undefined);
      if (isOngoingLessonSession) {
        onResourceClick(
          navigate,
          {
            resourceId: nextResourceId,
            lessonId: lessonId,
            lessonSessionId: Number(lessonSessionId),
            subjectId: Number(subjectId),
            topicId: Number(topicId),
            chapterId: Number(chapterId),
            classId: class_subject_info?.classId,
            section: class_subject_info?.section,
            connectedClassResourceSessionId:
              connectedClassResourceSessionId?.toString(),
            sectionId: class_subject_info?.sectionId,
          },
          SessionModeEnum.SESSION_MODE_LESSON_RESOURCE
        );
      } else {
        dispatch(setSelectedResourceSessionId(undefined));
        let redirectURL = `${TEACHING_FLOW}/${subjectId}/${chapterId}/${topicId}/${lessonId}?lessonSessionId=${lessonSessionId}`;
        if (class_subject_info?.classId && section) {
          redirectURL =
            redirectURL +
            `&classId=${class_subject_info?.classId}&section=${section}&sectionId=${sectionId}`;
        }
        navigate(redirectURL);
      }
      return;
    } else if (resourceRedirectionPath) {
      navigate(resourceRedirectionPath);
      dispatch(setResourceRedirectionPath(undefined));
    } else if (topicId && chapterId) {
      navigate(`${LESSON_PLAN_LISTS}/${subjectId}/${chapterId}/${topicId}`);
    } else if (chapterId) {
      navigate(`${TEACH_TOPIC_SELECTION}/${subjectId}/${chapterId}`);
    } else {
      navigate(HOME);
    }
  };

  const onNextClick = async () => {
    await interactionEvent({
      url: 'Teacher_resource_page',
      context: 'Teach',
      name: 'NEXT',
      lessonSessionId: lessonSessionId,
      resourceSessionId: activeResourceSessionId?.toString(),
    });
    if (pageContents[pageNumber + 1]) {
      if (viewMode) {
        const contentIdAttemptCopy = getUpdatedContentAttempt();
        updateContentIdAttempt(contentIdAttemptCopy);
        setPageNumber(pageNumber + 1);
        return;
      }
      const resp =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.updateTeacherResourceSession(
          makeResponseObject(SessionStatusEnum.SESSION_STATUS_ONGOING)
        );
      if (lessonId && lessonSessionId) {
        const resp2 =
          await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.updateTeacherLessonSession(
            makeLessonSessionObject(SessionStatusEnum.SESSION_STATUS_ONGOING)
          );
      }
      if (question_session_details) {
        unsubscribeQuestionSessionApiCall(question_session_details);
      }
      setPageNumber(pageNumber + 1);
    } else {
      if (question_session_details) {
        unsubscribeQuestionSessionApiCall(question_session_details);
      }
      handleResourceCompleteOrEnd(SessionStatusEnum.SESSION_STATUS_COMPLETED);
    }
  };

  const onPreviousClick = async () => {
    await interactionEvent({
      url: 'Teacher_resource_page',
      context: 'Teach',
      name: 'PREV',
      lessonSessionId: lessonSessionId,
      resourceSessionId: activeResourceSessionId?.toString(),
    });

    if (pageNumber > 0 && pageContents[pageNumber - 1]) {
      setPageNumber(pageNumber - 1);
    }
  };

  const chooseHandler = () => {
    if (
      !lessons_content ||
      typeof resourceIndex !== 'number' ||
      !rawResourceContent
    ) {
      setLoading('error');
      return;
    }
    const searchParams: Record<string, string> = {};
    if (class_id) searchParams['classId'] = class_id;
    if (sectionId) searchParams['sectionId'] = sectionId;
    if (section) searchParams['section'] = section;
    const lessonContentCopy = deepClone(lessons_content);
    const tempResources = lessonContentCopy?.resources.slice();
    if (viewMode === 'add') {
      tempResources.splice(
        resourceIndex,
        0,
        new ResourceInfo({
          resourceId: rawResourceContent.resourceId,
          title: rawResourceContent?.title,
          posterImageUrl: rawResourceContent.posterImageUrl,
          estimatedTimeInMin: rawResourceContent.estimatedTimeInMin,
          resourceType: rawResourceContent.resourceType,
          resourceCategoryType: rawResourceContent.resourceCategory,
        })
      );
      // rank fix
      tempResources.forEach((content, index) => {
        tempResources[index].rank = index + 1;
      });
      lessonContentCopy.resources = tempResources;

      dispatch(setLessonContent(lessonContentCopy));

      dispatch(
        setToastInfo({
          variant: 'info',
          label: `New Resource added - ${rawResourceContent?.title}`,
          open: true,
        })
      );
      navigate({
        pathname: `${EDIT_LESSON_PLAN}/${subjectId}/${chapterId}/${topicId}/${lessonId}`,
        search: Object.keys(searchParams).length
          ? `?${createSearchParams(searchParams)}`
          : undefined,
      });
    }
    if (viewMode === 'replace') {
      tempResources[resourceIndex] = new ResourceInfo({
        resourceId: rawResourceContent.resourceId,
        title: rawResourceContent?.title,
        posterImageUrl: rawResourceContent.posterImageUrl,
        estimatedTimeInMin: rawResourceContent.estimatedTimeInMin,
        resourceType: rawResourceContent.resourceType,
        resourceCategoryType: rawResourceContent.resourceCategory,
      });
      // rank fix
      tempResources.forEach((content, index) => {
        tempResources[index].rank = index + 1;
      });
      lessonContentCopy.resources = tempResources;
      dispatch(setLessonContent(lessonContentCopy));
      navigate({
        pathname: `${EDIT_LESSON_PLAN}/${subjectId}/${chapterId}/${topicId}/${lessonId}`,
        search: Object.keys(searchParams).length
          ? `?${createSearchParams(searchParams)}`
          : undefined,
      });
    }
  };

  const headerButtonHandler = () => {
    // console.log('headerButtonHandler');
    setLessonListPopupState(true);
  };

  const renderHeading = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginX: 'auto',
        }}
      >
        {approvalStatus ===
        ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_PENDING ? (
          <Box
            sx={{
              backgroundColor: '#FFFAE7',
              padding: pxToRem(10),
              textAlign: 'center',
              fontWeight: 'bold',
              borderRadius: pxToRem(5),
              marginBottom: pxToRem(10),
              top: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography variant="elementBodyText" color="text.primary">
              Your resource upload request is currently being reviewed by the
              admin
            </Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            // width: '100%',
            justifyContent: 'space-between',
            flexGrow: 1,
            alignItems: 'center',
            padding: { xs: pxToRem(15), lg: pxTovW(0) },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: { xs: pxToRem(10), md: pxTovW(20) },
            }}
          >
            <ImageWrapper
              name="chapterImage"
              type="png"
              styles={styles.headingImage}
              parentFolder="tempAssets"
              path={getMediaBasePath(
                rawResourceContent?.posterImageUrl,
                rawResourceContent?.resourceCategory ===
                  ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                  ? 'userDataBucket'
                  : 'processedMediaBucket'
              )}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                gap: pxToRem(10),
              }}
            >
              <Typography variant="elementH1">
                {rawResourceContent?.title}
              </Typography>

              <Box sx={styles.iconTextBoxContainer}>
                <RenderCdcIcons
                  estimatedTimeInMin={rawResourceContent?.estimatedTimeInMin}
                  resourceType={rawResourceContent?.resourceType}
                />
              </Box>
            </Box>
          </Box>

          {approvalStatus ===
            ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_APPROVED ||
          isNaN(approvalStatus) ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row-reverse' },
                gap: { md: pxTovW(5) },
                alignItems: { xs: 'center', md: 'flex-start' },
              }}
            >
              <Box>
                {isContentLockFeature && (
                  <ImageWrapper
                    name={
                      resourceId && lockedResourceIds?.includes(resourceId)
                        ? 'lock'
                        : 'unlock'
                    }
                    type="png"
                    parentFolder="icons"
                    styles={{
                      height: {
                        xs: pxToRem(36),
                        md: pxTovW(58),
                        lg: pxTovW(45),
                      },
                    }}
                  />
                )}
              </Box>
              {viewMode === 'replace' || viewMode === 'add' ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={chooseHandler}
                  sx={{
                    top: { xs: pxToRem(-10), md: 0, lg: 0 },
                    width: {
                      xs: pxToRem(70),
                      md: pxTovW(190),
                      lg: pxTovW(120),
                    },
                    height: { xs: pxToRem(36), md: pxTovW(58), lg: pxTovW(45) },
                  }}
                >
                  <Typography
                    variant="elementBodyText"
                    fontFamily="Poppins"
                    color="common.white"
                  >
                    Choose
                  </Typography>
                </Button>
              ) : (
                !base_station_details?.isOnline &&
                sessionModeFromParam === SessionModeEnum.SESSION_MODE_TEACH && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={headerButtonHandler}
                    sx={{
                      width: {
                        xs: pxToRem(70),
                        md: pxTovW(190),
                        lg: pxTovW(120),
                      },
                      height: {
                        xs: pxToRem(36),
                        md: pxTovW(58),
                        lg: pxTovW(45),
                      },
                    }}
                  >
                    <Typography
                      variant="elementBodyText"
                      fontFamily="Poppins"
                      color="common.white"
                    >
                      +Add
                    </Typography>
                  </Button>
                )
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  };

  if (loading === 'loading') {
    return <Loader />;
  }

  if (loading === 'error') {
    return (
      <Box
        width={{ xs: undefined, md: '95%' }}
        margin={{ xs: pxToRem(20), md: pxTovW(40) }}
        marginRight={{ xs: pxToRem(20), md: pxTovW(40) }}
      >
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      </Box>
    );
  }
  if (createLoader === true) {
    return <Loader />;
  }
  if (!resourceId || !selectedResourceContent) {
    return (
      <Box>
        <NoContentCard variant="info" icon="cards" text="No Cards available" />
      </Box>
    );
  }

  return connectedClass ? (
    <>
      {baseStationData?.sessionId && (
        <ConnectedClassHeader
          pageContent={pageContent}
          isAvailable={baseStationData.isOnline}
        />
      )}
      <Box sx={styles.root}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(20), md: pxTovW(17) },
            position: ViewResponses ? 'relative' : 'sticky',
            zIndex: ViewResponses ? 'unset' : '10',
            top: ViewResponses ? 'unset' : { xs: pxToRem(70), md: pxTovW(100) },
            backgroundColor: ViewResponses ? 'unset' : 'white',
          }}
        >
          {/* {(largeScreen || !ViewResponses) && (
          <Box
            sx={{
              width: '100%',
              display: { xs: 'flex', md: 'unset' },
              justifyContent: 'center',
              marginBottom: pxTovW(31),
              marginTop: { xs: pxToRem(10), md: '0px' },
            }}
          >
            <ConnectedClassIndicator fullwidth variant="wifi" />
          </Box>
        )} */}
          {!largeScreen && ViewResponses && (
            <Box
              sx={{
                display: 'flex',
                margin: `${pxToRem(0)} ${pxToRem(20)} ${pxToRem(40)} ${pxToRem(
                  20
                )} `,
                marginLeft: 'auto',
                width: 'max-content',
                height: pxToRem(28),
              }}
            >
              {' '}
              <SecondaryButton onClick={() => setViewResponses(false)}>
                <Typography
                  variant="smallText"
                  color="#FFFFFF"
                  sx={{ wordSpacing: '2px' }}
                >
                  BACK to QUESTION
                </Typography>
              </SecondaryButton>
            </Box>
          )}
          {/* {(largeScreen || !ViewResponses) && (
            <Box
              sx={{
                width: '100%',
                paddingBottom: { xs: pxToRem(27), md: pxTovW(30) },
              }}
            >
              <HeadingCard
                variant="medium"
                heading={rawResourceContent?.title || ''}
                iconDetails={[
                  {
                    iconName: 'clock',
                    text: `${rawResourceContent?.estimatedTimeInMin} Min`,
                  },
                  {
                    iconName: 'questions',
                    text: `${pageContents.length} Questions`,
                  },
                ]}
                image={getMediaBasePath(
                  rawResourceContent?.posterImageUrl,
                  'processedMediaBucket'
                )}
              />
            </Box>
          )} */}
        </Box>
        <Box>
          <Box
            sx={{
              boxSizing: 'border-box',
              display: 'flex',
              width: '100%',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              // position: 'absolute',
              marginBottom: { xs: pxToRem(80) },
            }}
          >
            <LeftInfoPanel
              pageContent={pageContent}
              ViewResponses={ViewResponses}
              doNotShowResponse={
                !getQuestionCategoryTypetoStartSession(
                  pageContent || firstPageContent
                )
              }
            />
            <Box
              sx={{
                width: { xs: '100%', md: '60%' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { md: pxTovW(40) },
                marginTop: { xs: pxToRem(20), md: '0px' },
                // backgroundColor: 'red',
              }}
            >
              <Box sx={{ marginBottom: { md: pxTovW(100) } }}>
                <ResourcePageContent
                  pageContents={pageContents}
                  pageNumber={pageNumber}
                  contentIdAttempt={contentIdAttempt}
                  handleAnswerSubmit={handleAnswerSubmit}
                  showSubmitButton={true}
                  customStyle={{ padding: 0 }}
                  questionTypeDisplay={true}
                  instuctionsDisplay={true}
                  resource_raw_data={rawResourceContent}
                />
              </Box>
              <Box sx={styles.paginationButtonBox}>
                <PaginationButton
                  currentPage={pageNumber + 1}
                  totalPages={pageContents.length}
                  onNextClick={onNextClick}
                  onPreviousClick={onPreviousClick}
                />
              </Box>
              {!largeScreen &&
                !ViewResponses &&
                getQuestionCategoryTypetoStartSession(
                  pageContent || firstPageContent
                ) && (
                  <LinkButton onClick={() => setViewResponses(true)}>
                    <Typography variant="h1" color="#0AA34F">
                      View Responses
                    </Typography>
                  </LinkButton>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Steps
        open={instructionModal}
        handleClose={() => {
          setInstructionModal(false);
        }}
      />
    </>
  ) : (
    <Box sx={styles.resourcePage}>
      {(pageNumber === 0 || viewMode) && (
        <InPageHeader title={renderHeading()} resourceStatus={approvalStatus} />
      )}

      <ResourcePageContent
        pageContents={pageContents}
        pageNumber={pageNumber}
        contentIdAttempt={contentIdAttempt}
        showSubmitButton={true}
        handleAnswerSubmit={handleAnswerSubmit}
        resource_raw_data={rawResourceContent}
      />

      <Box sx={styles.paginationButtonBox}>
        <PaginationButton
          currentPage={pageNumber + 1}
          totalPages={pageContents.length}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Box>

      <LessonListPopup
        popupState={lessonListPopupState}
        setPopupState={setLessonListPopupState}
        topic_id={topicId}
        subject_id={subjectId}
        resource_id={resourceId}
        setCreateLoaderState={setCreateLoader}
        section_id={class_subject_info?.sectionId}
      />
      {/* {createLoader === true && <Loader />} */}
    </Box>
  );
};

interface IBox {
  iconName: string;
  text?: string;
  index: number;
}

const IconTextBox = ({ iconName, text, index }: IBox) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: pxToRem(4), md: pxTovW(4) },
        alignItems: 'center',
      }}
    >
      <IconWrapper name={iconName} size="md" parentFolder="icons" type="png" />
      <Typography
        variant="bodyText"
        fontWeight={'bold'}
        color={index % 2 === 0 ? 'primary' : 'secondary'}
      >
        {text}
      </Typography>
    </Box>
  );
};

const cdcIconDetails = [
  {
    iconName: 'clock',
    text: '20 Min',
  },
  {
    iconName: 'questions',
    text: 'Questions',
  },
];
interface IRawResourceContent {
  estimatedTimeInMin?: number;
  resourceType?: Resource_ResourceEnum;
}
const RenderCdcIcons = (props: IRawResourceContent) => {
  const { estimatedTimeInMin, resourceType } = props;
  return (
    <>
      {[
        {
          iconName: 'clock',
          text: (estimatedTimeInMin || 0) + 'Mins',
        },
        {
          iconName: resourceType
            ? resourceTypeName(resourceType).icon
            : 'questions',
          text: resourceType ? resourceTypeName(resourceType).name : 'Resource',
        },
      ].map((elem, index) => (
        <IconTextBox
          key={index}
          iconName={elem.iconName}
          text={elem.text}
          index={index}
        />
      ))}
    </>
  );
};

const getQuestionCategoryTypetoStartSession = (
  content: PageContentInfo | undefined
): boolean => {
  if (!content) {
    return false;
  }
  const question = content?.contents[0].model.value as Question;
  const output =
    question &&
    (question.question?.model.value instanceof TfContentModel ||
      question.question?.model.value instanceof McqSingleContentModel ||
      question.question?.model.value instanceof McqMultipleContentModel)
      ? true
      : false;

  return output;
};
