import { Box } from '@mui/material';
import {
  ContentVideoContentModel,
  ResourceCategoryEnum,
} from '@protos/content_management/content.db_pb';
import { pxToRem, pxTovW } from '../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../commonUtils/styleUtils';
import ElementRenderer from '../../ElementRenderer';
import {
  SelectedResourceInfo,
  VideoPlayerWrapper,
} from '../../VideoPlayerWrapper/VideoPlayerWrapper';

const styles: IStyles = {
  videoWrapper: {
    gap: {
      xs: pxToRem(15),
      md: pxTovW(20),
    },
    display: 'flex',
    flexDirection: 'column',
  },
};

interface IProps {
  contentVideo: ContentVideoContentModel;
  selected_resource_info?: SelectedResourceInfo;
  resourceCategory?: ResourceCategoryEnum;
}
export const ContentVideoContent = (props: IProps) => {
  const { contentVideo, selected_resource_info, resourceCategory } = props;

  const elements = contentVideo.elements;
  return (
    <Box sx={styles.videoWrapper}>
      <Box>
        {/* <VideoPlayer videoUrl={contentVideo.primaryVideoUrl} /> */}
        <VideoPlayerWrapper
          videoUrl={contentVideo.primaryVideoUrl}
          selected_resource_info={selected_resource_info}
          resourceCategory={resourceCategory}
        />
      </Box>
      <ElementRenderer
        elements={elements}
        selected_resource_info={selected_resource_info}
        resourceCategory={resourceCategory}
      />
    </Box>
  );
};
