import { PartialMessage } from '@bufbuild/protobuf';
import {
  IStyles,
  IconWrapper,
  ImageWrapper,
  ScoreProgressBarLinear,
  SecondaryButton,
  getFileTypeName,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { CloudUpload } from '@protos/learning_management/lms.db_pb';
import { UploadFileResponse } from '@protos/learning_management/lms.hw.student.apis_pb';
import { ChangeEvent, DragEvent, useRef } from 'react';
import { CustomUploadResponse } from '../../../commonUtils/utilTypes';

const styles: IStyles = {
  root: {
    border: '1px dashed #CCE6FE',
    width: '100%',
    maxWidth: { md: pxTovW(620), lg: pxTovW(520) },
    maxHeight: { md: pxTovW(780), lg: pxTovW(680) },
    boxSizing: 'border-box',
    p: {
      xs: `${pxToRem(15)} ${pxToRem(5)}`,
      md: `${pxTovW(27)} ${pxTovW(18)}`,
    },
    overflowY: { md: 'auto' },
    // margin: 'auto',
  },
  fileCollector: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(25), md: pxTovW(14) },
  },
  plusInGray: {
    width: { xs: pxToRem(50), md: pxTovW(69) },
    height: { xs: pxToRem(50), md: pxTovW(69) },
  },
  uploadButton: {
    width: { lg: pxTovW(112) },
    height: { lg: pxTovW(40) },
    backgroundColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'info.main',
    },
  },

  fileDetails: {
    mt: { xs: pxToRem(15), md: pxTovW(30) },
  },
  loadingInfoBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(14), md: pxTovW(10) },
    width: 'max-content',
    margin: 'auto',
  },
  fileNameSizeBox: {
    display: 'flex',
    gap: { xs: pxToRem(5), md: pxTovW(14) },
    maxWidth: { xs: pxToRem(190), md: pxTovW(300) },
    boxSizing: 'border-box',
  },
  nameTypo: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  percentageAndIconBox: {
    display: 'flex',
    gap: { xs: pxToRem(5), md: pxTovW(14) },
    alignItems: 'flex-start',
  },
  deleteFileHandler: {
    height: { xs: pxToRem(14), md: pxTovW(16) },
    width: { xs: pxToRem(14), md: pxTovW(16) },
    cursor: 'pointer',
  },

  //  -----------------------------------------------------------

  uploadContentRoot: {
    border: '1px dashed #CCE6FE',
    width: '100%',
    // maxWidth: { md: pxTovW(663) },
    // maxHeight: { md: pxTovW(571) },
    boxSizing: 'border-box',
    p: {
      xs: `${pxToRem(15)} ${pxToRem(5)}`,
      md: `${pxTovW(40)} ${pxTovW(24)}`,
    },
    // overflowY: { md: 'auto' },
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
  },
  fileIconsCollection: {
    width: { xs: '100%', md: pxTovW(450) },
    height: { xs: pxToRem(100), md: pxTovW(132) },
  },
  selectFiles: {
    mt: { md: pxTovW(10) },
    width: { xs: pxToRem(130), md: pxTovW(250), lg: pxTovW(208) },
    height: { xs: pxToRem(40), md: pxTovW(66) },
    backgroundColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'info.main',
    },
  },
  orPartition: {
    margin: 'auto',
    width: { xs: pxToRem(285), md: pxTovW(550), lg: pxTovW(451) },
    mt: { xs: pxToRem(30), md: pxTovW(60), lg: pxTovW(30) },
    position: 'relative',
    border: { xs: '1px solid #E8E8E8', md: `${pxTovW(2)} solid #E8E8E8` },
  },

  orBox: {
    margin: 'auto',
    width: 'max-content',
    borderRadius: '50%',
    p: { xs: pxToRem(8), md: pxTovW(8) },
    backgroundColor: '#E8E8E8',
    position: 'absolute',
    left: '45%',
    top: { xs: pxToRem(-15), md: pxTovW(-25), lg: pxTovW(-17) },
  },
  fileDetailsBox: {
    width: { xs: pxToRem(232), md: pxTovW(415), lg: pxTovW(315) },
    // height: { xs: pxToRem(50), md: pxTovW(83) },
    margin: 'auto',
    border: '1px dashed #0AA34F',
    backgroundColor: '#E8FEF2',
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    mt: { xs: pxToRem(15), md: pxTovW(37) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(5)}`,
      md: `${pxTovW(18)} ${pxTovW(20)}`,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(6), md: pxTovW(6) },
  },
  fileDetailsGreenTickBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
};

interface IProps {
  handleDropFunction: (event: DragEvent<HTMLDivElement>) => void;
  handleChangeFunction: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFunction: (indexToDelete: number) => void;
  rootStyle?: SxProps<Theme> | undefined;
  userUploads: CustomUploadResponse[];

  variantSecond?: boolean;
  reorderDragStart?: (position: number) => void;
  reorderDragEnter?: (position: number) => void;
  reorderDrop?: () => void;
  dragOverItemIndex?: number;
}

export const DragAndDropFiles = (props: IProps) => {
  const {
    handleDropFunction,
    handleChangeFunction,
    handleDeleteFunction,
    rootStyle,
    userUploads,
    variantSecond,
  } = props;

  let rootSx = { ...styles.root };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));

  const inputRef = useRef<HTMLInputElement | null>(null);

  const countFileUploadSuccess = userUploads
    .filter((data) => !data.uploadFileData.isDeleted)
    .filter((upload) => upload.fileUploadStatus === 'success').length;

  const totalNonDeletedFiles = userUploads.filter(
    (data) => !data.uploadFileData.isDeleted
  ).length;

  const isAnyFilePending = userUploads?.some(
    (item) => item.fileUploadStatus === 'pending'
  );
  const tempFileUploadPercentage = 0;

  // Type guard
  function isUploadFileResponse(
    data: PartialMessage<UploadFileResponse> | PartialMessage<CloudUpload>
  ): data is PartialMessage<UploadFileResponse> {
    return 'originalImagePath' in data;
  }

  // Type guard
  function isCloudUpload(
    data: PartialMessage<UploadFileResponse> | PartialMessage<CloudUpload>
  ): data is PartialMessage<CloudUpload> {
    return 'fileName' in data;
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isAnyFilePending) return;
    await handleDropFunction(event);
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    await handleChangeFunction(event);
  };

  const handleDelete = async (indexToDelete: number) => {
    await handleDeleteFunction(indexToDelete);
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  };

  if (variantSecond)
    return (
      <UploadContent
        {...props}
        isUploadFileResponse={isUploadFileResponse}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        handleChange={handleChange}
        isAnyFilePending={isAnyFilePending}
        isMobile={isMobile}
        isMedium={isMedium}
        totalNonDeletedFiles={totalNonDeletedFiles}
        countFileUploadSuccess={countFileUploadSuccess}
        isCloudUpload={isCloudUpload}
        formatFileSize={formatFileSize}
        tempFileUploadPercentage={tempFileUploadPercentage}
        handleDelete={handleDelete}
      />
    );

  return (
    <Box
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{ ...rootSx, cursor: isAnyFilePending ? 'wait' : 'default' }}
    >
      <input
        type="file"
        multiple
        hidden
        ref={inputRef}
        onChange={handleChange}
      />

      <Box sx={styles.fileCollector}>
        <ImageWrapper
          // onClick={() => {  }}
          name="white_plus_in_gray"
          type="png"
          parentFolder="icons"
          styles={styles.plusInGray}
        />
        <Typography variant={isMobile ? 'h2' : 'h3'} fontWeight={'regular'}>
          Drag & Drop Files Or Upload
        </Typography>

        <SecondaryButton
          onClick={() => {
            if (inputRef.current) inputRef.current.click();
          }}
          disabled={isAnyFilePending}
          styles={styles.uploadButton}
        >
          <Typography
            variant={isMobile ? 'h3' : 'h5'}
            fontWeight={'medium'}
            color="common.white"
          >
            UPLOAD
          </Typography>
        </SecondaryButton>
      </Box>

      {userUploads && userUploads.length > 0 && (
        <Box sx={styles.fileDetails}>
          <Box sx={styles.loadingInfoBox}>
            {isAnyFilePending && (
              <CircularProgress
                color="primary"
                size={isMobile ? pxToRem(15) : pxTovW(24)}
              />
            )}

            {totalNonDeletedFiles > 0 && (
              <Typography variant="h4" color="info.main">
                {`${countFileUploadSuccess} out of ${totalNonDeletedFiles} uploaded`}
              </Typography>
            )}
          </Box>

          <Box sx={{ mt: { md: pxTovW(37) } }}>
            {userUploads.map((file, index) => {
              if (file.uploadFileData.isDeleted === true) return null;
              else
                return (
                  <Box
                    key={index}
                    sx={{
                      pt: {
                        xs: pxToRem(14),
                        md: index !== 0 ? pxTovW(17) : pxTovW(0),
                      },
                      pb: {
                        xs:
                          index !== userUploads.length - 1
                            ? pxToRem(14)
                            : pxToRem(0),
                        md:
                          index !== userUploads.length - 1
                            ? pxTovW(17)
                            : pxTovW(0),
                      },
                      borderBottom:
                        index !== userUploads.length - 1
                          ? '1px solid #D5D5D5'
                          : 'none',

                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: { xs: pxToRem(10), md: pxTovW(15) },
                    }}
                  >
                    <IconWrapper
                      name={getFileTypeName(file.uploadFileData.fileType)}
                      size="md"
                      parentFolder="icons"
                      type="png"
                    />

                    <Box sx={{ flexGrow: '1' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: { xs: pxToRem(10), md: pxTovW(10) },
                        }}
                      >
                        <Box sx={styles.fileNameSizeBox}>
                          <Typography
                            variant="h5"
                            color="primary.main"
                            sx={styles.nameTypo}
                          >
                            {isUploadFileResponse(file.uploadFileData)
                              ? file.uploadFileData.originalImagePath
                              : isCloudUpload(file.uploadFileData)
                              ? file.uploadFileData.fileName
                              : ''}
                          </Typography>

                          <Typography
                            variant="h5"
                            color="neutral.grey"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            {formatFileSize(file.fileSize)}
                          </Typography>
                        </Box>

                        <Box sx={styles.percentageAndIconBox}>
                          <Typography
                            variant="h5"
                            textAlign="center"
                            color={
                              file.fileUploadStatus === 'pending' ||
                              file.fileUploadStatus === 'success'
                                ? 'primary.main'
                                : 'neutral.crimson'
                            }
                          >
                            {/* //todo: Get percentage of the uploaded file % */}
                            {file.fileUploadStatus === 'success'
                              ? '100%'
                              : file.fileUploadStatus === 'pending'
                              ? tempFileUploadPercentage + '%'
                              : file.fileUploadStatus}
                          </Typography>

                          {/*//^ Green tick OR Error Symbol */}
                          {file.fileUploadStatus !== 'pending' && (
                            <IconWrapper
                              name={
                                file.fileUploadStatus === 'success'
                                  ? 'green-circle-check'
                                  : 'info_red'
                              }
                              size="md"
                              parentFolder="icons"
                              type="png"
                            />
                          )}
                        </Box>
                      </Box>

                      {/* //todo: Get percentage of the uploaded file % */}
                      <ScoreProgressBarLinear
                        score={
                          file.fileUploadStatus === 'success'
                            ? 100
                            : file.fileUploadStatus === 'pending'
                            ? tempFileUploadPercentage
                            : 0
                        }
                        variant="small"
                        customSx={{
                          mt: { xs: pxToRem(10), md: pxTovW(11) },
                          backgroundColor:
                            file.fileUploadStatus === 'pending' ||
                            file.fileUploadStatus === 'success'
                              ? '#CBF0DC'
                              : 'neutral.lightPink',
                        }}
                      />
                    </Box>

                    {file.fileUploadStatus !== 'pending' && (
                      <IconWrapper
                        name="white_x_in_gray"
                        size="md"
                        parentFolder="icons"
                        type="png"
                        onClick={() => handleDelete(index)}
                        customSx={styles.deleteFileHandler}
                      />
                    )}
                  </Box>
                );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface UploadContentProps extends IProps {
  isUploadFileResponse: (
    data: PartialMessage<UploadFileResponse> | PartialMessage<CloudUpload>
  ) => data is PartialMessage<UploadFileResponse>;
  handleDragOver: (event: DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: DragEvent<HTMLDivElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isAnyFilePending: boolean;
  isMobile: boolean;
  isMedium: boolean;
  totalNonDeletedFiles: number;
  countFileUploadSuccess: number;
  isCloudUpload: (
    data: PartialMessage<UploadFileResponse> | PartialMessage<CloudUpload>
  ) => data is PartialMessage<CloudUpload>;
  formatFileSize: (bytes: number) => string;
  tempFileUploadPercentage: number;
  handleDelete: (indexToDelete: number) => void;
}

const UploadContent = (props: UploadContentProps) => {
  const {
    rootStyle,
    userUploads,
    isUploadFileResponse,
    handleDragOver,
    handleDrop,
    handleChange,
    isAnyFilePending,
    isMobile,
    isMedium,
    totalNonDeletedFiles,
    countFileUploadSuccess,
    isCloudUpload,
    formatFileSize,
    tempFileUploadPercentage,
    handleDelete,
    reorderDragStart,
    reorderDragEnter,
    reorderDrop,
    dragOverItemIndex,
  } = props;

  let rootSx = { ...styles.uploadContentRoot };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Box
      sx={{ ...rootSx, cursor: isAnyFilePending ? 'wait' : 'default' }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <Box>
        <input
          type="file"
          multiple
          hidden
          ref={inputRef}
          onChange={handleChange}
        />

        <Box sx={styles.fileCollector}>
          <ImageWrapper
            // onClick={() => {  }}
            name="file-icons-collection"
            type="png"
            parentFolder="images"
            styles={styles.fileIconsCollection}
          />

          <SecondaryButton
            onClick={() => {
              if (inputRef.current) inputRef.current.click();
            }}
            disabled={isAnyFilePending}
            styles={styles.selectFiles}
          >
            <Typography
              variant={isMobile ? 'h3' : isMedium ? 'h5' : 'h3'}
              fontWeight={'medium'}
              color="common.white"
            >
              SELECT FILES
            </Typography>
          </SecondaryButton>
        </Box>

        {totalNonDeletedFiles <= 0 && (
          <>
            <Box sx={styles.orPartition}>
              <Box sx={styles.orBox}>
                <Typography variant={'h4'} fontWeight={'medium'}>
                  OR
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: { xs: pxToRem(30), md: pxTovW(50) } }}>
              <Typography
                variant={'h3'}
                fontWeight={'medium'}
                textAlign={'center'}
              >
                Drop Files Here
              </Typography>

              <Box sx={styles.fileDetailsBox}>
                {fileInfoDetails.map((data, index) => (
                  <Box key={index} sx={styles.fileDetailsGreenTickBox}>
                    <IconWrapper
                      name="green-tick"
                      size="small"
                      parentFolder="icons"
                      type="png"
                    />

                    <Typography variant={'h4'} fontWeight={'regular'}>
                      {data}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>

      {userUploads && userUploads.length > 0 && (
        <Box sx={styles.fileDetails}>
          <Box sx={styles.loadingInfoBox}>
            {isAnyFilePending && (
              <CircularProgress
                color="primary"
                size={isMobile ? pxToRem(15) : pxTovW(24)}
              />
            )}

            {totalNonDeletedFiles > 0 && (
              <Typography variant="h4" color="info.main">
                {`${countFileUploadSuccess} out of ${totalNonDeletedFiles} uploaded`}
              </Typography>
            )}
          </Box>

          <Box sx={{ mt: { md: pxTovW(37) } }}>
            {userUploads.map((file, index) => {
              if (file.uploadFileData.isDeleted === true) return null;
              else
                return (
                  <Box
                    key={index}
                    sx={{
                      pt: {
                        xs: pxToRem(14),
                        md: index !== 0 ? pxTovW(17) : pxTovW(0),
                      },
                      pb: {
                        xs:
                          index !== userUploads.length - 1
                            ? pxToRem(14)
                            : pxToRem(0),
                        md:
                          index !== userUploads.length - 1
                            ? pxTovW(17)
                            : pxTovW(0),
                      },
                      borderBottom:
                        index !== userUploads.length - 1
                          ? '1px solid #D5D5D5'
                          : 'none',

                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: { xs: pxToRem(10), md: pxTovW(20) },
                      alignItems: 'center',
                      opacity: dragOverItemIndex === index ? 0.2 : 1,
                    }}
                  >
                    <Button
                      draggable={true}
                      onDragStart={() => {
                        reorderDragStart && reorderDragStart(index);
                      }}
                      onDragEnter={() => {
                        reorderDragEnter && reorderDragEnter(index);
                      }}
                      onDragEnd={() => {
                        reorderDrop && reorderDrop();
                      }}
                      disabled={isAnyFilePending}
                      sx={{ cursor: 'grab' }}
                    >
                      <IconWrapper
                        name="reorder"
                        size="small"
                        type="png"
                        parentFolder="icons"
                      />
                    </Button>

                    <Box sx={{ flexGrow: '1' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: { xs: pxToRem(10), md: pxTovW(10) },
                        }}
                      >
                        <Box sx={styles.fileNameSizeBox}>
                          <IconWrapper
                            name={getFileTypeName(file.uploadFileData.fileType)}
                            size="md"
                            parentFolder="icons"
                            type="png"
                          />
                          <Typography
                            variant="h5"
                            color="primary.main"
                            sx={styles.nameTypo}
                          >
                            {isUploadFileResponse(file.uploadFileData)
                              ? file.uploadFileData.originalImagePath
                              : isCloudUpload(file.uploadFileData)
                              ? file.uploadFileData.fileName
                              : ''}
                          </Typography>

                          {/* <Typography
                            variant="h5"
                            color="neutral.grey"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            {formatFileSize(file.fileSize)}
                          </Typography> */}
                        </Box>

                        <Box sx={styles.percentageAndIconBox}>
                          <Typography
                            variant="h5"
                            color="neutral.grey"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            {formatFileSize(file.fileSize)}
                          </Typography>
                          <Typography
                            variant="h5"
                            textAlign="center"
                            color={
                              file.fileUploadStatus === 'pending' ||
                              file.fileUploadStatus === 'success'
                                ? 'primary.main'
                                : 'neutral.crimson'
                            }
                          >
                            {/* //todo: Get percentage of the uploaded file % */}
                            {file.fileUploadStatus === 'success'
                              ? '100%'
                              : file.fileUploadStatus === 'pending'
                              ? tempFileUploadPercentage + '%'
                              : file.fileUploadStatus}
                          </Typography>

                          {/* if preview is available for the file */}
                          {/* <IconWrapper
                            name="gray-eye"
                            size="small"
                            parentFolder="icons"
                            type="png"
                            onClick={() => {
                              console.log('gray-eye: ', index);
                            }}
                            customSx={{
                              width: { xs: pxToRem(14), md: pxTovW(25) },
                              height: { xs: pxToRem(14), md: pxTovW(18) },
                              cursor: 'pointer',
                            }}
                          /> */}

                          {/*//^ Green tick OR Error Symbol */}
                          {file.fileUploadStatus !== 'pending' && (
                            <IconWrapper
                              name={
                                file.fileUploadStatus === 'success'
                                  ? 'green-circle-check'
                                  : 'info_red'
                              }
                              size="md"
                              parentFolder="icons"
                              type="png"
                            />
                          )}

                          {file.fileUploadStatus !== 'pending' && (
                            <IconWrapper
                              name="white_x_in_gray"
                              size="md"
                              parentFolder="icons"
                              type="png"
                              onClick={() => handleDelete(index)}
                              customSx={styles.deleteFileHandler}
                            />
                          )}
                        </Box>
                      </Box>

                      {/* //todo: Get percentage of the uploaded file % */}
                      <ScoreProgressBarLinear
                        score={
                          file.fileUploadStatus === 'success'
                            ? 100
                            : file.fileUploadStatus === 'pending'
                            ? tempFileUploadPercentage
                            : 0
                        }
                        variant="small"
                        customSx={{
                          mt: { xs: pxToRem(10), md: pxTovW(11) },
                          backgroundColor:
                            file.fileUploadStatus === 'pending' ||
                            file.fileUploadStatus === 'success'
                              ? '#CBF0DC'
                              : 'neutral.lightPink',
                        }}
                      />
                    </Box>
                  </Box>
                );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const fileInfoDetails = [
  'File Format: jpeg, png, pdf, doc, ppt',
  'File size - Minimum 10KB & Maximum 50MB each',
];
