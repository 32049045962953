import {
  AssessmentButton,
  IStyles,
  roundingAssessmentNumber,
  pxToRem,
} from '@geneo2-web/shared-ui';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigation } from 'react-router-dom';

const styles: IStyles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { md: 'space-between' },
    gap: { xs: pxToRem(31), md: 0 },
  },
};
interface IProps {
  createClickHandler: () => void;
  libraryClickHandler: () => void;
  hwCount?: number;
}
export default function AssementRedirectionComponent(props: IProps) {
  const { createClickHandler, libraryClickHandler, hwCount } = props;
  const navigate = useNavigation();
  return (
    <Box sx={styles.root}>
      <AssessmentButton
        text="Create"
        subText="New Assessment"
        backgroundColor="#FFDD7B"
        imageName="working-person-group"
        clickHandler={createClickHandler}
        variant="regular"
      />

      <AssessmentButton
        text="Choose"
        subText={`From Library (${roundingAssessmentNumber(
          Number(100)
        )} ready to use assessments)`}
        backgroundColor="#CBEECD"
        imageName="library-group"
        clickHandler={libraryClickHandler}
        variant="regular"
      />
    </Box>
  );
}
