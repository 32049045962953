// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.apis.proto (package geneo.lms.assessment.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";
import { AssessmentEnum } from "@protos/learning_management/lms.db_pb";
import { HomeworkListEnum } from "@protos/learning_management/lms.hw.teacher.apis_pb";
import { HomeworkTask } from "@protos/learning_management/lms.hw.common.apis_pb";

/**
 * @generated from message geneo.lms.assessment.apis.FetchAssessmentsByModuleRequest
 */
export class FetchAssessmentsByModuleRequest extends Message<FetchAssessmentsByModuleRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: optional int32 chapter_id = 4;
   */
  chapterId?: number;

  /**
   * @generated from field: optional int32 topic_id = 5;
   */
  topicId?: number;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 6;
   */
  assessmentType?: AssessmentEnum;

  /**
   * @generated from field: optional int32 page_number = 7;
   */
  pageNumber?: number;

  constructor(data?: PartialMessage<FetchAssessmentsByModuleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.FetchAssessmentsByModuleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 7, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentsByModuleRequest {
    return new FetchAssessmentsByModuleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleRequest {
    return new FetchAssessmentsByModuleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleRequest {
    return new FetchAssessmentsByModuleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentsByModuleRequest | PlainMessage<FetchAssessmentsByModuleRequest> | undefined, b: FetchAssessmentsByModuleRequest | PlainMessage<FetchAssessmentsByModuleRequest> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentsByModuleRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.apis.FetchAssessmentsByModuleResponse
 */
export class FetchAssessmentsByModuleResponse extends Message<FetchAssessmentsByModuleResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.apis.AssessmentsByType data = 4;
   */
  data?: AssessmentsByType;

  constructor(data?: PartialMessage<FetchAssessmentsByModuleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.FetchAssessmentsByModuleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AssessmentsByType },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentsByModuleResponse {
    return new FetchAssessmentsByModuleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleResponse {
    return new FetchAssessmentsByModuleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleResponse {
    return new FetchAssessmentsByModuleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentsByModuleResponse | PlainMessage<FetchAssessmentsByModuleResponse> | undefined, b: FetchAssessmentsByModuleResponse | PlainMessage<FetchAssessmentsByModuleResponse> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentsByModuleResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.apis.GetTeacherAssessmentsListRequest
 */
export class GetTeacherAssessmentsListRequest extends Message<GetTeacherAssessmentsListRequest> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course (optional)
   *
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 page_number = 5;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 items_per_page = 6;
   */
  itemsPerPage?: number;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 7;
   */
  assessmentType?: AssessmentEnum;

  /**
   * @generated from field: optional geneo.lms.hw.teacher.apis.HomeworkListEnum homework_list_type = 8;
   */
  homeworkListType?: HomeworkListEnum;

  constructor(data?: PartialMessage<GetTeacherAssessmentsListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.GetTeacherAssessmentsListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "items_per_page", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 8, name: "homework_list_type", kind: "enum", T: proto3.getEnumType(HomeworkListEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherAssessmentsListRequest | PlainMessage<GetTeacherAssessmentsListRequest> | undefined, b: GetTeacherAssessmentsListRequest | PlainMessage<GetTeacherAssessmentsListRequest> | undefined): boolean {
    return proto3.util.equals(GetTeacherAssessmentsListRequest, a, b);
  }
}

/**
 * Response model for /teacher/homework/list
 *
 * @generated from message geneo.lms.assessment.apis.GetTeacherAssessmentsListResponse
 */
export class GetTeacherAssessmentsListResponse extends Message<GetTeacherAssessmentsListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.apis.TeacherAssessmentsList assessment_list = 4;
   */
  assessmentList?: TeacherAssessmentsList;

  constructor(data?: PartialMessage<GetTeacherAssessmentsListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.GetTeacherAssessmentsListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "assessment_list", kind: "message", T: TeacherAssessmentsList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherAssessmentsListResponse | PlainMessage<GetTeacherAssessmentsListResponse> | undefined, b: GetTeacherAssessmentsListResponse | PlainMessage<GetTeacherAssessmentsListResponse> | undefined): boolean {
    return proto3.util.equals(GetTeacherAssessmentsListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.apis.TeacherAssessmentsList
 */
export class TeacherAssessmentsList extends Message<TeacherAssessmentsList> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * @generated from field: geneo.lms.assessment.apis.AssessmentList assessment_list = 6;
   */
  assessmentList?: AssessmentList;

  constructor(data?: PartialMessage<TeacherAssessmentsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.TeacherAssessmentsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "assessment_list", kind: "message", T: AssessmentList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentsList | PlainMessage<TeacherAssessmentsList> | undefined, b: TeacherAssessmentsList | PlainMessage<TeacherAssessmentsList> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentsList, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.apis.AssessmentsByType
 */
export class AssessmentsByType extends Message<AssessmentsByType> {
  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask assessments = 1;
   */
  assessments: HomeworkTask[] = [];

  /**
   * @generated from field: optional geneo.lms.assessment.apis.AssessmentListInfo assessment_list_info = 2;
   */
  assessmentListInfo?: AssessmentListInfo;

  constructor(data?: PartialMessage<AssessmentsByType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.AssessmentsByType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessments", kind: "message", T: HomeworkTask, repeated: true },
    { no: 2, name: "assessment_list_info", kind: "message", T: AssessmentListInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentsByType {
    return new AssessmentsByType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentsByType {
    return new AssessmentsByType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentsByType {
    return new AssessmentsByType().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentsByType | PlainMessage<AssessmentsByType> | undefined, b: AssessmentsByType | PlainMessage<AssessmentsByType> | undefined): boolean {
    return proto3.util.equals(AssessmentsByType, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.apis.AssessmentList
 */
export class AssessmentList extends Message<AssessmentList> {
  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask draft = 1;
   */
  draft: HomeworkTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask assigned = 2;
   */
  assigned: HomeworkTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask ended = 3;
   */
  ended: HomeworkTask[] = [];

  /**
   * @generated from field: optional geneo.lms.assessment.apis.AssessmentListCountInfo assesment_list_info = 4;
   */
  assesmentListInfo?: AssessmentListCountInfo;

  constructor(data?: PartialMessage<AssessmentList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.AssessmentList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft", kind: "message", T: HomeworkTask, repeated: true },
    { no: 2, name: "assigned", kind: "message", T: HomeworkTask, repeated: true },
    { no: 3, name: "ended", kind: "message", T: HomeworkTask, repeated: true },
    { no: 4, name: "assesment_list_info", kind: "message", T: AssessmentListCountInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentList {
    return new AssessmentList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentList {
    return new AssessmentList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentList {
    return new AssessmentList().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentList | PlainMessage<AssessmentList> | undefined, b: AssessmentList | PlainMessage<AssessmentList> | undefined): boolean {
    return proto3.util.equals(AssessmentList, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.apis.AssessmentListCountInfo
 */
export class AssessmentListCountInfo extends Message<AssessmentListCountInfo> {
  /**
   * @generated from field: int32 total_count = 1;
   */
  totalCount = 0;

  /**
   * @generated from field: int32 draft_count = 2;
   */
  draftCount = 0;

  /**
   * @generated from field: int32 active_count = 3;
   */
  activeCount = 0;

  /**
   * @generated from field: int32 ended_count = 4;
   */
  endedCount = 0;

  /**
   * @generated from field: int32 count_in_pagination = 5;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<AssessmentListCountInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.AssessmentListCountInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "draft_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "active_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "ended_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentListCountInfo | PlainMessage<AssessmentListCountInfo> | undefined, b: AssessmentListCountInfo | PlainMessage<AssessmentListCountInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentListCountInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.apis.AssessmentListInfo
 */
export class AssessmentListInfo extends Message<AssessmentListInfo> {
  /**
   * @generated from field: int32 total_assessments_count = 1;
   */
  totalAssessmentsCount = 0;

  /**
   * @generated from field: int32 homeworks_count = 2;
   */
  homeworksCount = 0;

  /**
   * @generated from field: int32 formative_assessments_count = 3;
   */
  formativeAssessmentsCount = 0;

  /**
   * @generated from field: int32 diagnostics_assessments = 4;
   */
  diagnosticsAssessments = 0;

  /**
   * @generated from field: int32 count_in_pagination = 5;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<AssessmentListInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.apis.AssessmentListInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_assessments_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homeworks_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "formative_assessments_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "diagnostics_assessments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentListInfo {
    return new AssessmentListInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentListInfo {
    return new AssessmentListInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentListInfo {
    return new AssessmentListInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentListInfo | PlainMessage<AssessmentListInfo> | undefined, b: AssessmentListInfo | PlainMessage<AssessmentListInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentListInfo, a, b);
  }
}

