// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.student.events.proto (package geneo.ai.student.events, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { TaskEnum } from "@protos/learning_management/lms.db_pb";
import { Content_ContentEnum, Question_QuestionEnum, Resource_ResourceEnum, ResourceCategoryEnum } from "@protos/content_management/content.db_pb";

/**
 * @generated from enum geneo.ai.student.events.HomeWorkSelectionFromTypeEnum
 */
export enum HomeWorkSelectionFromTypeEnum {
  /**
   * @generated from enum value: HOMEWORK_SELECTION_FROM_UNDEFINED = 0;
   */
  HOMEWORK_SELECTION_FROM_UNDEFINED = 0,

  /**
   * @generated from enum value: APP_HOMEPAGE_HW = 1;
   */
  APP_HOMEPAGE_HW = 1,

  /**
   * @generated from enum value: HOMEWORK_SUBMITTED_HOMEPAGE_HW = 2;
   */
  HOMEWORK_SUBMITTED_HOMEPAGE_HW = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(HomeWorkSelectionFromTypeEnum)
proto3.util.setEnumType(HomeWorkSelectionFromTypeEnum, "geneo.ai.student.events.HomeWorkSelectionFromTypeEnum", [
  { no: 0, name: "HOMEWORK_SELECTION_FROM_UNDEFINED" },
  { no: 1, name: "APP_HOMEPAGE_HW" },
  { no: 2, name: "HOMEWORK_SUBMITTED_HOMEPAGE_HW" },
]);

/**
 * @generated from enum geneo.ai.student.events.QAttemptResultEnum
 */
export enum QAttemptResultEnum {
  /**
   * @generated from enum value: RESPONSE_UNDEFINED = 0;
   */
  RESPONSE_UNDEFINED = 0,

  /**
   * @generated from enum value: RESPONSE_CORRECT = 1;
   */
  RESPONSE_CORRECT = 1,

  /**
   * @generated from enum value: RESPONSE_INCORRECT = 2;
   */
  RESPONSE_INCORRECT = 2,

  /**
   * @generated from enum value: RESPONSE_PARTIALLY_CORRECT = 3;
   */
  RESPONSE_PARTIALLY_CORRECT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(QAttemptResultEnum)
proto3.util.setEnumType(QAttemptResultEnum, "geneo.ai.student.events.QAttemptResultEnum", [
  { no: 0, name: "RESPONSE_UNDEFINED" },
  { no: 1, name: "RESPONSE_CORRECT" },
  { no: 2, name: "RESPONSE_INCORRECT" },
  { no: 3, name: "RESPONSE_PARTIALLY_CORRECT" },
]);

/**
 * @generated from enum geneo.ai.student.events.ResourceSelectionFromTypeEnum
 */
export enum ResourceSelectionFromTypeEnum {
  /**
   * @generated from enum value: RESOURCE_SELECTION_FROM_UNDEFINED = 0;
   */
  RESOURCE_SELECTION_FROM_UNDEFINED = 0,

  /**
   * @generated from enum value: CHAPTER_HOMEPAGE = 1;
   */
  CHAPTER_HOMEPAGE = 1,

  /**
   * @generated from enum value: TOPIC_HOMEPAGE = 2;
   */
  TOPIC_HOMEPAGE = 2,

  /**
   * @generated from enum value: LESSON_PLAN_HOMEPAGE = 3;
   */
  LESSON_PLAN_HOMEPAGE = 3,

  /**
   * @generated from enum value: APP_HOMEPAGE_RECOMMENDATION = 4;
   */
  APP_HOMEPAGE_RECOMMENDATION = 4,

  /**
   * @generated from enum value: PERFOMANCE_HOMEPAGE = 5;
   */
  PERFOMANCE_HOMEPAGE = 5,

  /**
   * @generated from enum value: HOMEWORK_ENDED_RECOMMENDATION = 6;
   */
  HOMEWORK_ENDED_RECOMMENDATION = 6,

  /**
   * @generated from enum value: HOMEWORK_SUBMITTED_RECOMMENDATION = 7;
   */
  HOMEWORK_SUBMITTED_RECOMMENDATION = 7,

  /**
   * @generated from enum value: APP_SELECTED_SUBJECT_HOMEPAGE_RECOMMENDATION = 8;
   */
  APP_SELECTED_SUBJECT_HOMEPAGE_RECOMMENDATION = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(ResourceSelectionFromTypeEnum)
proto3.util.setEnumType(ResourceSelectionFromTypeEnum, "geneo.ai.student.events.ResourceSelectionFromTypeEnum", [
  { no: 0, name: "RESOURCE_SELECTION_FROM_UNDEFINED" },
  { no: 1, name: "CHAPTER_HOMEPAGE" },
  { no: 2, name: "TOPIC_HOMEPAGE" },
  { no: 3, name: "LESSON_PLAN_HOMEPAGE" },
  { no: 4, name: "APP_HOMEPAGE_RECOMMENDATION" },
  { no: 5, name: "PERFOMANCE_HOMEPAGE" },
  { no: 6, name: "HOMEWORK_ENDED_RECOMMENDATION" },
  { no: 7, name: "HOMEWORK_SUBMITTED_RECOMMENDATION" },
  { no: 8, name: "APP_SELECTED_SUBJECT_HOMEPAGE_RECOMMENDATION" },
]);

/**
 * @generated from enum geneo.ai.student.events.LessonPlanSelectionFromTypeEnum
 */
export enum LessonPlanSelectionFromTypeEnum {
  /**
   * @generated from enum value: LESSONPLAN_SELECTION_FROM_UNDEFINED = 0;
   */
  LESSONPLAN_SELECTION_FROM_UNDEFINED = 0,

  /**
   * @generated from enum value: APP_HOMEPAGE_LP = 1;
   */
  APP_HOMEPAGE_LP = 1,

  /**
   * @generated from enum value: CHAPTER_HOMEPAGE_LP = 2;
   */
  CHAPTER_HOMEPAGE_LP = 2,

  /**
   * @generated from enum value: TOPIC_HOMEPAGE_LP = 3;
   */
  TOPIC_HOMEPAGE_LP = 3,

  /**
   * @generated from enum value: LESSON_PLAN_HOMEPAGE_LP = 4;
   */
  LESSON_PLAN_HOMEPAGE_LP = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(LessonPlanSelectionFromTypeEnum)
proto3.util.setEnumType(LessonPlanSelectionFromTypeEnum, "geneo.ai.student.events.LessonPlanSelectionFromTypeEnum", [
  { no: 0, name: "LESSONPLAN_SELECTION_FROM_UNDEFINED" },
  { no: 1, name: "APP_HOMEPAGE_LP" },
  { no: 2, name: "CHAPTER_HOMEPAGE_LP" },
  { no: 3, name: "TOPIC_HOMEPAGE_LP" },
  { no: 4, name: "LESSON_PLAN_HOMEPAGE_LP" },
]);

/**
 * @generated from message geneo.ai.student.events.HomeworkAction
 */
export class HomeworkAction extends Message<HomeworkAction> {
  /**
   * @generated from oneof geneo.ai.student.events.HomeworkAction.HomeworkActionType
   */
  HomeworkActionType: {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction.HomeworkOpen open = 1;
     */
    value: HomeworkAction_HomeworkOpen;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction.QuestionOpen question_open = 2;
     */
    value: HomeworkAction_QuestionOpen;
    case: "questionOpen";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction.QuestionSubmitSummary question_submit = 3;
     */
    value: HomeworkAction_QuestionSubmitSummary;
    case: "questionSubmit";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction.ReviewhomeworkOpen review_open = 4;
     */
    value: HomeworkAction_ReviewhomeworkOpen;
    case: "reviewOpen";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction.HomeworkSummary review_close = 5;
     */
    value: HomeworkAction_HomeworkSummary;
    case: "reviewClose";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction.QuestionReviewSummary question_review_summary = 6;
     */
    value: HomeworkAction_QuestionReviewSummary;
    case: "questionReviewSummary";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction.HomeworkSummary close = 7;
     */
    value: HomeworkAction_HomeworkSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 homework_id = 11;
   */
  homeworkId = 0;

  /**
   * @generated from field: string action_id = 12;
   */
  actionId = "";

  /**
   * @generated from field: geneo.ai.student.events.HomeWorkSelectionFromTypeEnum selected_from = 13;
   */
  selectedFrom = HomeWorkSelectionFromTypeEnum.HOMEWORK_SELECTION_FROM_UNDEFINED;

  /**
   * @generated from field: optional bool reattempt = 14;
   */
  reattempt?: boolean;

  /**
   * @generated from field: optional bool resume = 15;
   */
  resume?: boolean;

  /**
   * Type of task (enum: task_type_enum)
   *
   * @generated from field: optional geneo.lms.db.TaskEnum homework_type = 16;
   */
  homeworkType?: TaskEnum;

  constructor(data?: PartialMessage<HomeworkAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.HomeworkAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "message", T: HomeworkAction_HomeworkOpen, oneof: "HomeworkActionType" },
    { no: 2, name: "question_open", kind: "message", T: HomeworkAction_QuestionOpen, oneof: "HomeworkActionType" },
    { no: 3, name: "question_submit", kind: "message", T: HomeworkAction_QuestionSubmitSummary, oneof: "HomeworkActionType" },
    { no: 4, name: "review_open", kind: "message", T: HomeworkAction_ReviewhomeworkOpen, oneof: "HomeworkActionType" },
    { no: 5, name: "review_close", kind: "message", T: HomeworkAction_HomeworkSummary, oneof: "HomeworkActionType" },
    { no: 6, name: "question_review_summary", kind: "message", T: HomeworkAction_QuestionReviewSummary, oneof: "HomeworkActionType" },
    { no: 7, name: "close", kind: "message", T: HomeworkAction_HomeworkSummary, oneof: "HomeworkActionType" },
    { no: 11, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "selected_from", kind: "enum", T: proto3.getEnumType(HomeWorkSelectionFromTypeEnum) },
    { no: 14, name: "reattempt", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 15, name: "resume", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 16, name: "homework_type", kind: "enum", T: proto3.getEnumType(TaskEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction {
    return new HomeworkAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction {
    return new HomeworkAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction {
    return new HomeworkAction().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction | PlainMessage<HomeworkAction> | undefined, b: HomeworkAction | PlainMessage<HomeworkAction> | undefined): boolean {
    return proto3.util.equals(HomeworkAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.HomeworkAction.QuestionOpen
 */
export class HomeworkAction_QuestionOpen extends Message<HomeworkAction_QuestionOpen> {
  /**
   * int32 question_index = 2;
   *
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  constructor(data?: PartialMessage<HomeworkAction_QuestionOpen>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.HomeworkAction.QuestionOpen";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_QuestionOpen {
    return new HomeworkAction_QuestionOpen().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionOpen {
    return new HomeworkAction_QuestionOpen().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionOpen {
    return new HomeworkAction_QuestionOpen().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_QuestionOpen | PlainMessage<HomeworkAction_QuestionOpen> | undefined, b: HomeworkAction_QuestionOpen | PlainMessage<HomeworkAction_QuestionOpen> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_QuestionOpen, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.HomeworkAction.QuestionSubmitSummary
 */
export class HomeworkAction_QuestionSubmitSummary extends Message<HomeworkAction_QuestionSubmitSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  /**
   * @generated from field: repeated string answer = 2;
   */
  answer: string[] = [];

  /**
   * @generated from field: string question_id = 3;
   */
  questionId = "";

  /**
   * @generated from field: optional bool is_correct_answer_open = 4;
   */
  isCorrectAnswerOpen?: boolean;

  /**
   * int32 question_index = 4;
   *
   * @generated from field: geneo.ai.student.events.QAttemptResultEnum status = 5;
   */
  status = QAttemptResultEnum.RESPONSE_UNDEFINED;

  constructor(data?: PartialMessage<HomeworkAction_QuestionSubmitSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.HomeworkAction.QuestionSubmitSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_correct_answer_open", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(QAttemptResultEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_QuestionSubmitSummary {
    return new HomeworkAction_QuestionSubmitSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionSubmitSummary {
    return new HomeworkAction_QuestionSubmitSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionSubmitSummary {
    return new HomeworkAction_QuestionSubmitSummary().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_QuestionSubmitSummary | PlainMessage<HomeworkAction_QuestionSubmitSummary> | undefined, b: HomeworkAction_QuestionSubmitSummary | PlainMessage<HomeworkAction_QuestionSubmitSummary> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_QuestionSubmitSummary, a, b);
  }
}

/**
 * content open capture in correct answer summary
 *
 * @generated from message geneo.ai.student.events.HomeworkAction.QuestionReviewSummary
 */
export class HomeworkAction_QuestionReviewSummary extends Message<HomeworkAction_QuestionReviewSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  /**
   * int32 question_index = 3;
   *
   * @generated from field: string question_id = 2;
   */
  questionId = "";

  constructor(data?: PartialMessage<HomeworkAction_QuestionReviewSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.HomeworkAction.QuestionReviewSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_QuestionReviewSummary {
    return new HomeworkAction_QuestionReviewSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionReviewSummary {
    return new HomeworkAction_QuestionReviewSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionReviewSummary {
    return new HomeworkAction_QuestionReviewSummary().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_QuestionReviewSummary | PlainMessage<HomeworkAction_QuestionReviewSummary> | undefined, b: HomeworkAction_QuestionReviewSummary | PlainMessage<HomeworkAction_QuestionReviewSummary> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_QuestionReviewSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.HomeworkAction.HomeworkSummary
 */
export class HomeworkAction_HomeworkSummary extends Message<HomeworkAction_HomeworkSummary> {
  /**
   * @generated from field: optional int32 score = 1;
   */
  score?: number;

  /**
   * @generated from field: int32 timespent = 2;
   */
  timespent = 0;

  constructor(data?: PartialMessage<HomeworkAction_HomeworkSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.HomeworkAction.HomeworkSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "score", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_HomeworkSummary {
    return new HomeworkAction_HomeworkSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkSummary {
    return new HomeworkAction_HomeworkSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkSummary {
    return new HomeworkAction_HomeworkSummary().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_HomeworkSummary | PlainMessage<HomeworkAction_HomeworkSummary> | undefined, b: HomeworkAction_HomeworkSummary | PlainMessage<HomeworkAction_HomeworkSummary> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_HomeworkSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.HomeworkAction.HomeworkOpen
 */
export class HomeworkAction_HomeworkOpen extends Message<HomeworkAction_HomeworkOpen> {
  /**
   * @generated from field: repeated string question_ids = 1;
   */
  questionIds: string[] = [];

  constructor(data?: PartialMessage<HomeworkAction_HomeworkOpen>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.HomeworkAction.HomeworkOpen";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_HomeworkOpen {
    return new HomeworkAction_HomeworkOpen().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkOpen {
    return new HomeworkAction_HomeworkOpen().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkOpen {
    return new HomeworkAction_HomeworkOpen().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_HomeworkOpen | PlainMessage<HomeworkAction_HomeworkOpen> | undefined, b: HomeworkAction_HomeworkOpen | PlainMessage<HomeworkAction_HomeworkOpen> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_HomeworkOpen, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.HomeworkAction.ReviewhomeworkOpen
 */
export class HomeworkAction_ReviewhomeworkOpen extends Message<HomeworkAction_ReviewhomeworkOpen> {
  /**
   * @generated from field: repeated string question_ids = 1;
   */
  questionIds: string[] = [];

  constructor(data?: PartialMessage<HomeworkAction_ReviewhomeworkOpen>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.HomeworkAction.ReviewhomeworkOpen";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_ReviewhomeworkOpen {
    return new HomeworkAction_ReviewhomeworkOpen().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_ReviewhomeworkOpen {
    return new HomeworkAction_ReviewhomeworkOpen().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_ReviewhomeworkOpen {
    return new HomeworkAction_ReviewhomeworkOpen().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_ReviewhomeworkOpen | PlainMessage<HomeworkAction_ReviewhomeworkOpen> | undefined, b: HomeworkAction_ReviewhomeworkOpen | PlainMessage<HomeworkAction_ReviewhomeworkOpen> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_ReviewhomeworkOpen, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.InteractionButton
 */
export class InteractionButton extends Message<InteractionButton> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string context = 2;
   */
  context = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<InteractionButton>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.InteractionButton";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "context", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InteractionButton {
    return new InteractionButton().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InteractionButton {
    return new InteractionButton().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InteractionButton {
    return new InteractionButton().fromJsonString(jsonString, options);
  }

  static equals(a: InteractionButton | PlainMessage<InteractionButton> | undefined, b: InteractionButton | PlainMessage<InteractionButton> | undefined): boolean {
    return proto3.util.equals(InteractionButton, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourceSelectionFrom
 */
export class ResourceSelectionFrom extends Message<ResourceSelectionFrom> {
  /**
   * @generated from oneof geneo.ai.student.events.ResourceSelectionFrom.ResourceSelectionFromType
   */
  ResourceSelectionFromType: {
    /**
     * @generated from field: geneo.ai.student.events.ResourceSelectionFrom.ResourceSelection RESOURCE_SELECTION = 1;
     */
    value: ResourceSelectionFrom_ResourceSelection;
    case: "RESOURCESELECTION";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: geneo.ai.student.events.ResourceSelectionFromTypeEnum type = 9;
   */
  type = ResourceSelectionFromTypeEnum.RESOURCE_SELECTION_FROM_UNDEFINED;

  constructor(data?: PartialMessage<ResourceSelectionFrom>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourceSelectionFrom";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "RESOURCE_SELECTION", kind: "message", T: ResourceSelectionFrom_ResourceSelection, oneof: "ResourceSelectionFromType" },
    { no: 9, name: "type", kind: "enum", T: proto3.getEnumType(ResourceSelectionFromTypeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceSelectionFrom {
    return new ResourceSelectionFrom().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceSelectionFrom {
    return new ResourceSelectionFrom().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceSelectionFrom {
    return new ResourceSelectionFrom().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceSelectionFrom | PlainMessage<ResourceSelectionFrom> | undefined, b: ResourceSelectionFrom | PlainMessage<ResourceSelectionFrom> | undefined): boolean {
    return proto3.util.equals(ResourceSelectionFrom, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourceSelectionFrom.ResourceSelection
 */
export class ResourceSelectionFrom_ResourceSelection extends Message<ResourceSelectionFrom_ResourceSelection> {
  /**
   * @generated from field: optional string recommendation_id = 1;
   */
  recommendationId?: string;

  /**
   * @generated from field: optional int32 rank = 2;
   */
  rank?: number;

  constructor(data?: PartialMessage<ResourceSelectionFrom_ResourceSelection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourceSelectionFrom.ResourceSelection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recommendation_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceSelectionFrom_ResourceSelection {
    return new ResourceSelectionFrom_ResourceSelection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceSelectionFrom_ResourceSelection {
    return new ResourceSelectionFrom_ResourceSelection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceSelectionFrom_ResourceSelection {
    return new ResourceSelectionFrom_ResourceSelection().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceSelectionFrom_ResourceSelection | PlainMessage<ResourceSelectionFrom_ResourceSelection> | undefined, b: ResourceSelectionFrom_ResourceSelection | PlainMessage<ResourceSelectionFrom_ResourceSelection> | undefined): boolean {
    return proto3.util.equals(ResourceSelectionFrom_ResourceSelection, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.BookAction
 */
export class BookAction extends Message<BookAction> {
  /**
   * @generated from oneof geneo.ai.student.events.BookAction.BookActionType
   */
  BookActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.BookAction.BookSummary close = 2;
     */
    value: BookAction_BookSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 book_id = 3;
   */
  bookId = 0;

  /**
   * book session id
   *
   * @generated from field: string action_id = 4;
   */
  actionId = "";

  /**
   * @generated from field: optional int32 school_course_id = 5;
   */
  schoolCourseId?: number;

  constructor(data?: PartialMessage<BookAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.BookAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "BookActionType" },
    { no: 2, name: "close", kind: "message", T: BookAction_BookSummary, oneof: "BookActionType" },
    { no: 3, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "school_course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookAction {
    return new BookAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookAction {
    return new BookAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookAction {
    return new BookAction().fromJsonString(jsonString, options);
  }

  static equals(a: BookAction | PlainMessage<BookAction> | undefined, b: BookAction | PlainMessage<BookAction> | undefined): boolean {
    return proto3.util.equals(BookAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.BookAction.BookSummary
 */
export class BookAction_BookSummary extends Message<BookAction_BookSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<BookAction_BookSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.BookAction.BookSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookAction_BookSummary {
    return new BookAction_BookSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookAction_BookSummary {
    return new BookAction_BookSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookAction_BookSummary {
    return new BookAction_BookSummary().fromJsonString(jsonString, options);
  }

  static equals(a: BookAction_BookSummary | PlainMessage<BookAction_BookSummary> | undefined, b: BookAction_BookSummary | PlainMessage<BookAction_BookSummary> | undefined): boolean {
    return proto3.util.equals(BookAction_BookSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ChapterAction
 */
export class ChapterAction extends Message<ChapterAction> {
  /**
   * @generated from oneof geneo.ai.student.events.ChapterAction.ChapterActionType
   */
  ChapterActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ChapterAction.ChapterSummary close = 2;
     */
    value: ChapterAction_ChapterSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 chapter_id = 3;
   */
  chapterId = 0;

  /**
   * @generated from field: string action_id = 4;
   */
  actionId = "";

  constructor(data?: PartialMessage<ChapterAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ChapterAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ChapterActionType" },
    { no: 2, name: "close", kind: "message", T: ChapterAction_ChapterSummary, oneof: "ChapterActionType" },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterAction {
    return new ChapterAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterAction {
    return new ChapterAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterAction {
    return new ChapterAction().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterAction | PlainMessage<ChapterAction> | undefined, b: ChapterAction | PlainMessage<ChapterAction> | undefined): boolean {
    return proto3.util.equals(ChapterAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ChapterAction.ChapterSummary
 */
export class ChapterAction_ChapterSummary extends Message<ChapterAction_ChapterSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<ChapterAction_ChapterSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ChapterAction.ChapterSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterAction_ChapterSummary {
    return new ChapterAction_ChapterSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterAction_ChapterSummary {
    return new ChapterAction_ChapterSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterAction_ChapterSummary {
    return new ChapterAction_ChapterSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterAction_ChapterSummary | PlainMessage<ChapterAction_ChapterSummary> | undefined, b: ChapterAction_ChapterSummary | PlainMessage<ChapterAction_ChapterSummary> | undefined): boolean {
    return proto3.util.equals(ChapterAction_ChapterSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.TopicAction
 */
export class TopicAction extends Message<TopicAction> {
  /**
   * @generated from oneof geneo.ai.student.events.TopicAction.TopicActionType
   */
  TopicActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.TopicAction.TopicSummary close = 2;
     */
    value: TopicAction_TopicSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 topic_id = 3;
   */
  topicId = 0;

  /**
   * @generated from field: string action_id = 4;
   */
  actionId = "";

  constructor(data?: PartialMessage<TopicAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.TopicAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "TopicActionType" },
    { no: 2, name: "close", kind: "message", T: TopicAction_TopicSummary, oneof: "TopicActionType" },
    { no: 3, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicAction {
    return new TopicAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicAction {
    return new TopicAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicAction {
    return new TopicAction().fromJsonString(jsonString, options);
  }

  static equals(a: TopicAction | PlainMessage<TopicAction> | undefined, b: TopicAction | PlainMessage<TopicAction> | undefined): boolean {
    return proto3.util.equals(TopicAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.TopicAction.TopicSummary
 */
export class TopicAction_TopicSummary extends Message<TopicAction_TopicSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<TopicAction_TopicSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.TopicAction.TopicSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicAction_TopicSummary {
    return new TopicAction_TopicSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicAction_TopicSummary {
    return new TopicAction_TopicSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicAction_TopicSummary {
    return new TopicAction_TopicSummary().fromJsonString(jsonString, options);
  }

  static equals(a: TopicAction_TopicSummary | PlainMessage<TopicAction_TopicSummary> | undefined, b: TopicAction_TopicSummary | PlainMessage<TopicAction_TopicSummary> | undefined): boolean {
    return proto3.util.equals(TopicAction_TopicSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.LessonAction
 */
export class LessonAction extends Message<LessonAction> {
  /**
   * @generated from oneof geneo.ai.student.events.LessonAction.LessonPlanActionType
   */
  LessonPlanActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.LessonAction.LessonPlanSummary close = 2;
     */
    value: LessonAction_LessonPlanSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string lesson_id = 4;
   */
  lessonId = "";

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  /**
   * @generated from field: geneo.ai.student.events.LessonPlanSelectionFromTypeEnum selected_from = 6;
   */
  selectedFrom = LessonPlanSelectionFromTypeEnum.LESSONPLAN_SELECTION_FROM_UNDEFINED;

  constructor(data?: PartialMessage<LessonAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.LessonAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "LessonPlanActionType" },
    { no: 2, name: "close", kind: "message", T: LessonAction_LessonPlanSummary, oneof: "LessonPlanActionType" },
    { no: 4, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "selected_from", kind: "enum", T: proto3.getEnumType(LessonPlanSelectionFromTypeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonAction {
    return new LessonAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonAction {
    return new LessonAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonAction {
    return new LessonAction().fromJsonString(jsonString, options);
  }

  static equals(a: LessonAction | PlainMessage<LessonAction> | undefined, b: LessonAction | PlainMessage<LessonAction> | undefined): boolean {
    return proto3.util.equals(LessonAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.LessonAction.LessonPlanSummary
 */
export class LessonAction_LessonPlanSummary extends Message<LessonAction_LessonPlanSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<LessonAction_LessonPlanSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.LessonAction.LessonPlanSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonAction_LessonPlanSummary {
    return new LessonAction_LessonPlanSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonAction_LessonPlanSummary {
    return new LessonAction_LessonPlanSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonAction_LessonPlanSummary {
    return new LessonAction_LessonPlanSummary().fromJsonString(jsonString, options);
  }

  static equals(a: LessonAction_LessonPlanSummary | PlainMessage<LessonAction_LessonPlanSummary> | undefined, b: LessonAction_LessonPlanSummary | PlainMessage<LessonAction_LessonPlanSummary> | undefined): boolean {
    return proto3.util.equals(LessonAction_LessonPlanSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourceAction
 */
export class ResourceAction extends Message<ResourceAction> {
  /**
   * @generated from oneof geneo.ai.student.events.ResourceAction.ResourceActionType
   */
  ResourceActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourceAction.ResourceSummary close = 2;
     */
    value: ResourceAction_ResourceSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string resource_id = 4;
   */
  resourceId = "";

  /**
   * resource session id
   *
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  /**
   * Type of the resource
   *
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 6;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * Category type of the resource
   *
   * @generated from field: geneo.content.db.ResourceCategoryEnum resource_category = 8;
   */
  resourceCategory = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: geneo.ai.student.events.ResourceSelectionFrom selected_from = 9;
   */
  selectedFrom?: ResourceSelectionFrom;

  constructor(data?: PartialMessage<ResourceAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourceAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ResourceActionType" },
    { no: 2, name: "close", kind: "message", T: ResourceAction_ResourceSummary, oneof: "ResourceActionType" },
    { no: 4, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 8, name: "resource_category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 9, name: "selected_from", kind: "message", T: ResourceSelectionFrom },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceAction {
    return new ResourceAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceAction {
    return new ResourceAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceAction {
    return new ResourceAction().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceAction | PlainMessage<ResourceAction> | undefined, b: ResourceAction | PlainMessage<ResourceAction> | undefined): boolean {
    return proto3.util.equals(ResourceAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourceAction.ResourceSummary
 */
export class ResourceAction_ResourceSummary extends Message<ResourceAction_ResourceSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<ResourceAction_ResourceSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourceAction.ResourceSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceAction_ResourceSummary {
    return new ResourceAction_ResourceSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceAction_ResourceSummary {
    return new ResourceAction_ResourceSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceAction_ResourceSummary {
    return new ResourceAction_ResourceSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceAction_ResourceSummary | PlainMessage<ResourceAction_ResourceSummary> | undefined, b: ResourceAction_ResourceSummary | PlainMessage<ResourceAction_ResourceSummary> | undefined): boolean {
    return proto3.util.equals(ResourceAction_ResourceSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageAction
 */
export class ResourcePageAction extends Message<ResourcePageAction> {
  /**
   * @generated from oneof geneo.ai.student.events.ResourcePageAction.ResourcePageActionType
   */
  ResourcePageActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageAction.ResourcePageSummary close = 2;
     */
    value: ResourcePageAction_ResourcePageSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string resource_id = 3;
   */
  resourceId = "";

  /**
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<ResourcePageAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ResourcePageActionType" },
    { no: 2, name: "close", kind: "message", T: ResourcePageAction_ResourcePageSummary, oneof: "ResourcePageActionType" },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageAction {
    return new ResourcePageAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageAction {
    return new ResourcePageAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageAction {
    return new ResourcePageAction().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageAction | PlainMessage<ResourcePageAction> | undefined, b: ResourcePageAction | PlainMessage<ResourcePageAction> | undefined): boolean {
    return proto3.util.equals(ResourcePageAction, a, b);
  }
}

/**
 * no of questions and questions coveredneed to check how to get content
 *
 * @generated from message geneo.ai.student.events.ResourcePageAction.ResourcePageSummary
 */
export class ResourcePageAction_ResourcePageSummary extends Message<ResourcePageAction_ResourcePageSummary> {
  /**
   * optional int32 no_of_content_accessed = 2;
   * optional int32 no_of_contents = 3;
   * optional int32 no_of_questions_attemted = 4;
   *
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<ResourcePageAction_ResourcePageSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageAction.ResourcePageSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageAction_ResourcePageSummary {
    return new ResourcePageAction_ResourcePageSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageAction_ResourcePageSummary {
    return new ResourcePageAction_ResourcePageSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageAction_ResourcePageSummary {
    return new ResourcePageAction_ResourcePageSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageAction_ResourcePageSummary | PlainMessage<ResourcePageAction_ResourcePageSummary> | undefined, b: ResourcePageAction_ResourcePageSummary | PlainMessage<ResourcePageAction_ResourcePageSummary> | undefined): boolean {
    return proto3.util.equals(ResourcePageAction_ResourcePageSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.QuestionSubmitStatus
 */
export class QuestionSubmitStatus extends Message<QuestionSubmitStatus> {
  constructor(data?: PartialMessage<QuestionSubmitStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.QuestionSubmitStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionSubmitStatus {
    return new QuestionSubmitStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionSubmitStatus {
    return new QuestionSubmitStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionSubmitStatus {
    return new QuestionSubmitStatus().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionSubmitStatus | PlainMessage<QuestionSubmitStatus> | undefined, b: QuestionSubmitStatus | PlainMessage<QuestionSubmitStatus> | undefined): boolean {
    return proto3.util.equals(QuestionSubmitStatus, a, b);
  }
}

/**
 * @generated from enum geneo.ai.student.events.QuestionSubmitStatus.QuestionSubmitStatusEnum
 */
export enum QuestionSubmitStatus_QuestionSubmitStatusEnum {
  /**
   * right
   *
   * @generated from enum value: R = 0;
   */
  R = 0,

  /**
   * wrong
   *
   * @generated from enum value: W = 1;
   */
  W = 1,

  /**
   * unattempted
   *
   * @generated from enum value: U = 2;
   */
  U = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionSubmitStatus_QuestionSubmitStatusEnum)
proto3.util.setEnumType(QuestionSubmitStatus_QuestionSubmitStatusEnum, "geneo.ai.student.events.QuestionSubmitStatus.QuestionSubmitStatusEnum", [
  { no: 0, name: "R" },
  { no: 1, name: "W" },
  { no: 2, name: "U" },
]);

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction
 */
export class ResourcePageContentAction extends Message<ResourcePageContentAction> {
  /**
   * @generated from oneof geneo.ai.student.events.ResourcePageContentAction.ResourceContentType
   */
  ResourceContentType: {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.ImplementedContentModel open = 3;
     */
    value: ResourcePageContentAction_ImplementedContentModel;
    case: "open";
  } | {
    /**
     * ResourcePageContentSummary submit = 5;
     *
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.ResourcePageContentSummary close = 4;
     */
    value: ResourcePageContentAction_ResourcePageContentSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string resource_id = 9;
   */
  resourceId = "";

  /**
   * @generated from field: int32 page_number = 5;
   */
  pageNumber = 0;

  /**
   * string content_id = 6;
   *
   * geneo.content.db.Content.ContentEnum content_type = 8;
   * ContentEnum content_type = 7; // Type of content
   *
   * @generated from field: optional string action_id = 7;
   */
  actionId?: string;

  constructor(data?: PartialMessage<ResourcePageContentAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "open", kind: "message", T: ResourcePageContentAction_ImplementedContentModel, oneof: "ResourceContentType" },
    { no: 4, name: "close", kind: "message", T: ResourcePageContentAction_ResourcePageContentSummary, oneof: "ResourceContentType" },
    { no: 9, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction {
    return new ResourcePageContentAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction {
    return new ResourcePageContentAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction {
    return new ResourcePageContentAction().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction | PlainMessage<ResourcePageContentAction> | undefined, b: ResourcePageContentAction | PlainMessage<ResourcePageContentAction> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.ContentSummary
 */
export class ResourcePageContentAction_ContentSummary extends Message<ResourcePageContentAction_ContentSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  /**
   * @generated from field: optional float playtime = 2;
   */
  playtime?: number;

  /**
   * @generated from field: repeated geneo.ai.student.events.ResourcePageContentAction.ContentSummary.Range ranges = 3;
   */
  ranges: ResourcePageContentAction_ContentSummary_Range[] = [];

  /**
   * @generated from field: string content_id = 4;
   */
  contentId = "";

  /**
   * @generated from field: geneo.content.db.Content.ContentEnum type = 5;
   */
  type = Content_ContentEnum.CONTENT_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ResourcePageContentAction_ContentSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.ContentSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "playtime", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "ranges", kind: "message", T: ResourcePageContentAction_ContentSummary_Range, repeated: true },
    { no: 4, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_ContentSummary {
    return new ResourcePageContentAction_ContentSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ContentSummary {
    return new ResourcePageContentAction_ContentSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ContentSummary {
    return new ResourcePageContentAction_ContentSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_ContentSummary | PlainMessage<ResourcePageContentAction_ContentSummary> | undefined, b: ResourcePageContentAction_ContentSummary | PlainMessage<ResourcePageContentAction_ContentSummary> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_ContentSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.ContentSummary.Range
 */
export class ResourcePageContentAction_ContentSummary_Range extends Message<ResourcePageContentAction_ContentSummary_Range> {
  /**
   * @generated from field: float start_position = 1;
   */
  startPosition = 0;

  /**
   * @generated from field: float end_position = 2;
   */
  endPosition = 0;

  /**
   * @generated from field: float speed = 3;
   */
  speed = 0;

  constructor(data?: PartialMessage<ResourcePageContentAction_ContentSummary_Range>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.ContentSummary.Range";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_position", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "end_position", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "speed", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_ContentSummary_Range {
    return new ResourcePageContentAction_ContentSummary_Range().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ContentSummary_Range {
    return new ResourcePageContentAction_ContentSummary_Range().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ContentSummary_Range {
    return new ResourcePageContentAction_ContentSummary_Range().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_ContentSummary_Range | PlainMessage<ResourcePageContentAction_ContentSummary_Range> | undefined, b: ResourcePageContentAction_ContentSummary_Range | PlainMessage<ResourcePageContentAction_ContentSummary_Range> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_ContentSummary_Range, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.QuestionSummary
 */
export class ResourcePageContentAction_QuestionSummary extends Message<ResourcePageContentAction_QuestionSummary> {
  /**
   * @generated from field: geneo.ai.student.events.QAttemptResultEnum status = 1;
   */
  status = QAttemptResultEnum.RESPONSE_UNDEFINED;

  /**
   * Question.QuestionEnum question_type = 1;
   *
   * @generated from field: repeated string answer = 3;
   */
  answer: string[] = [];

  /**
   * @generated from field: string question_id = 4;
   */
  questionId = "";

  /**
   * @generated from field: int32 timespent = 5;
   */
  timespent = 0;

  /**
   * @generated from field: geneo.content.db.Question.QuestionEnum type = 6;
   */
  type = Question_QuestionEnum.QUESTION_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ResourcePageContentAction_QuestionSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.QuestionSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(QAttemptResultEnum) },
    { no: 3, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "type", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_QuestionSummary {
    return new ResourcePageContentAction_QuestionSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_QuestionSummary {
    return new ResourcePageContentAction_QuestionSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_QuestionSummary {
    return new ResourcePageContentAction_QuestionSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_QuestionSummary | PlainMessage<ResourcePageContentAction_QuestionSummary> | undefined, b: ResourcePageContentAction_QuestionSummary | PlainMessage<ResourcePageContentAction_QuestionSummary> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_QuestionSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.InstructionSummary
 */
export class ResourcePageContentAction_InstructionSummary extends Message<ResourcePageContentAction_InstructionSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  /**
   * @generated from field: string instruction_id = 2;
   */
  instructionId = "";

  constructor(data?: PartialMessage<ResourcePageContentAction_InstructionSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.InstructionSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "instruction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_InstructionSummary {
    return new ResourcePageContentAction_InstructionSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_InstructionSummary {
    return new ResourcePageContentAction_InstructionSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_InstructionSummary {
    return new ResourcePageContentAction_InstructionSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_InstructionSummary | PlainMessage<ResourcePageContentAction_InstructionSummary> | undefined, b: ResourcePageContentAction_InstructionSummary | PlainMessage<ResourcePageContentAction_InstructionSummary> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_InstructionSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.ResourcePageContentSummary
 */
export class ResourcePageContentAction_ResourcePageContentSummary extends Message<ResourcePageContentAction_ResourcePageContentSummary> {
  /**
   * @generated from oneof geneo.ai.student.events.ResourcePageContentAction.ResourcePageContentSummary.ResourcePageSummaryType
   */
  ResourcePageSummaryType: {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.ContentSummary content = 1;
     */
    value: ResourcePageContentAction_ContentSummary;
    case: "content";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.InstructionSummary instruction = 2;
     */
    value: ResourcePageContentAction_InstructionSummary;
    case: "instruction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.QuestionSummary question = 3;
     */
    value: ResourcePageContentAction_QuestionSummary;
    case: "question";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ResourcePageContentAction_ResourcePageContentSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.ResourcePageContentSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: ResourcePageContentAction_ContentSummary, oneof: "ResourcePageSummaryType" },
    { no: 2, name: "instruction", kind: "message", T: ResourcePageContentAction_InstructionSummary, oneof: "ResourcePageSummaryType" },
    { no: 3, name: "question", kind: "message", T: ResourcePageContentAction_QuestionSummary, oneof: "ResourcePageSummaryType" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_ResourcePageContentSummary {
    return new ResourcePageContentAction_ResourcePageContentSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ResourcePageContentSummary {
    return new ResourcePageContentAction_ResourcePageContentSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ResourcePageContentSummary {
    return new ResourcePageContentAction_ResourcePageContentSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_ResourcePageContentSummary | PlainMessage<ResourcePageContentAction_ResourcePageContentSummary> | undefined, b: ResourcePageContentAction_ResourcePageContentSummary | PlainMessage<ResourcePageContentAction_ResourcePageContentSummary> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_ResourcePageContentSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.Content
 */
export class ResourcePageContentAction_Content extends Message<ResourcePageContentAction_Content> {
  /**
   * @generated from field: string content_id = 1;
   */
  contentId = "";

  /**
   * @generated from field: geneo.content.db.Content.ContentEnum type = 2;
   */
  type = Content_ContentEnum.CONTENT_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ResourcePageContentAction_Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_Content {
    return new ResourcePageContentAction_Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_Content {
    return new ResourcePageContentAction_Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_Content {
    return new ResourcePageContentAction_Content().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_Content | PlainMessage<ResourcePageContentAction_Content> | undefined, b: ResourcePageContentAction_Content | PlainMessage<ResourcePageContentAction_Content> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_Content, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.Question
 */
export class ResourcePageContentAction_Question extends Message<ResourcePageContentAction_Question> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from field: geneo.content.db.Question.QuestionEnum type = 2;
   */
  type = Question_QuestionEnum.QUESTION_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ResourcePageContentAction_Question>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.Question";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_Question {
    return new ResourcePageContentAction_Question().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_Question {
    return new ResourcePageContentAction_Question().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_Question {
    return new ResourcePageContentAction_Question().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_Question | PlainMessage<ResourcePageContentAction_Question> | undefined, b: ResourcePageContentAction_Question | PlainMessage<ResourcePageContentAction_Question> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_Question, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.Instruction
 */
export class ResourcePageContentAction_Instruction extends Message<ResourcePageContentAction_Instruction> {
  /**
   * @generated from field: string instruction_id = 1;
   */
  instructionId = "";

  constructor(data?: PartialMessage<ResourcePageContentAction_Instruction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.Instruction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instruction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_Instruction {
    return new ResourcePageContentAction_Instruction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_Instruction {
    return new ResourcePageContentAction_Instruction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_Instruction {
    return new ResourcePageContentAction_Instruction().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_Instruction | PlainMessage<ResourcePageContentAction_Instruction> | undefined, b: ResourcePageContentAction_Instruction | PlainMessage<ResourcePageContentAction_Instruction> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_Instruction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.ResourcePageContentAction.ImplementedContentModel
 */
export class ResourcePageContentAction_ImplementedContentModel extends Message<ResourcePageContentAction_ImplementedContentModel> {
  /**
   * @generated from oneof geneo.ai.student.events.ResourcePageContentAction.ImplementedContentModel.PageContentType
   */
  PageContentType: {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.Content content = 1;
     */
    value: ResourcePageContentAction_Content;
    case: "content";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.Question question = 2;
     */
    value: ResourcePageContentAction_Question;
    case: "question";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction.Instruction instruction = 3;
     */
    value: ResourcePageContentAction_Instruction;
    case: "instruction";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ResourcePageContentAction_ImplementedContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.ResourcePageContentAction.ImplementedContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: ResourcePageContentAction_Content, oneof: "PageContentType" },
    { no: 2, name: "question", kind: "message", T: ResourcePageContentAction_Question, oneof: "PageContentType" },
    { no: 3, name: "instruction", kind: "message", T: ResourcePageContentAction_Instruction, oneof: "PageContentType" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_ImplementedContentModel {
    return new ResourcePageContentAction_ImplementedContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ImplementedContentModel {
    return new ResourcePageContentAction_ImplementedContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ImplementedContentModel {
    return new ResourcePageContentAction_ImplementedContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_ImplementedContentModel | PlainMessage<ResourcePageContentAction_ImplementedContentModel> | undefined, b: ResourcePageContentAction_ImplementedContentModel | PlainMessage<ResourcePageContentAction_ImplementedContentModel> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_ImplementedContentModel, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.RecommendationAction
 */
export class RecommendationAction extends Message<RecommendationAction> {
  /**
   * @generated from oneof geneo.ai.student.events.RecommendationAction.RecommendationActionType
   */
  RecommendationActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.RecommendationAction.RecommendationSummary close = 2;
     */
    value: RecommendationAction_RecommendationSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string recommendation_id = 3;
   */
  recommendationId = "";

  /**
   * @generated from field: string action_id = 4;
   */
  actionId = "";

  constructor(data?: PartialMessage<RecommendationAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.RecommendationAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "RecommendationActionType" },
    { no: 2, name: "close", kind: "message", T: RecommendationAction_RecommendationSummary, oneof: "RecommendationActionType" },
    { no: 3, name: "recommendation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendationAction {
    return new RecommendationAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendationAction {
    return new RecommendationAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendationAction {
    return new RecommendationAction().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendationAction | PlainMessage<RecommendationAction> | undefined, b: RecommendationAction | PlainMessage<RecommendationAction> | undefined): boolean {
    return proto3.util.equals(RecommendationAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.RecommendationAction.RecommendationSummary
 */
export class RecommendationAction_RecommendationSummary extends Message<RecommendationAction_RecommendationSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<RecommendationAction_RecommendationSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.RecommendationAction.RecommendationSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendationAction_RecommendationSummary {
    return new RecommendationAction_RecommendationSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendationAction_RecommendationSummary {
    return new RecommendationAction_RecommendationSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendationAction_RecommendationSummary {
    return new RecommendationAction_RecommendationSummary().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendationAction_RecommendationSummary | PlainMessage<RecommendationAction_RecommendationSummary> | undefined, b: RecommendationAction_RecommendationSummary | PlainMessage<RecommendationAction_RecommendationSummary> | undefined): boolean {
    return proto3.util.equals(RecommendationAction_RecommendationSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.PerformanceAction
 */
export class PerformanceAction extends Message<PerformanceAction> {
  /**
   * @generated from oneof geneo.ai.student.events.PerformanceAction.PerformanceActionType
   */
  PerformanceActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.PerformanceAction.PerformanceASummary close = 2;
     */
    value: PerformanceAction_PerformanceASummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string performance_id = 4;
   */
  performanceId = "";

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<PerformanceAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.PerformanceAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "PerformanceActionType" },
    { no: 2, name: "close", kind: "message", T: PerformanceAction_PerformanceASummary, oneof: "PerformanceActionType" },
    { no: 4, name: "performance_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceAction {
    return new PerformanceAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceAction {
    return new PerformanceAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceAction {
    return new PerformanceAction().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceAction | PlainMessage<PerformanceAction> | undefined, b: PerformanceAction | PlainMessage<PerformanceAction> | undefined): boolean {
    return proto3.util.equals(PerformanceAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.PerformanceAction.PerformanceASummary
 */
export class PerformanceAction_PerformanceASummary extends Message<PerformanceAction_PerformanceASummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<PerformanceAction_PerformanceASummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.PerformanceAction.PerformanceASummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceAction_PerformanceASummary {
    return new PerformanceAction_PerformanceASummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceAction_PerformanceASummary {
    return new PerformanceAction_PerformanceASummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceAction_PerformanceASummary {
    return new PerformanceAction_PerformanceASummary().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceAction_PerformanceASummary | PlainMessage<PerformanceAction_PerformanceASummary> | undefined, b: PerformanceAction_PerformanceASummary | PlainMessage<PerformanceAction_PerformanceASummary> | undefined): boolean {
    return proto3.util.equals(PerformanceAction_PerformanceASummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.Login
 */
export class Login extends Message<Login> {
  /**
   * Class ID
   *
   * @generated from field: optional int32 class_id = 1;
   */
  classId?: number;

  /**
   * Section ID
   *
   * @generated from field: optional int32 section_id = 2;
   */
  sectionId?: number;

  /**
   * School ID
   *
   * @generated from field: optional int32 school_id = 3;
   */
  schoolId?: number;

  /**
   * Board ID
   *
   * @generated from field: optional int32 board_id = 4;
   */
  boardId?: number;

  /**
   * Region ID
   *
   * @generated from field: optional int32 region_id = 5;
   */
  regionId?: number;

  /**
   * Medium ID
   *
   * @generated from field: optional int32 medium_id = 6;
   */
  mediumId?: number;

  constructor(data?: PartialMessage<Login>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.Login";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Login {
    return new Login().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Login {
    return new Login().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Login {
    return new Login().fromJsonString(jsonString, options);
  }

  static equals(a: Login | PlainMessage<Login> | undefined, b: Login | PlainMessage<Login> | undefined): boolean {
    return proto3.util.equals(Login, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.Logout
 */
export class Logout extends Message<Logout> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  /**
   * @generated from field: geneo.ai.student.events.Logout.LogoutType.LogoutTypeEnum reason = 2;
   */
  reason = Logout_LogoutType_LogoutTypeEnum.MANUAL;

  constructor(data?: PartialMessage<Logout>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.Logout";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "reason", kind: "enum", T: proto3.getEnumType(Logout_LogoutType_LogoutTypeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Logout {
    return new Logout().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Logout {
    return new Logout().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Logout {
    return new Logout().fromJsonString(jsonString, options);
  }

  static equals(a: Logout | PlainMessage<Logout> | undefined, b: Logout | PlainMessage<Logout> | undefined): boolean {
    return proto3.util.equals(Logout, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.Logout.LogoutType
 */
export class Logout_LogoutType extends Message<Logout_LogoutType> {
  constructor(data?: PartialMessage<Logout_LogoutType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.Logout.LogoutType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Logout_LogoutType {
    return new Logout_LogoutType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Logout_LogoutType {
    return new Logout_LogoutType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Logout_LogoutType {
    return new Logout_LogoutType().fromJsonString(jsonString, options);
  }

  static equals(a: Logout_LogoutType | PlainMessage<Logout_LogoutType> | undefined, b: Logout_LogoutType | PlainMessage<Logout_LogoutType> | undefined): boolean {
    return proto3.util.equals(Logout_LogoutType, a, b);
  }
}

/**
 * @generated from enum geneo.ai.student.events.Logout.LogoutType.LogoutTypeEnum
 */
export enum Logout_LogoutType_LogoutTypeEnum {
  /**
   * @generated from enum value: MANUAL = 0;
   */
  MANUAL = 0,

  /**
   * @generated from enum value: DUE_TO_INACTIVITY = 1;
   */
  DUE_TO_INACTIVITY = 1,

  /**
   * @generated from enum value: KILLED_SESSION = 2;
   */
  KILLED_SESSION = 2,

  /**
   * @generated from enum value: AUTO_LOGOUT = 3;
   */
  AUTO_LOGOUT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Logout_LogoutType_LogoutTypeEnum)
proto3.util.setEnumType(Logout_LogoutType_LogoutTypeEnum, "geneo.ai.student.events.Logout.LogoutType.LogoutTypeEnum", [
  { no: 0, name: "MANUAL" },
  { no: 1, name: "DUE_TO_INACTIVITY" },
  { no: 2, name: "KILLED_SESSION" },
  { no: 3, name: "AUTO_LOGOUT" },
]);

/**
 * @generated from message geneo.ai.student.events.StudentEvent
 */
export class StudentEvent extends Message<StudentEvent> {
  /**
   * @generated from oneof geneo.ai.student.events.StudentEvent.EventType
   */
  EventType: {
    /**
     * @generated from field: geneo.ai.student.events.Login login = 1;
     */
    value: Login;
    case: "login";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.BookAction book_action = 2;
     */
    value: BookAction;
    case: "bookAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.HomeworkAction homework_action = 3;
     */
    value: HomeworkAction;
    case: "homeworkAction";
  } | {
    /**
     * VideoAction video_action = 3;
     *
     * @generated from field: geneo.ai.student.events.TopicAction topic_action = 4;
     */
    value: TopicAction;
    case: "topicAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.LessonAction lesson_action = 5;
     */
    value: LessonAction;
    case: "lessonAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourceAction resource_action = 7;
     */
    value: ResourceAction;
    case: "resourceAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageContentAction resource_page_content_action = 8;
     */
    value: ResourcePageContentAction;
    case: "resourcePageContentAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ChapterAction chapter_action = 10;
     */
    value: ChapterAction;
    case: "chapterAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.PerformanceAction performance_action = 11;
     */
    value: PerformanceAction;
    case: "performanceAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.RecommendationAction recommendation_action = 13;
     */
    value: RecommendationAction;
    case: "recommendationAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.ResourcePageAction resource_page_action = 14;
     */
    value: ResourcePageAction;
    case: "resourcePageAction";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.InteractionButton interaction_button = 35;
     */
    value: InteractionButton;
    case: "interactionButton";
  } | {
    /**
     * @generated from field: geneo.ai.student.events.Logout logout = 36;
     */
    value: Logout;
    case: "logout";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int64 student_id = 102;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string session_id = 103;
   */
  sessionId = "";

  /**
   * optional bool is_offline = 70;
   *
   * @generated from field: google.protobuf.Timestamp timestamp = 104;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: optional string book_session_id = 105;
   */
  bookSessionId?: string;

  /**
   * @generated from field: optional string chapter_session_id = 106;
   */
  chapterSessionId?: string;

  /**
   * @generated from field: optional string active_sesssion_id = 107;
   */
  activeSesssionId?: string;

  /**
   * @generated from field: optional string login_sesssion_id = 108;
   */
  loginSesssionId?: string;

  /**
   * @generated from field: optional string homework_sesssion_id = 109;
   */
  homeworkSesssionId?: string;

  /**
   * @generated from field: optional string lesson_sesssion_id = 110;
   */
  lessonSesssionId?: string;

  /**
   * @generated from field: optional int32 resource_lesson_sesssion_id = 111;
   */
  resourceLessonSesssionId?: number;

  /**
   * @generated from field: optional string resource_sesssion_id = 112;
   */
  resourceSesssionId?: string;

  /**
   * event number starts from 0 for each session
   *
   * @generated from field: int32 event_index = 113;
   */
  eventIndex = 0;

  /**
   * will be stored from action id
   *
   * @generated from field: optional string action_id = 114;
   */
  actionId?: string;

  /**
   * @generated from field: optional string event_type = 115;
   */
  eventType?: string;

  /**
   * @generated from field: optional string action_type = 116;
   */
  actionType?: string;

  /**
   * Creation timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp created_on = 117;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 118;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 119;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 120;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional bool is_offline = 121;
   */
  isOffline?: boolean;

  /**
   * @generated from field: optional google.protobuf.Timestamp server_timestamp = 122;
   */
  serverTimestamp?: Timestamp;

  constructor(data?: PartialMessage<StudentEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.StudentEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "login", kind: "message", T: Login, oneof: "EventType" },
    { no: 2, name: "book_action", kind: "message", T: BookAction, oneof: "EventType" },
    { no: 3, name: "homework_action", kind: "message", T: HomeworkAction, oneof: "EventType" },
    { no: 4, name: "topic_action", kind: "message", T: TopicAction, oneof: "EventType" },
    { no: 5, name: "lesson_action", kind: "message", T: LessonAction, oneof: "EventType" },
    { no: 7, name: "resource_action", kind: "message", T: ResourceAction, oneof: "EventType" },
    { no: 8, name: "resource_page_content_action", kind: "message", T: ResourcePageContentAction, oneof: "EventType" },
    { no: 10, name: "chapter_action", kind: "message", T: ChapterAction, oneof: "EventType" },
    { no: 11, name: "performance_action", kind: "message", T: PerformanceAction, oneof: "EventType" },
    { no: 13, name: "recommendation_action", kind: "message", T: RecommendationAction, oneof: "EventType" },
    { no: 14, name: "resource_page_action", kind: "message", T: ResourcePageAction, oneof: "EventType" },
    { no: 35, name: "interaction_button", kind: "message", T: InteractionButton, oneof: "EventType" },
    { no: 36, name: "logout", kind: "message", T: Logout, oneof: "EventType" },
    { no: 102, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 103, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 104, name: "timestamp", kind: "message", T: Timestamp },
    { no: 105, name: "book_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 106, name: "chapter_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 107, name: "active_sesssion_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 108, name: "login_sesssion_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 109, name: "homework_sesssion_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 110, name: "lesson_sesssion_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 111, name: "resource_lesson_sesssion_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 112, name: "resource_sesssion_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 113, name: "event_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 114, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 115, name: "event_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 116, name: "action_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 117, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 118, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 119, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 120, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 121, name: "is_offline", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 122, name: "server_timestamp", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentEvent {
    return new StudentEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentEvent {
    return new StudentEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentEvent {
    return new StudentEvent().fromJsonString(jsonString, options);
  }

  static equals(a: StudentEvent | PlainMessage<StudentEvent> | undefined, b: StudentEvent | PlainMessage<StudentEvent> | undefined): boolean {
    return proto3.util.equals(StudentEvent, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.StudentEventResponse
 */
export class StudentEventResponse extends Message<StudentEventResponse> {
  /**
   * @generated from field: bool is_successful = 1;
   */
  isSuccessful = false;

  /**
   * @generated from field: repeated string error_messages = 2;
   */
  errorMessages: string[] = [];

  /**
   * @generated from field: optional string stack_trace = 3;
   */
  stackTrace?: string;

  /**
   * @generated from field: optional string response_type = 5;
   */
  responseType?: string;

  /**
   * @generated from field: optional int64 student_id = 6;
   */
  studentId?: bigint;

  /**
   * @generated from field: optional string session_id = 7;
   */
  sessionId?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp timestamp = 8;
   */
  timestamp?: Timestamp;

  constructor(data?: PartialMessage<StudentEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.StudentEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_successful", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "error_messages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "stack_trace", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "response_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "timestamp", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentEventResponse {
    return new StudentEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentEventResponse {
    return new StudentEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentEventResponse {
    return new StudentEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentEventResponse | PlainMessage<StudentEventResponse> | undefined, b: StudentEventResponse | PlainMessage<StudentEventResponse> | undefined): boolean {
    return proto3.util.equals(StudentEventResponse, a, b);
  }
}

