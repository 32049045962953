import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  ChapterResources,
  ChapterTopicInfo,
  ChapterTopicMiniInfo,
  FetchSubjectChapterInfo,
  ResourceContent as RawResourceContent,
  TopicResourceInfo,
} from '@protos/content_management/content.common.apis_pb';
import {
  ResourceCategoryEnum,
  ResourceContent,
} from '@protos/content_management/content.db_pb';
import {
  SessionModeEnum,
  VisitedResourceContentInfoList,
} from '@protos/learning_management/lms.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  LessonContent,
  LessonInfo,
  LessonsByModule,
  TeacherLessonInfo,
} from '@protos/learning_management/lms.lesson.common.apis_pb';
import { CustomUploadFileResponse } from '../../Homework/reducer/homework.slice';
import { UpsertTeacherContentRequest } from '@protos/content_management/cms.teacher.content.apis_pb';

export interface ITeachState {
  subject_chapter_info?: FetchSubjectChapterInfo;
  subject_topic_info?: ChapterTopicInfo;
  chapter_resources?: ChapterResources;
  selected_topic_info?: ChapterTopicMiniInfo;
  topic_resources?: TopicResourceInfo;
  lessons_by_module?: LessonsByModule;
  lessons_content?: LessonContent;
  selected_lessons_info?: LessonInfo | TeacherLessonInfo;
  selected_resource_content?: {
    rawData: RawResourceContent;
    parsedData: ResourceContent;
  };
  selected_resource_session_id?: number;
  lesson_plan_redirection_path?: string;
  lesson_session_visited_resource_info?: {
    [lessonSessionId: string]: VisitedResourceContentInfoList[];
  };
  resource_redirection_path?: string;
  session_mode_teaching_flow: SessionModeEnum | undefined;
  custom_resource_data?: {
    resourceId?: string;
    resourceCategory?: ResourceCategoryEnum;
    selected_chapter: { chapter_name: string; chapter_id: number };
    selectedTopic?: { topicName: string; topicId: number };
    resourceTitle: string;
    resourceTime: string;
    contentLock: boolean;
    custom_resource_cover_image?: CustomUploadFileResponse;
    custom_resource_uploaded_files: CustomUploadFileResponse[];
  };
}

const initialState: ITeachState = {
  subject_chapter_info: undefined,
  subject_topic_info: undefined,
  chapter_resources: undefined,
  selected_topic_info: undefined,
  topic_resources: undefined,
  lessons_by_module: undefined,
  lessons_content: undefined,
  selected_lessons_info: undefined,
  selected_resource_content: undefined,
  selected_resource_session_id: undefined,
  lesson_plan_redirection_path: undefined,
  resource_redirection_path: undefined,
  session_mode_teaching_flow: undefined,
  custom_resource_data: undefined,
};

export const teachSlice = createSlice({
  name: 'teach',
  initialState,
  reducers: {
    setChapterSubjectInfo: (state, action) => {
      // console.log('payload', action.payload);
      state.subject_chapter_info = action.payload;
    },

    setChapterTopicInfo: (state, action) => {
      // console.log('payload', action.payload);
      state.subject_topic_info = action.payload;
    },

    setChapterResources: (state, action) => {
      // console.log('payload', action.payload);
      state.chapter_resources = action.payload;
    },

    setSelectedTopic: (state, action) => {
      // console.log('payload', action.payload);
      state.selected_topic_info = action.payload;
    },

    setTopicResources: (state, action) => {
      // console.log('payload', action.payload);
      state.topic_resources = action.payload;
    },

    setLessonsByModule: (state, action) => {
      // console.log('payload', action.payload);
      state.lessons_by_module = action.payload;
    },

    setLessonContent: (state, action) => {
      // console.log('payload', action.payload);
      state.lessons_content = action.payload;
    },

    setSelectedLessonInfo: (state, action) => {
      // console.log('payload', action.payload);
      state.selected_lessons_info = action.payload;
    },

    setSelectedResourceContent: (state, action) => {
      // console.log('payload', action.payload);
      state.selected_resource_content = action.payload;
    },

    setSelectedResourceSessionId: (state, action) => {
      // console.log('payload', action.payload);
      state.selected_resource_session_id = action.payload;
    },
    setLessonPlanRedirectionPath: (state, action) => {
      // console.log('payload', action.payload);
      state.lesson_plan_redirection_path = action.payload;
    },
    setUpdatedLessonSessionVisitedResourceInfo: (
      state,
      action: PayloadAction<{
        [lessonSessionId: string]: VisitedResourceContentInfoList[];
      }>
    ) => {
      state.lesson_session_visited_resource_info = action.payload;
    },
    setResourceRedirectionPath: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.resource_redirection_path = action.payload;
    },
    setSessionMode: (
      state,
      action: PayloadAction<SessionModeEnum | undefined>
    ) => {
      state.session_mode_teaching_flow = action.payload;
    },
    setCustomResourceData: (state, action) => {
      state.custom_resource_data = action.payload;
    },
    resetTeachState: (state) => {
      return initialState;
    },
  },
});

export const {
  setChapterSubjectInfo,
  setChapterTopicInfo,
  setChapterResources,
  setSelectedTopic,
  setTopicResources,
  setLessonsByModule,
  setLessonContent,
  setSelectedLessonInfo,
  resetTeachState,
  setSelectedResourceContent,
  setSelectedResourceSessionId,
  setLessonPlanRedirectionPath,
  setUpdatedLessonSessionVisitedResourceInfo,
  setResourceRedirectionPath,
  setSessionMode,
  setCustomResourceData,
} = teachSlice.actions;
export default teachSlice.reducer;
