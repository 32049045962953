// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/content_management/content.common.apis.proto (package geneo.content.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";
import { CloudUpload } from "@protos/learning_management/lms.db_pb";
import { ChapterMetaInfo, Content_ContentEnum, Module_ModuleCategoryEnum, Question, Resource_ResourceEnum, ResourceCategoryEnum, SchoolContentInfo } from "@protos/content_management/content.db_pb";
import { APIResponse_CodeEnum, APIResponse_StatusEnum } from "@protos/common/common.db_pb";

/**
 * we will soft delete
 *
 * @generated from message geneo.content.common.apis.FetchResourceEditDetailsRequest
 */
export class FetchResourceEditDetailsRequest extends Message<FetchResourceEditDetailsRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: string resource_id = 3;
   */
  resourceId = "";

  constructor(data?: PartialMessage<FetchResourceEditDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchResourceEditDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchResourceEditDetailsRequest {
    return new FetchResourceEditDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsRequest {
    return new FetchResourceEditDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsRequest {
    return new FetchResourceEditDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchResourceEditDetailsRequest | PlainMessage<FetchResourceEditDetailsRequest> | undefined, b: FetchResourceEditDetailsRequest | PlainMessage<FetchResourceEditDetailsRequest> | undefined): boolean {
    return proto3.util.equals(FetchResourceEditDetailsRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchResourceEditDetailsResponse
 */
export class FetchResourceEditDetailsResponse extends Message<FetchResourceEditDetailsResponse> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: string cover_title = 2;
   */
  coverTitle = "";

  /**
   * should be cloud upload
   *
   * @generated from field: optional geneo.lms.db.CloudUpload cover_image_info = 3;
   */
  coverImageInfo?: CloudUpload;

  /**
   * @generated from field: int32 estimated_time_in_min = 4;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: geneo.content.db.ResourceCategoryEnum resource_category = 5;
   */
  resourceCategory = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * need to discuss
   *
   * @generated from field: optional geneo.content.db.Content.ContentEnum content_type = 6;
   */
  contentType?: Content_ContentEnum;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload files_info = 7;
   */
  filesInfo: CloudUpload[] = [];

  constructor(data?: PartialMessage<FetchResourceEditDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchResourceEditDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cover_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cover_image_info", kind: "message", T: CloudUpload, opt: true },
    { no: 4, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "resource_category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 6, name: "content_type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum), opt: true },
    { no: 7, name: "files_info", kind: "message", T: CloudUpload, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchResourceEditDetailsResponse {
    return new FetchResourceEditDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsResponse {
    return new FetchResourceEditDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsResponse {
    return new FetchResourceEditDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchResourceEditDetailsResponse | PlainMessage<FetchResourceEditDetailsResponse> | undefined, b: FetchResourceEditDetailsResponse | PlainMessage<FetchResourceEditDetailsResponse> | undefined): boolean {
    return proto3.util.equals(FetchResourceEditDetailsResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.GetDownloadInfoRequest
 */
export class GetDownloadInfoRequest extends Message<GetDownloadInfoRequest> {
  /**
   * @generated from field: optional int32 chapter_id = 1;
   */
  chapterId?: number;

  /**
   * @generated from field: optional int32 topic_id = 2;
   */
  topicId?: number;

  /**
   * @generated from field: optional string lessonplan_id = 3;
   */
  lessonplanId?: string;

  constructor(data?: PartialMessage<GetDownloadInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.GetDownloadInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "lessonplan_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDownloadInfoRequest {
    return new GetDownloadInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDownloadInfoRequest {
    return new GetDownloadInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDownloadInfoRequest {
    return new GetDownloadInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDownloadInfoRequest | PlainMessage<GetDownloadInfoRequest> | undefined, b: GetDownloadInfoRequest | PlainMessage<GetDownloadInfoRequest> | undefined): boolean {
    return proto3.util.equals(GetDownloadInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.DownloadInfoResponse
 */
export class DownloadInfoResponse extends Message<DownloadInfoResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.db.ChapterMetaInfo data = 4;
   */
  data?: ChapterMetaInfo;

  constructor(data?: PartialMessage<DownloadInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.DownloadInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ChapterMetaInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadInfoResponse {
    return new DownloadInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadInfoResponse {
    return new DownloadInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadInfoResponse {
    return new DownloadInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadInfoResponse | PlainMessage<DownloadInfoResponse> | undefined, b: DownloadInfoResponse | PlainMessage<DownloadInfoResponse> | undefined): boolean {
    return proto3.util.equals(DownloadInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.SubjectChapterMiniInfo
 */
export class SubjectChapterMiniInfo extends Message<SubjectChapterMiniInfo> {
  /**
   * @generated from field: int32 chapter_id = 1;
   */
  chapterId = 0;

  /**
   * int32 course_id = 2; // fetch bookid based on course id which will be in req body
   *
   * module rank
   *
   * @generated from field: int32 chapter_no = 3;
   */
  chapterNo = 0;

  /**
   * module name
   *
   * @generated from field: string chapter_title = 4;
   */
  chapterTitle = "";

  /**
   * module poster_image_url
   *
   * @generated from field: string poster_images_url = 5;
   */
  posterImagesUrl = "";

  /**
   * fetch count of topics based on parent_module_id,MODULE_CATEGORY_TOPIC,book_id
   *
   * @generated from field: int32 no_of_topics = 6;
   */
  noOfTopics = 0;

  /**
   * @generated from field: repeated geneo.content.common.apis.TopicBasicInfo topics = 7;
   */
  topics: TopicBasicInfo[] = [];

  constructor(data?: PartialMessage<SubjectChapterMiniInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.SubjectChapterMiniInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "chapter_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "poster_images_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "no_of_topics", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "topics", kind: "message", T: TopicBasicInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectChapterMiniInfo {
    return new SubjectChapterMiniInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectChapterMiniInfo {
    return new SubjectChapterMiniInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectChapterMiniInfo {
    return new SubjectChapterMiniInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectChapterMiniInfo | PlainMessage<SubjectChapterMiniInfo> | undefined, b: SubjectChapterMiniInfo | PlainMessage<SubjectChapterMiniInfo> | undefined): boolean {
    return proto3.util.equals(SubjectChapterMiniInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.TopicBasicInfo
 */
export class TopicBasicInfo extends Message<TopicBasicInfo> {
  /**
   * @generated from field: int32 topic_id = 1;
   */
  topicId = 0;

  /**
   * module rank
   *
   * @generated from field: int32 topic_no = 2;
   */
  topicNo = 0;

  /**
   * module name
   *
   * @generated from field: string topic_title = 3;
   */
  topicTitle = "";

  /**
   * module poster_image_url
   *
   * @generated from field: string poster_images_url = 4;
   */
  posterImagesUrl = "";

  constructor(data?: PartialMessage<TopicBasicInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.TopicBasicInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "topic_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "topic_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "poster_images_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicBasicInfo {
    return new TopicBasicInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicBasicInfo {
    return new TopicBasicInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicBasicInfo {
    return new TopicBasicInfo().fromJsonString(jsonString, options);
  }

  static equals(a: TopicBasicInfo | PlainMessage<TopicBasicInfo> | undefined, b: TopicBasicInfo | PlainMessage<TopicBasicInfo> | undefined): boolean {
    return proto3.util.equals(TopicBasicInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.TopicContentStats
 */
export class TopicContentStats extends Message<TopicContentStats> {
  /**
   * @generated from field: optional int32 lesson_count = 1;
   */
  lessonCount?: number;

  /**
   * @generated from field: optional int32 homework_count = 2;
   */
  homeworkCount?: number;

  /**
   * @generated from field: int32 question_count = 3;
   */
  questionCount = 0;

  constructor(data?: PartialMessage<TopicContentStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.TopicContentStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "homework_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "question_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicContentStats {
    return new TopicContentStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicContentStats {
    return new TopicContentStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicContentStats {
    return new TopicContentStats().fromJsonString(jsonString, options);
  }

  static equals(a: TopicContentStats | PlainMessage<TopicContentStats> | undefined, b: TopicContentStats | PlainMessage<TopicContentStats> | undefined): boolean {
    return proto3.util.equals(TopicContentStats, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.ChapterTopicMiniInfo
 */
export class ChapterTopicMiniInfo extends Message<ChapterTopicMiniInfo> {
  /**
   * @generated from field: int32 topic_id = 1;
   */
  topicId = 0;

  /**
   * @generated from field: int32 topic_no = 2;
   */
  topicNo = 0;

  /**
   * @generated from field: string topic_title = 3;
   */
  topicTitle = "";

  /**
   * @generated from field: geneo.content.common.apis.TopicContentStats topic_content_stats = 4;
   */
  topicContentStats?: TopicContentStats;

  /**
   * module poster_image_url
   *
   * @generated from field: string poster_images_url = 5;
   */
  posterImagesUrl = "";

  constructor(data?: PartialMessage<ChapterTopicMiniInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.ChapterTopicMiniInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "topic_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "topic_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "topic_content_stats", kind: "message", T: TopicContentStats },
    { no: 5, name: "poster_images_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterTopicMiniInfo {
    return new ChapterTopicMiniInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterTopicMiniInfo {
    return new ChapterTopicMiniInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterTopicMiniInfo {
    return new ChapterTopicMiniInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterTopicMiniInfo | PlainMessage<ChapterTopicMiniInfo> | undefined, b: ChapterTopicMiniInfo | PlainMessage<ChapterTopicMiniInfo> | undefined): boolean {
    return proto3.util.equals(ChapterTopicMiniInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchSubjectChapterInfoRequest
 */
export class FetchSubjectChapterInfoRequest extends Message<FetchSubjectChapterInfoRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 book_id = 4;
   */
  bookId = 0;

  /**
   * @generated from field: int32 section_id = 5;
   */
  sectionId = 0;

  constructor(data?: PartialMessage<FetchSubjectChapterInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchSubjectChapterInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSubjectChapterInfoRequest {
    return new FetchSubjectChapterInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSubjectChapterInfoRequest {
    return new FetchSubjectChapterInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSubjectChapterInfoRequest {
    return new FetchSubjectChapterInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSubjectChapterInfoRequest | PlainMessage<FetchSubjectChapterInfoRequest> | undefined, b: FetchSubjectChapterInfoRequest | PlainMessage<FetchSubjectChapterInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchSubjectChapterInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchCourseIndexInfoRequest
 */
export class FetchCourseIndexInfoRequest extends Message<FetchCourseIndexInfoRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  constructor(data?: PartialMessage<FetchCourseIndexInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchCourseIndexInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCourseIndexInfoRequest {
    return new FetchCourseIndexInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCourseIndexInfoRequest {
    return new FetchCourseIndexInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCourseIndexInfoRequest {
    return new FetchCourseIndexInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCourseIndexInfoRequest | PlainMessage<FetchCourseIndexInfoRequest> | undefined, b: FetchCourseIndexInfoRequest | PlainMessage<FetchCourseIndexInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchCourseIndexInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchCourseIndexInfoResponse
 */
export class FetchCourseIndexInfoResponse extends Message<FetchCourseIndexInfoResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.CourseIndexInfo data = 4;
   */
  data?: CourseIndexInfo;

  constructor(data?: PartialMessage<FetchCourseIndexInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchCourseIndexInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: CourseIndexInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCourseIndexInfoResponse {
    return new FetchCourseIndexInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCourseIndexInfoResponse {
    return new FetchCourseIndexInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCourseIndexInfoResponse {
    return new FetchCourseIndexInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCourseIndexInfoResponse | PlainMessage<FetchCourseIndexInfoResponse> | undefined, b: FetchCourseIndexInfoResponse | PlainMessage<FetchCourseIndexInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchCourseIndexInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.CourseIndexInfo
 */
export class CourseIndexInfo extends Message<CourseIndexInfo> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject_name = 2;
   */
  subjectName = "";

  /**
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * @generated from field: repeated geneo.content.common.apis.SubjectChapterMiniInfo chapters = 4;
   */
  chapters: SubjectChapterMiniInfo[] = [];

  constructor(data?: PartialMessage<CourseIndexInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.CourseIndexInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "chapters", kind: "message", T: SubjectChapterMiniInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CourseIndexInfo {
    return new CourseIndexInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CourseIndexInfo {
    return new CourseIndexInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CourseIndexInfo {
    return new CourseIndexInfo().fromJsonString(jsonString, options);
  }

  static equals(a: CourseIndexInfo | PlainMessage<CourseIndexInfo> | undefined, b: CourseIndexInfo | PlainMessage<CourseIndexInfo> | undefined): boolean {
    return proto3.util.equals(CourseIndexInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchSubjectChapterInfoResponse
 */
export class FetchSubjectChapterInfoResponse extends Message<FetchSubjectChapterInfoResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.FetchSubjectChapterInfo data = 4;
   */
  data?: FetchSubjectChapterInfo;

  constructor(data?: PartialMessage<FetchSubjectChapterInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchSubjectChapterInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: FetchSubjectChapterInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSubjectChapterInfoResponse {
    return new FetchSubjectChapterInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSubjectChapterInfoResponse {
    return new FetchSubjectChapterInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSubjectChapterInfoResponse {
    return new FetchSubjectChapterInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSubjectChapterInfoResponse | PlainMessage<FetchSubjectChapterInfoResponse> | undefined, b: FetchSubjectChapterInfoResponse | PlainMessage<FetchSubjectChapterInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchSubjectChapterInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchSubjectChapterInfo
 */
export class FetchSubjectChapterInfo extends Message<FetchSubjectChapterInfo> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject_book_image_url = 2;
   */
  subjectBookImageUrl = "";

  /**
   * @generated from field: repeated geneo.content.common.apis.SubjectChapterMiniInfo response = 3;
   */
  response: SubjectChapterMiniInfo[] = [];

  /**
   * @generated from field: int32 ongoing_chapter_id = 4;
   */
  ongoingChapterId = 0;

  constructor(data?: PartialMessage<FetchSubjectChapterInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchSubjectChapterInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "subject_book_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "response", kind: "message", T: SubjectChapterMiniInfo, repeated: true },
    { no: 4, name: "ongoing_chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSubjectChapterInfo {
    return new FetchSubjectChapterInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSubjectChapterInfo {
    return new FetchSubjectChapterInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSubjectChapterInfo {
    return new FetchSubjectChapterInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSubjectChapterInfo | PlainMessage<FetchSubjectChapterInfo> | undefined, b: FetchSubjectChapterInfo | PlainMessage<FetchSubjectChapterInfo> | undefined): boolean {
    return proto3.util.equals(FetchSubjectChapterInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchChapterTopicInfoRequest
 */
export class FetchChapterTopicInfoRequest extends Message<FetchChapterTopicInfoRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 4;
   */
  chapterId = 0;

  /**
   * @generated from field: int32 section_id = 5;
   */
  sectionId = 0;

  /**
   * @generated from field: optional bool is_homework = 6;
   */
  isHomework?: boolean;

  constructor(data?: PartialMessage<FetchChapterTopicInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchChapterTopicInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "is_homework", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchChapterTopicInfoRequest {
    return new FetchChapterTopicInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchChapterTopicInfoRequest {
    return new FetchChapterTopicInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchChapterTopicInfoRequest {
    return new FetchChapterTopicInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchChapterTopicInfoRequest | PlainMessage<FetchChapterTopicInfoRequest> | undefined, b: FetchChapterTopicInfoRequest | PlainMessage<FetchChapterTopicInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchChapterTopicInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchChapterTopicInfoResponse
 */
export class FetchChapterTopicInfoResponse extends Message<FetchChapterTopicInfoResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.ChapterTopicInfo data = 4;
   */
  data?: ChapterTopicInfo;

  constructor(data?: PartialMessage<FetchChapterTopicInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchChapterTopicInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ChapterTopicInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchChapterTopicInfoResponse {
    return new FetchChapterTopicInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchChapterTopicInfoResponse {
    return new FetchChapterTopicInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchChapterTopicInfoResponse {
    return new FetchChapterTopicInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchChapterTopicInfoResponse | PlainMessage<FetchChapterTopicInfoResponse> | undefined, b: FetchChapterTopicInfoResponse | PlainMessage<FetchChapterTopicInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchChapterTopicInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.ChapterTopicInfo
 */
export class ChapterTopicInfo extends Message<ChapterTopicInfo> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: geneo.content.common.apis.SubjectChapterMiniInfo chapterInfo = 5;
   */
  chapterInfo?: SubjectChapterMiniInfo;

  /**
   * @generated from field: repeated geneo.content.common.apis.ChapterTopicMiniInfo chapter_topics = 3;
   */
  chapterTopics: ChapterTopicMiniInfo[] = [];

  constructor(data?: PartialMessage<ChapterTopicInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.ChapterTopicInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "chapterInfo", kind: "message", T: SubjectChapterMiniInfo },
    { no: 3, name: "chapter_topics", kind: "message", T: ChapterTopicMiniInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterTopicInfo {
    return new ChapterTopicInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterTopicInfo {
    return new ChapterTopicInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterTopicInfo {
    return new ChapterTopicInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterTopicInfo | PlainMessage<ChapterTopicInfo> | undefined, b: ChapterTopicInfo | PlainMessage<ChapterTopicInfo> | undefined): boolean {
    return proto3.util.equals(ChapterTopicInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchTopicResourceInfoRequest
 */
export class FetchTopicResourceInfoRequest extends Message<FetchTopicResourceInfoRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 4;
   */
  chapterId = 0;

  /**
   * @generated from field: int32 topic_id = 5;
   */
  topicId = 0;

  constructor(data?: PartialMessage<FetchTopicResourceInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchTopicResourceInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTopicResourceInfoRequest {
    return new FetchTopicResourceInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoRequest {
    return new FetchTopicResourceInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoRequest {
    return new FetchTopicResourceInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTopicResourceInfoRequest | PlainMessage<FetchTopicResourceInfoRequest> | undefined, b: FetchTopicResourceInfoRequest | PlainMessage<FetchTopicResourceInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchTopicResourceInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchTopicResourceInfoResponse
 */
export class FetchTopicResourceInfoResponse extends Message<FetchTopicResourceInfoResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.TopicResourceInfo data = 4;
   */
  data?: TopicResourceInfo;

  constructor(data?: PartialMessage<FetchTopicResourceInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchTopicResourceInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TopicResourceInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTopicResourceInfoResponse {
    return new FetchTopicResourceInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoResponse {
    return new FetchTopicResourceInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoResponse {
    return new FetchTopicResourceInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTopicResourceInfoResponse | PlainMessage<FetchTopicResourceInfoResponse> | undefined, b: FetchTopicResourceInfoResponse | PlainMessage<FetchTopicResourceInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchTopicResourceInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.TopicResourceInfo
 */
export class TopicResourceInfo extends Message<TopicResourceInfo> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 2;
   */
  chapterId = 0;

  /**
   * @generated from field: int32 topic_id = 3;
   */
  topicId = 0;

  /**
   * @generated from field: repeated geneo.content.common.apis.CategoryResourceMap category_resource_map = 4;
   */
  categoryResourceMap: CategoryResourceMap[] = [];

  constructor(data?: PartialMessage<TopicResourceInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.TopicResourceInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "category_resource_map", kind: "message", T: CategoryResourceMap, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicResourceInfo {
    return new TopicResourceInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicResourceInfo {
    return new TopicResourceInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicResourceInfo {
    return new TopicResourceInfo().fromJsonString(jsonString, options);
  }

  static equals(a: TopicResourceInfo | PlainMessage<TopicResourceInfo> | undefined, b: TopicResourceInfo | PlainMessage<TopicResourceInfo> | undefined): boolean {
    return proto3.util.equals(TopicResourceInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchChapterResourcesRequest
 */
export class FetchChapterResourcesRequest extends Message<FetchChapterResourcesRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 4;
   */
  chapterId = 0;

  constructor(data?: PartialMessage<FetchChapterResourcesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchChapterResourcesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchChapterResourcesRequest {
    return new FetchChapterResourcesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchChapterResourcesRequest {
    return new FetchChapterResourcesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchChapterResourcesRequest {
    return new FetchChapterResourcesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchChapterResourcesRequest | PlainMessage<FetchChapterResourcesRequest> | undefined, b: FetchChapterResourcesRequest | PlainMessage<FetchChapterResourcesRequest> | undefined): boolean {
    return proto3.util.equals(FetchChapterResourcesRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.ResourceInfo
 */
export class ResourceInfo extends Message<ResourceInfo> {
  /**
   * Unique identifier for the resource.
   *
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * Title of the resource.
   *
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * URL of the resource's poster image.
   *
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Estimated time to complete the resource in minutes.
   *
   * @generated from field: int32 estimated_time_in_min = 4;
   */
  estimatedTimeInMin = 0;

  /**
   * Type of the resource (e.g., video, document).
   *
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 5;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * Category type of the resource (e.g., science, history).
   *
   * @generated from field: geneo.content.db.ResourceCategoryEnum resource_category_type = 6;
   */
  resourceCategoryType = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * Rank of the resource for sorting or prioritization.
   *
   * @generated from field: int32 rank = 7;
   */
  rank = 0;

  /**
   * @generated from field: int64 createdBy = 8;
   */
  createdBy = protoInt64.zero;

  /**
   * @generated from field: optional geneo.content.db.SchoolContentInfo school_content_info = 9;
   */
  schoolContentInfo?: SchoolContentInfo;

  constructor(data?: PartialMessage<ResourceInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.ResourceInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 6, name: "resource_category_type", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 7, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "createdBy", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "school_content_info", kind: "message", T: SchoolContentInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceInfo {
    return new ResourceInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceInfo {
    return new ResourceInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceInfo {
    return new ResourceInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceInfo | PlainMessage<ResourceInfo> | undefined, b: ResourceInfo | PlainMessage<ResourceInfo> | undefined): boolean {
    return proto3.util.equals(ResourceInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.CategoryResourceMap
 */
export class CategoryResourceMap extends Message<CategoryResourceMap> {
  /**
   * @generated from field: geneo.content.db.ResourceCategoryEnum category = 1;
   */
  category = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string category_description = 2;
   */
  categoryDescription = "";

  /**
   * @generated from field: string category_title = 3;
   */
  categoryTitle = "";

  /**
   * @generated from field: repeated geneo.content.common.apis.ResourceInfo category_resources = 4;
   */
  categoryResources: ResourceInfo[] = [];

  /**
   * @generated from field: int32 module_id = 5;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 6;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  constructor(data?: PartialMessage<CategoryResourceMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.CategoryResourceMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 2, name: "category_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "category_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category_resources", kind: "message", T: ResourceInfo, repeated: true },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryResourceMap {
    return new CategoryResourceMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryResourceMap {
    return new CategoryResourceMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryResourceMap {
    return new CategoryResourceMap().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryResourceMap | PlainMessage<CategoryResourceMap> | undefined, b: CategoryResourceMap | PlainMessage<CategoryResourceMap> | undefined): boolean {
    return proto3.util.equals(CategoryResourceMap, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.ChapterResources
 */
export class ChapterResources extends Message<ChapterResources> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 2;
   */
  chapterId = 0;

  /**
   * @generated from field: repeated geneo.content.common.apis.CategoryResourceMap category_resource_map = 3;
   */
  categoryResourceMap: CategoryResourceMap[] = [];

  constructor(data?: PartialMessage<ChapterResources>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.ChapterResources";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "category_resource_map", kind: "message", T: CategoryResourceMap, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterResources {
    return new ChapterResources().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterResources {
    return new ChapterResources().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterResources {
    return new ChapterResources().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterResources | PlainMessage<ChapterResources> | undefined, b: ChapterResources | PlainMessage<ChapterResources> | undefined): boolean {
    return proto3.util.equals(ChapterResources, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchChapterResourcesResponse
 */
export class FetchChapterResourcesResponse extends Message<FetchChapterResourcesResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.ChapterResources data = 4;
   */
  data?: ChapterResources;

  constructor(data?: PartialMessage<FetchChapterResourcesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchChapterResourcesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ChapterResources },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchChapterResourcesResponse {
    return new FetchChapterResourcesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchChapterResourcesResponse {
    return new FetchChapterResourcesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchChapterResourcesResponse {
    return new FetchChapterResourcesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchChapterResourcesResponse | PlainMessage<FetchChapterResourcesResponse> | undefined, b: FetchChapterResourcesResponse | PlainMessage<FetchChapterResourcesResponse> | undefined): boolean {
    return proto3.util.equals(FetchChapterResourcesResponse, a, b);
  }
}

/**
 * Resource
 *
 * @generated from message geneo.content.common.apis.ResourceListResponse
 */
export class ResourceListResponse extends Message<ResourceListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.content.common.apis.ResourceInfo data = 4;
   */
  data: ResourceInfo[] = [];

  constructor(data?: PartialMessage<ResourceListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.ResourceListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ResourceInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceListResponse {
    return new ResourceListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceListResponse {
    return new ResourceListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceListResponse {
    return new ResourceListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceListResponse | PlainMessage<ResourceListResponse> | undefined, b: ResourceListResponse | PlainMessage<ResourceListResponse> | undefined): boolean {
    return proto3.util.equals(ResourceListResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchResourceContentRequest
 */
export class FetchResourceContentRequest extends Message<FetchResourceContentRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: string resource_id = 3;
   */
  resourceId = "";

  constructor(data?: PartialMessage<FetchResourceContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchResourceContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchResourceContentRequest {
    return new FetchResourceContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchResourceContentRequest {
    return new FetchResourceContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchResourceContentRequest {
    return new FetchResourceContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchResourceContentRequest | PlainMessage<FetchResourceContentRequest> | undefined, b: FetchResourceContentRequest | PlainMessage<FetchResourceContentRequest> | undefined): boolean {
    return proto3.util.equals(FetchResourceContentRequest, a, b);
  }
}

/**
 * Response message for FetchResourceContent.
 *
 * @generated from message geneo.content.common.apis.FetchResourceContentResponse
 */
export class FetchResourceContentResponse extends Message<FetchResourceContentResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.ResourceContent data = 4;
   */
  data?: ResourceContent;

  constructor(data?: PartialMessage<FetchResourceContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchResourceContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ResourceContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchResourceContentResponse {
    return new FetchResourceContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchResourceContentResponse {
    return new FetchResourceContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchResourceContentResponse {
    return new FetchResourceContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchResourceContentResponse | PlainMessage<FetchResourceContentResponse> | undefined, b: FetchResourceContentResponse | PlainMessage<FetchResourceContentResponse> | undefined): boolean {
    return proto3.util.equals(FetchResourceContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.ResourceContent
 */
export class ResourceContent extends Message<ResourceContent> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * @generated from field: int32 estimated_time_in_min = 4;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 5;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * @generated from field: geneo.content.db.ResourceCategoryEnum resource_category = 6;
   */
  resourceCategory = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string gcp_json_url = 7;
   */
  gcpJsonUrl = "";

  constructor(data?: PartialMessage<ResourceContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.ResourceContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 6, name: "resource_category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 7, name: "gcp_json_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContent {
    return new ResourceContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContent {
    return new ResourceContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContent {
    return new ResourceContent().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContent | PlainMessage<ResourceContent> | undefined, b: ResourceContent | PlainMessage<ResourceContent> | undefined): boolean {
    return proto3.util.equals(ResourceContent, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchQuestionsRequest
 */
export class FetchQuestionsRequest extends Message<FetchQuestionsRequest> {
  /**
   * @generated from field: repeated string questionIds = 1;
   */
  questionIds: string[] = [];

  constructor(data?: PartialMessage<FetchQuestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchQuestionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchQuestionsRequest {
    return new FetchQuestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchQuestionsRequest {
    return new FetchQuestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchQuestionsRequest {
    return new FetchQuestionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchQuestionsRequest | PlainMessage<FetchQuestionsRequest> | undefined, b: FetchQuestionsRequest | PlainMessage<FetchQuestionsRequest> | undefined): boolean {
    return proto3.util.equals(FetchQuestionsRequest, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchQuestionsResponse
 */
export class FetchQuestionsResponse extends Message<FetchQuestionsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.QuestionsData data = 4;
   */
  data?: QuestionsData;

  constructor(data?: PartialMessage<FetchQuestionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchQuestionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: QuestionsData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchQuestionsResponse {
    return new FetchQuestionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchQuestionsResponse {
    return new FetchQuestionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchQuestionsResponse {
    return new FetchQuestionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchQuestionsResponse | PlainMessage<FetchQuestionsResponse> | undefined, b: FetchQuestionsResponse | PlainMessage<FetchQuestionsResponse> | undefined): boolean {
    return proto3.util.equals(FetchQuestionsResponse, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.QuestionsData
 */
export class QuestionsData extends Message<QuestionsData> {
  /**
   * @generated from field: repeated geneo.content.db.Question questions = 1;
   */
  questions: Question[] = [];

  constructor(data?: PartialMessage<QuestionsData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.QuestionsData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "message", T: Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionsData {
    return new QuestionsData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionsData {
    return new QuestionsData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionsData {
    return new QuestionsData().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionsData | PlainMessage<QuestionsData> | undefined, b: QuestionsData | PlainMessage<QuestionsData> | undefined): boolean {
    return proto3.util.equals(QuestionsData, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchTopicResourceInfoRequestV2
 */
export class FetchTopicResourceInfoRequestV2 extends Message<FetchTopicResourceInfoRequestV2> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 4;
   */
  chapterId = 0;

  /**
   * @generated from field: int32 topic_id = 5;
   */
  topicId = 0;

  /**
   * @generated from field: optional int32 school_id = 6;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 section_id = 7;
   */
  sectionId?: number;

  constructor(data?: PartialMessage<FetchTopicResourceInfoRequestV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchTopicResourceInfoRequestV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTopicResourceInfoRequestV2 {
    return new FetchTopicResourceInfoRequestV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoRequestV2 {
    return new FetchTopicResourceInfoRequestV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoRequestV2 {
    return new FetchTopicResourceInfoRequestV2().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTopicResourceInfoRequestV2 | PlainMessage<FetchTopicResourceInfoRequestV2> | undefined, b: FetchTopicResourceInfoRequestV2 | PlainMessage<FetchTopicResourceInfoRequestV2> | undefined): boolean {
    return proto3.util.equals(FetchTopicResourceInfoRequestV2, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchTopicResourceInfoResponseV2
 */
export class FetchTopicResourceInfoResponseV2 extends Message<FetchTopicResourceInfoResponseV2> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.TopicResourceInfo data = 4;
   */
  data?: TopicResourceInfo;

  constructor(data?: PartialMessage<FetchTopicResourceInfoResponseV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchTopicResourceInfoResponseV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TopicResourceInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTopicResourceInfoResponseV2 {
    return new FetchTopicResourceInfoResponseV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoResponseV2 {
    return new FetchTopicResourceInfoResponseV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTopicResourceInfoResponseV2 {
    return new FetchTopicResourceInfoResponseV2().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTopicResourceInfoResponseV2 | PlainMessage<FetchTopicResourceInfoResponseV2> | undefined, b: FetchTopicResourceInfoResponseV2 | PlainMessage<FetchTopicResourceInfoResponseV2> | undefined): boolean {
    return proto3.util.equals(FetchTopicResourceInfoResponseV2, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchChapterResourcesRequestV2
 */
export class FetchChapterResourcesRequestV2 extends Message<FetchChapterResourcesRequestV2> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 4;
   */
  chapterId = 0;

  /**
   * @generated from field: optional int32 school_id = 5;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 section_id = 6;
   */
  sectionId?: number;

  constructor(data?: PartialMessage<FetchChapterResourcesRequestV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchChapterResourcesRequestV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchChapterResourcesRequestV2 {
    return new FetchChapterResourcesRequestV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchChapterResourcesRequestV2 {
    return new FetchChapterResourcesRequestV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchChapterResourcesRequestV2 {
    return new FetchChapterResourcesRequestV2().fromJsonString(jsonString, options);
  }

  static equals(a: FetchChapterResourcesRequestV2 | PlainMessage<FetchChapterResourcesRequestV2> | undefined, b: FetchChapterResourcesRequestV2 | PlainMessage<FetchChapterResourcesRequestV2> | undefined): boolean {
    return proto3.util.equals(FetchChapterResourcesRequestV2, a, b);
  }
}

/**
 * @generated from message geneo.content.common.apis.FetchChapterResourcesResponseV2
 */
export class FetchChapterResourcesResponseV2 extends Message<FetchChapterResourcesResponseV2> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.content.common.apis.ChapterResources data = 4;
   */
  data?: ChapterResources;

  constructor(data?: PartialMessage<FetchChapterResourcesResponseV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.common.apis.FetchChapterResourcesResponseV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ChapterResources },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchChapterResourcesResponseV2 {
    return new FetchChapterResourcesResponseV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchChapterResourcesResponseV2 {
    return new FetchChapterResourcesResponseV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchChapterResourcesResponseV2 {
    return new FetchChapterResourcesResponseV2().fromJsonString(jsonString, options);
  }

  static equals(a: FetchChapterResourcesResponseV2 | PlainMessage<FetchChapterResourcesResponseV2> | undefined, b: FetchChapterResourcesResponseV2 | PlainMessage<FetchChapterResourcesResponseV2> | undefined): boolean {
    return proto3.util.equals(FetchChapterResourcesResponseV2, a, b);
  }
}

