import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Timestamp } from '@bufbuild/protobuf';
import {
  ActionsPopup,
  ConnectedClassIndicator,
  ContentDetailCard,
  ContentLockPopup,
  HwProceedButton,
  IStyles,
  IconWrapper,
  ImageWrapper,
  InPageHeader,
  InfoBar,
  InstructionsPopup,
  Loader,
  LockToggleButton,
  NoContentCard,
  PrimaryButton,
  SecondaryButton,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  getEnumKeyByEnumValue,
  getLocalStorage,
  getMediaBasePath,
  getResourceCategory,
  getTeacherSubjectEnum,
  pxToRem,
  pxTovW,
  resourceTypeName,
  theme,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import {
  ChapterMetaInfo,
  CreationStatusEnum,
  DownloadedSubject,
  Module_ModuleCategoryEnum,
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  SessionModeEnum,
  SessionStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import {
  LessonContent,
  LessonInfo,
  TeacherLessonInfo,
} from '@protos/learning_management/lms.lesson.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { DownloadButtonWrapper } from '../../../components/DownloadButtonWrapper';
import { v4 as uuidv4 } from 'uuid';
import { useConnectedClassContext } from '../../../app/Context/ConnectedClassContextProvider';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  CONNECTED_CLASS_RESULTS,
  EDIT_LESSON_PLAN,
  HOME,
} from '../../../routeHandling/RoutesNomenclature';
import { findClassSubjectInfoFromClassSection } from '../../../utils/functions';
import { onResourceClick } from '../../../utils/resource';
import { interactionEvent } from '../../Auth/auth.events';
import { Steps } from '../../ConnectedClassRoom/StartClassroom/components/Steps';
import { aiBookOpenEvent } from '../../Home/home.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import {
  resetConnectedClassState,
  setConnectedClassSessionData,
} from '../reducer/connectedClass.slice';
import {
  resetTeachState,
  setLessonContent,
  setLessonPlanRedirectionPath,
  setSelectedLessonInfo,
  setTopicResources,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../reducer/teach.slice';
import {
  aiChapterOpenEvent,
  aiLessonCloseEvent,
  aiLessonCreateEvent,
  aiLessonOpenEvent,
  aiTopicOpenEvent,
} from '../teach.events';
import { AttendeceList, AttendencePopup } from './components/AttendeceList';

const styles: IStyles = {
  root: {
    backgroundColor: 'neutral.paleGrey',
    // height: '100vh',
    marginTop: pxToRem(10),
    marginBottom: pxTovW(10),
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)} 0`,
      md: `${pxTovW(20)}`,
      lg: 0,
    },
  },
  headingImage: {
    width: { xs: pxToRem(60), md: pxTovW(105) },
    height: { xs: pxToRem(60), md: pxTovW(105) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    objectFit: 'cover',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { md: 'space-between', lg: 'unset' },
    gap: { xs: pxToRem(40), md: pxToRem(20), lg: pxTovW(270) },
    padding: { lg: `${pxTovW(15)} ${pxTovW(240)}` },
    // margin: {
    //   xs: `${pxToRem(0)} ${pxToRem(20)} ${pxToRem(0)} ${pxToRem(20)}`,
    //   md: 0,
    // },
  },
  leftPanel: {
    width: { md: '65%', lg: pxTovW(444) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(27), md: pxTovW(30) },
  },
  rightPanel: {
    height: '100%',
    overflow: 'auto',
    paddingX: pxTovW(20),
    flexBasis: { md: '100%', lg: pxTovW(747) },
  },
  rightPanelHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: { xs: pxToRem(24), md: pxTovW(20) },
  },
  infoBox: {},
  editButton: {
    cursor: 'pointer',
    padding: 0,
    minWidth: 0,
    width: { xs: pxToRem(34), md: pxTovW(50) },
    height: { xs: pxToRem(34), md: pxTovW(50) },
    borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
    bgcolor: 'common.white',
    border: '1px solid #EEEEEE',
  },
  cardsContainer: {
    paddingTop: pxTovW(20),
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(20) },
    mb: { xs: pxToRem(100), md: pxTovW(0) },
  },
  item: {
    display: { xs: 'flex', md: 'none' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: pxToRem(5),
    '&>div': {
      display: 'flex',
      gap: pxToRem(2),
      justifyContent: 'center',
      alignItems: 'center',
    },
    // backgroundColor: 'red',
  },
};
interface IlockedPopupData {
  resourceId?: string;
  image: string;
  lessonId: string;
  title: string;
  moduleId?: number;
  category?: Module_ModuleCategoryEnum;
}
export default function TeachingFlow() {
  const [contentLoading, setContentLoading] = useState<
    'loading' | 'completed' | 'error' | 'invalid session'
  >('completed');
  const [isContentLockPopupOpen, setIsContentLockPopupOpen] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState<string | null>(
    null
  );
  const [lockedPopupData, setLockedPopData] = useState<IlockedPopupData | null>(
    null
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { base_station_details, connectedClass_session_data, students_data } =
    deserify(useAppSelector((state) => state.connectedClass));
  const [instructionModal, setInstructionModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { subject_id, topic_id, lesson_id, connected_class_room, chapter_id } =
    useParams();

  const { baseStationData, upsertConnectedClassResourceSessionApi } =
    useConnectedClassContext();
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV2ClientWithStatusCodeHandler,
    LessonCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonTeachAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  const lesson_session_id =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;

  const class_id =
    new URLSearchParams(location.search).get('classId') || undefined;
  const section = new URLSearchParams(location.search).get('section');

  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const featureInfo = user_info?.schoolDetails[0]?.featuresPlanInfo;
  console.log('featureInfo:', featureInfo);
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );

  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const class_subject_info =
    deserify(
      useAppSelector((state) => state.homeDashboard.class_subject_info)
    ) ||
    findClassSubjectInfoFromClassSection({
      userInfo: user_info,
      classId: Number(class_id),
      sectionId: Number(sectionId),
      subjectId: Number(subject_id),
    });

  const teacher_id = getLocalStorage('userId');
  const { addDownload, isDownloading } = useDownloadContext();
  const [fetchingState, setFetchingState] = useState(false);
  const [contentLockData, setContentLockData] =
    useState<ContentLockModuleData>();
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});
  const [lockedSelected, setLockedSelected] = useState<ContentLockStatusType>(
    ContentLockStatusType.CONTENT_LOCK_STATUS_UNDEFINED
  );

  const [openIntructionsPopup, setOpenIntructionsPopup] = useState(false);

  const [copyLpPopup, setCopyLpPopup] = useState(false);
  const [AttendencePopupDisplay, setAttendencePopupDisplay] = useState(false);
  const closePopup = () => {
    setCopyLpPopup(false);
  };

  const {
    unsubscribeFromFirestore,
    upsertConnectedClassSessionApi,
    resetConnectedClass,
  } = useConnectedClassContext();
  // /resource.estimatedTimeInMin

  // Copy of the selected Lesson Plan
  const { selected_lessons_info, chapter_resources } = deserify(
    useAppSelector((state) => state.teach)
  );
  const editClickHandler = async () => {
    // if LP belongs to another id a copy will be made
    const createdByTeacher =
      selected_lessons_info?.createdBy.toString() === teacher_id;
    const searchParams: Record<string, string> = {};
    if (class_id) searchParams['classId'] = class_id;
    if (sectionId) searchParams['sectionId'] = sectionId;
    if (section) searchParams['section'] = section;
    if (createdByTeacher) {
      navigate({
        pathname: `${EDIT_LESSON_PLAN}/${subject_id}/${chapter_id}/${topic_id}/${lesson_id}`,
        search: `?${createSearchParams(searchParams)}`,
      });
    } else {
      setCopyLpPopup(true);
    }
    await interactionEvent({
      url: 'Teacher_teaching_flow',
      context: 'Teaching_flow',
      name: 'EDIT',
    });
  };

  const [completedResourceId, setCompletedResourceId] = useState<string[]>([]);

  const { setSelectedFunction, isIpadOnly, setSelectedLogoClickHandler } =
    useGlobalContext();
  const redirectionPath = useAppSelector(
    (state) => state.teach.lesson_plan_redirection_path
  );

  const backButtonClick = async (navigateType = 'default') => {
    await aiLessonCloseEvent(lesson_id);
    console.log({
      lesson_session_id,
      lesson_id,
      completedResourceId,
      lessons_content,
    });
    if (lesson_session_id && lesson_id && lessons_content) {
      LessonTeachAPIServiceV1ClientWithStatusCodeHandler.updateTeacherLessonSession(
        {
          teacherId: teacher_id,
          teacherLessonSessionId: Number(lesson_session_id),
          lessonId: lesson_id,
          sessionStatus:
            completedResourceId.length < lessons_content?.resources.length
              ? SessionStatusEnum.SESSION_STATUS_EXITED
              : SessionStatusEnum.SESSION_STATUS_COMPLETED,
          endTime: Timestamp.fromDate(new Date()),
        }
      );
    }
    unsubscribeFromFirestore();
    if (
      base_station_details?.isOnline &&
      connectedClass_session_data?.connectedClassSessionId
    ) {
      connectedClassSessionCreation(SessionStatusEnum.SESSION_STATUS_EXITED);
    }
    resetConnectedClass();
    dispatch(resetConnectedClassState());
    dispatch(resetTeachState());
    if (redirectionPath) {
      dispatch(setLessonPlanRedirectionPath(undefined));
      dispatch(setUpdatedLessonSessionVisitedResourceInfo({}));
      if (navigateType === 'logo') navigate(HOME);
      else navigate(redirectionPath);
    } else {
      if (navigateType === 'default') {
        navigate(-1);
      } else {
        navigate(HOME);
      }
    }
  };

  useEffect(() => {
    setSelectedFunction(() => () => backButtonClick('default'));
    setSelectedLogoClickHandler(() => () => backButtonClick('logo'));
    return () => {
      setSelectedFunction(null);
      setSelectedLogoClickHandler(null);
    };
  }, [completedResourceId, lesson_session_id]);

  useEffect(() => {
    (async () => {
      if (!subject_id || !chapter_id || !topic_id || !lesson_id) {
        return;
      }
      setFetchingState(true);
      const indexDbData = await findDownloadedSubjectByParams({
        subjectId: Number(subject_id),
      });
      const meta = deepClone(downloadedMetaData);
      if (indexDbData.length > 0) {
        const data = indexDbData[0];
        const offlineData =
          await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
            {
              chapterId: Number(chapter_id),
              topicId: Number(topic_id),
              lessonplanId: lesson_id,
            }
          );
        meta[Number(chapter_id)] = offlineData.data;
      }
      setDownloadedMetaData(meta);
      setFetchingState(false);
    })();
    if (
      base_station_details?.isOnline &&
      !connectedClass_session_data?.connectedClassSessionId
    ) {
      setInstructionModal(true);
      // getClassStudentList();
      connectedClassSessionCreation(SessionStatusEnum.SESSION_STATUS_STARTED);
    } else {
      if (chapter_id && topic_id && class_subject_info?.subjectId) {
        fetchTopicResources({
          chapterId: chapter_id,
          subjectId: class_subject_info?.subjectId,
          topicId: topic_id,
        });
      }
    }
  }, []);

  async function fetchTopicResources({
    subjectId,
    chapterId,
    topicId,
  }: {
    subjectId: number;
    chapterId: string;
    topicId: string;
  }) {
    try {
      const response =
        await ContentCommonAPIServiceV2ClientWithStatusCodeHandler.fetchTopicResources(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            chapterId: Number(chapterId),
            topicId: Number(topicId),
            sectionId: class_subject_info?.sectionId,
          }
        );
      if (response) {
        if (response?.data) {
          response.data.categoryResourceMap.forEach((elem) => {
            elem.categoryResources.sort((a, b) => a.rank - b.rank);
          });
          const data = response.data;
          // console.log(typeof data, data);
          dispatch(setTopicResources(data));
        }
      }

      // setLoading(false);
    } catch (err) {
      // setError(err);
      console.log(err);
    }
  }
  const connectedClassSessionCreation = async (
    sessionStatus: SessionStatusEnum
  ) => {
    const sessionData = await upsertConnectedClassSessionApi({
      classId: class_subject_info?.classId,
      sectionId: class_subject_info?.sectionId,
      subject:
        subject_id && !isNaN(Number(subject_id))
          ? getTeacherSubjectEnum(
              Number(subject_id),
              user_info?.teachClassSubjects
            )
          : undefined,
      baseReceiverId: Number(base_station_details?.baseReceiverId),
      teacherId: teacher_id,
      lessonId: lesson_id,
      schoolId: user_info?.schoolDetails[0].schoolId,
      teacherLessonSessionId: Number(lesson_session_id),
      sessionStatus: sessionStatus,
      connectedClassSessionId:
        sessionStatus === SessionStatusEnum.SESSION_STATUS_EXITED ||
        sessionStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
          ? connectedClass_session_data?.connectedClassSessionId
          : undefined,
    });
    console.log('sessionData', sessionData);
    if (sessionStatus === SessionStatusEnum.SESSION_STATUS_EXITED) {
      return;
    }
    if (sessionData) {
      dispatch(setConnectedClassSessionData(sessionData));
    } else {
      dispatch(
        setToastInfo({
          label: 'upsertConnectedClassSessionApi err',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
  };

  // useEffect(() => {
  //   if (subject_id && !isNaN(Number(subject_id))) {
  //     (async () => {
  //       const indexDbData = await findDownloadedSubjectByParams({
  //         subjectId: Number(subject_id),
  //       });
  //       if (indexDbData.length > 0) {
  //         const data = indexDbData[0];
  //         setDownloadedSubject(data);
  //       }
  //     })();
  //   }
  // }, [currentDownload]);
  //API call to get the lock status of the lesson

  const getLessonLockedStatus = async (teacherId: string) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.moduleLockInfoFetch(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleId: Number(topic_id),
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
          }
        );
      const data = response?.data;
      if (data) {
        setContentLockData(data);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // API call create teacher lesson session
  async function lessonSessionInfo() {
    try {
      // setLoading(true);

      if (!lesson_id) return;
      await fetchLessonContent(lesson_id);

      const response =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
          {
            teacherId: BigInt(teacher_id),
            lessonId: lesson_id,
          }
        );
      setCompletedResourceId(response.data?.completedResourceIds || []);

      // setLoading(false);
    } catch (err) {
      // setLoading(false);
      // setError(err);
      console.log(err);
    }
  }

  //
  async function createLessonCopy(
    selectedLesson: LessonInfo | TeacherLessonInfo
  ) {
    if (!selectedLesson.lessonId) {
      return;
    }
    try {
      // setLoading(true);

      const response =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.upsertLesson({
          teacherId: BigInt(teacher_id),
          title: selectedLesson.title + ' (Copy)',
          subjectId: Number(subject_id),
          moduleId: selectedLesson.moduleId,
          moduleCategory: selectedLesson.moduleCategory,
          posterImageUrl: selectedLesson.posterImageUrl,
          resourceIds: selectedLesson.resourceIds,
          sourceLessonId: selectedLesson.lessonId,
          creationStatus: CreationStatusEnum.CREATION_STATUS_APPROVED,
          schoolClassSectionId: class_subject_info?.sectionId,
        });

      if (response?.data) {
        const data = response.data;
        // console.log('createLessonCopy', data);
        await aiLessonCloseEvent(lesson_id);
        dispatch(setSelectedLessonInfo(data));
        await aiLessonCreateEvent({
          lessonId: data.lessonId,
          name: selectedLesson.title + ' (Copy)',
          resourceIds: selectedLesson.resourceIds,
        });
        await aiLessonOpenEvent(data.lessonId);

        const searchParams: Record<string, string> = {};
        if (class_id) searchParams['classId'] = class_id;
        if (sectionId) searchParams['sectionId'] = sectionId;
        if (section) searchParams['section'] = section;
        navigate({
          pathname: `${EDIT_LESSON_PLAN}/${subject_id}/${chapter_id}/${topic_id}/${data.lessonId}`,
          search: `?${createSearchParams(searchParams)}`,
        });
      } else {
        dispatch(
          setToastInfo({
            variant: 'error',
            label: 'Error while making lesson copy',
            open: true,
          })
        );
      }

      // setLoading(false);
    } catch (err) {
      dispatch(
        setToastInfo({
          variant: 'error',
          label: 'Error while making lesson copy',
          open: true,
        })
      );
      // setLoading(false);
      // setError(err);
      console.log(err);
    }
  }

  // Getting all the Lesson content
  const { lessons_content, session_mode_teaching_flow } = deserify(
    useAppSelector((state) => state.teach)
  );

  async function fetchLessonContent(lessonId: string) {
    try {
      setContentLoading('loading');

      const response =
        await LessonCommonAPIServiceV1ClientWithStatusCodeHandler.fetchLessonContent(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            lessonId: lessonId,
          }
        );

      if (response) {
        if (isContentLockFeature && teacher_id) {
          await getLessonLockedStatus(teacher_id);
        }
        if (response?.data) {
          const data = response.data;
          // console.log('fetchLessonContent', data);
          // console.log(typeof data, data);
          dispatch(setLessonContent(data));

          await aiBookOpenEvent(class_subject_info?.bookId, uuidv4());
          await aiChapterOpenEvent(chapter_resources?.chapterId, uuidv4());
          await aiTopicOpenEvent(Number(topic_id));
          await aiLessonOpenEvent(lesson_id);
        }

        setContentLoading('completed');
      }
    } catch (err) {
      setContentLoading('error');
      console.log(err);
    }
  }

  useEffect(() => {
    lessonSessionInfo();
  }, [lesson_id]);

  // useEffect(() => {
  //   const fetchLessonLockedStatus = async () => {
  //     setIsSubmitted(false);
  //     try {
  //       if (isContentLockFeature && isSubmitted && teacher_id) {
  //         await getLessonLockedStatus(teacher_id);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching lesson lock status:', error);
  //       // Handle the error if necessary
  //     }
  //   };

  //   fetchLessonLockedStatus();
  // }, [isSubmitted]);

  // const lockedResource = contentLockData?.moduleUpdatedLockInfo?.children.find(
  //   (val) => Number(val.moduleId) === Number(chapter_id)
  // );

  const lockedResourceData =
    contentLockData?.moduleUpdatedLockInfo?.lessonsLockInfo.find(
      (val) => val.lessonId === lesson_id
    );
  const onLearnClicked = async (
    resourceId?: string,
    resourceType?: ResourceCategoryEnum
  ) => {
    if (!resourceId) {
      const allResourceIds =
        lessons_content?.resources.map((res) => res.resourceId) || [];
      if (!allResourceIds.length) {
        return;
      }
      const notCompletedResourceIds = allResourceIds.filter(
        (resId) => !completedResourceId.includes(resId)
      );
      resourceId = notCompletedResourceIds[0] || allResourceIds[0];
      resourceType = lessons_content?.resources.find(
        (res) => res.resourceId === resourceId
      )?.resourceCategoryType;
      if (!resourceId) {
        return;
      }
    }
    let connectedClassResourceSessionId: bigint | undefined;
    if (
      baseStationData?.sessionId &&
      resourceType === ResourceCategoryEnum.RESOURCE_CATEGORY_PRACTICE
    ) {
      const res = await upsertConnectedClassResourceSessionApi({
        connectedClassSessionId: BigInt(baseStationData?.sessionId),
        resourceId: resourceId,
        sessionStartTime: Timestamp.fromDate(new Date()),
        sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
      });
      if (res === undefined) {
        dispatch(
          setToastInfo({
            label: 'upsertConnectedClassResourceSessionApi err',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      connectedClassResourceSessionId = res?.connectedClassResourceSessionId;
    }
    onResourceClick(
      navigate,
      {
        resourceId,
        lessonId: lesson_id,
        lessonSessionId: Number(lesson_session_id),
        subjectId: Number(subject_id),
        topicId: Number(topic_id),
        section: class_subject_info?.section,
        classId: class_subject_info?.classId,
        chapterId: Number(chapter_id),
        connectedClassResourceSessionId:
          connectedClassResourceSessionId?.toString(),
        sectionId: class_subject_info?.sectionId,
      },
      session_mode_teaching_flow === SessionModeEnum.SESSION_MODE_TEACH
        ? SessionModeEnum.SESSION_MODE_LESSON_RESOURCE
        : SessionModeEnum.SESSION_MODE_PREPARE_RESOURCE
    );

    await interactionEvent({
      url: 'Teacher_teaching_flow',
      context: 'Teaching_flow',
      name: 'TEACH',
      lessonSessionId: lesson_session_id,
    });
  };

  const totalTimeOfResources = lessons_content?.resources.reduce(
    (acc, resource) => acc + resource.estimatedTimeInMin,
    0
  );
  // const getClassStudentList = async () => {
  //   try {
  //     const response =
  //       await LmsConnectedClassAPIServiceV1Client.getClassStudentList({
  //         teacherId: teacher_id,
  //         classId: class_subject_info?.classId,
  //         sectionId: class_subject_info?.sectionId,
  //       });
  //     // console.log('getBaseStationConnectionStatus response', response);
  //     if (response.status === 200) {
  //       const list = response.studentListData?.studentClickerData || [];
  //       dispatch(setStudentsData(list));
  //     }
  //   } catch (error: any) {
  //     console.log(error.message);
  //   }
  // };

  const handleClosePopup = () => {
    setLockedPopData(null);
    setIsContentLockPopupOpen(false);
  };

  const handleOpenPopup = (
    ev?: React.MouseEvent,
    isLocked?: number,
    lessonId?: string,
    resources?: ResourceInfo
  ) => {
    try {
      ev?.stopPropagation();

      // Log the locked status for debugging purposes
      console.log('Locked status:', isLocked);

      if (typeof isLocked === 'number') {
        setLockedSelected(isLocked);
      } else {
        console.warn('isLocked is not a valid number:', isLocked);
      }

      // Build the lockedPopupData object, ensuring defaults are provided for optional fields
      const lockedPopupData = {
        resourceId: resources?.resourceId || '',
        image: resources?.posterImageUrl
          ? getMediaBasePath(resources.posterImageUrl, 'processedMediaBucket')
          : '',
        lessonId: lessonId || '',
        title: resources?.title || '',
        moduleId: Number(topic_id),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
      };

      // Set the lockedPopupData state
      setLockedPopData(lockedPopupData);

      // Open the content lock popup
      setIsContentLockPopupOpen(true);
    } catch (error) {
      // Error handling
      console.error('Error occurred in handleOpenPopup:', error);

      // Display a user-friendly error message
      dispatch(
        setToastInfo({
          label: 'Failed to open the content lock popup. Please try again.',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const handleSubmit = async (ev?: React.MouseEvent) => {
    ev?.stopPropagation();
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.teacherContentLockUpdate(
          {
            teacherId: BigInt(teacher_id),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleInfo: {
              moduleId: lockedPopupData?.moduleId,
              category: lockedPopupData?.category,
              lockStatus: lockedSelected,
              resourceId: lockedPopupData?.resourceId
                ? lockedPopupData?.resourceId
                : '',
              lessonId: lockedPopupData?.lessonId,
            },
          }
        );
      const data = response;
      if (data) {
        await getLessonLockedStatus(teacher_id);
        handleClosePopup();
        setLockedPopData(null);
      }
    } catch (error) {
      console.log('Error:', error);
      dispatch(
        setToastInfo({
          label: 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
    // onClose(); // Close the popup after submission
  };

  const handleLockedRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = getEnumKeyByEnumValue(
      ContentLockStatusType,
      Number(event.target.value)
    );
    if (val) {
      setLockedSelected(ContentLockStatusType[val]);
    }
  };

  console.log('lockedResourceData:', contentLoading);

  return contentLoading === 'loading' ? (
    <Loader />
  ) : contentLoading === 'error' ? (
    <NoContentCard variant="error" icon="error" text="Error Occured" />
  ) : contentLoading === 'invalid session' ? (
    <NoContentCard variant="error" icon="error" text="Invalid session" />
  ) : (
    <Box>
      {/* {connected_class_room && ( */}
      {baseStationData?.sessionId && (
        <ConnectedClassIndicator
          variant="wifi"
          fullwidth
          setInstructionModal={setInstructionModal}
          isOnline={baseStationData.isOnline}
          instructionEvent={async () => {
            await interactionEvent({
              url: 'Teacher_teaching_flow',
              context: 'connected_classroom',
              name: 'INSTRUCTIONS',
            });
          }}
        />
      )}
      <Box sx={styles.root}>
        <InPageHeader
          title={
            <Heading
              selectedLessonInfo={lessons_content}
              totalResourceTime={totalTimeOfResources}
              headingRightElement={
                !fetchingState && (
                  <Box sx={{ marginLeft: { xs: 'auto', md: '2px' } }}>
                    <DownloadButtonWrapper
                      user={new TeacherLoginResponseType(user_info)}
                      downloadedSubject={
                        downloadedSubject
                          ? new DownloadedSubject(downloadedSubject)
                          : undefined
                      }
                      downloadedMetaData={downloadedMetaData}
                      setDownloadedMetaData={setDownloadedMetaData}
                      subjectId={Number(subject_id)}
                      classId={class_subject_info?.classId}
                      sectionId={class_subject_info?.sectionId}
                      chapterId={Number(chapter_id)}
                      topicId={Number(topic_id)}
                      lessonPlanId={lesson_id}
                      title={`Lesson Plan - ${lessons_content?.lessonTitle}`}
                      interactionEventUrl={'Teacher_teaching_flow'}
                      interactionEventContext={'teach'}
                    />
                  </Box>
                )
              }
            />
          }
          buttonText="START"
          buttonClickHandler={() => onLearnClicked()}
        />

        <Box sx={styles.mainContainer}>
          <Box sx={styles.leftPanel}>
            {lessons_content?.learningOutcomes &&
              lessons_content.learningOutcomes.length !== 0 && (
                <Box
                  sx={{
                    margin: {
                      xs: `${pxToRem(26)} ${pxToRem(4)} ${pxToRem(0)} ${pxToRem(
                        4
                      )}`,
                      md: 0,
                    },
                  }}
                >
                  <SecondaryButton
                    variant="outlined"
                    witharrow
                    fullWidth
                    onClick={async () => {
                      await interactionEvent({
                        url: 'Teacher_teaching_flow',
                        context: 'Drop_down',
                        name: 'LEARNING_OUTCOME',
                      });
                      setOpenIntructionsPopup(true);
                    }}
                  >
                    <Typography
                      variant={isIpadOnly ? 'h4' : 'h5'}
                      fontWeight="bold"
                      fontFamily={'poppins'}
                    >
                      Learning Outcome
                    </Typography>
                  </SecondaryButton>
                </Box>
              )}

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <InfoBar
                contentList={[
                  {
                    iconName: 'questions',
                    heading: lessons_content?.resources.length || 0,
                    subHeading: 'Resources',
                  },
                  {
                    iconName: 'clock',
                    heading: totalTimeOfResources || 0,
                    subHeading: 'Minutes',
                  },
                ]}
              />
            </Box>

            {!baseStationData?.sessionId && (
              <Box
                sx={{
                  height: { xs: pxToRem(52), md: pxTovW(120) },
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <PrimaryButton
                  fullWidth
                  onClick={() => onLearnClicked()}
                  sx={{
                    boxShadow: `inset 0 0 ${pxTovW(8)} #00602B , 0 0 ${pxTovW(
                      7
                    )} #0AA34F63`,
                    height: { xs: pxToRem(52), lg: pxTovW(70) },
                  }}
                >
                  <Typography
                    variant="bodyText"
                    fontWeight="bold"
                    color={'white'}
                    sx={{ letterSpacing: '1px' }}
                  >
                    START
                  </Typography>
                </PrimaryButton>
              </Box>
            )}

            {/* {connected_class_room && ( */}
            {baseStationData?.sessionId && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
                    backgroundColor: '#FFFFFFFF',
                    boxSizing: 'border-box',
                    boxShadow: `0px 0px ${pxTovW(11)} #00000029`,
                    padding: { xs: pxToRem(12), md: pxTovW(20) },
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderRight: '1px dashed lightGrey',
                      gap: { xs: pxToRem(5), md: pxTovW(10) },
                      paddingRight: { xs: pxToRem(27), md: pxTovW(15) },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: { xs: pxToRem(10), md: pxTovW(10) },
                        alignItems: 'center',
                      }}
                    >
                      <ImageWrapper
                        name="joined"
                        parentFolder="icons"
                        type="png"
                        styles={{
                          height: { xs: pxToRem(27), md: pxTovW(40) },
                          width: { xs: pxToRem(27), md: pxTovW(40) },
                        }}
                      />
                      <Typography variant="h3" fontWeight="bold">
                        {
                          (baseStationData?.keyPads || []).filter(
                            (keypad: any) => keypad.isOnline
                          ).length
                        }
                      </Typography>
                    </Box>
                    <Typography variant="h4">Joined</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderRight: '1px dashed lightGrey',

                      gap: { xs: pxToRem(5), md: pxTovW(10) },
                      paddingRight: { xs: pxToRem(27), md: pxTovW(15) },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: { xs: pxToRem(10), md: pxTovW(20) },
                        alignItems: 'center',
                      }}
                    >
                      <ImageWrapper
                        name="user2"
                        parentFolder="icons"
                        type="png"
                        styles={{
                          height: { xs: pxToRem(27), md: pxTovW(40) },
                          width: { xs: pxToRem(27), md: pxTovW(40) },
                        }}
                      />
                      <Typography variant="h3" fontWeight="bold">
                        {students_data?.length || 0}
                      </Typography>
                    </Box>
                    <Typography variant="h4">Total</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: { xs: pxToRem(5), md: pxTovW(10) },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: { xs: pxToRem(10), md: pxTovW(10) },
                        alignItems: 'center',
                      }}
                    >
                      <ImageWrapper
                        name="science"
                        parentFolder="icons"
                        type="png"
                        styles={{
                          height: { xs: pxToRem(27), md: pxTovW(40) },
                          width: { xs: pxToRem(27), md: pxTovW(40) },
                        }}
                      />
                      <Typography variant="h3" fontWeight="bold">
                        {class_subject_info?.classname}
                        {class_subject_info?.section}
                      </Typography>
                    </Box>
                    <Typography variant="h4">
                      {class_subject_info?.subject}
                    </Typography>
                  </Box>
                </Box>

                <PrimaryButton
                  fullWidth
                  onClick={async () => {
                    navigate({
                      pathname: CONNECTED_CLASS_RESULTS,
                      search: `?${createSearchParams({
                        lessonSessionId: lesson_session_id || '',
                      })}`,
                    });
                    await interactionEvent({
                      url: 'Teacher_teaching_flow',
                      context: 'navigation_bar',
                      name: 'VIEW_RESULTS',
                    });
                  }}
                  sx={{
                    boxShadow: `inset 0 0 ${pxTovW(8)} #00602B , 0 0 ${pxTovW(
                      7
                    )} #0AA34F63`,
                    height: { xs: pxToRem(52), md: pxTovW(70) },
                  }}
                >
                  <Typography
                    variant="bodyText"
                    fontWeight="bold"
                    color={'white'}
                    sx={{ letterSpacing: '1px' }}
                  >
                    VIEW RESULT
                  </Typography>
                </PrimaryButton>
              </>
            )}
            {/* {connected_class_room && ( */}
            {baseStationData?.sessionId &&
              (largeScreen ? (
                <AttendeceList />
              ) : (
                <SecondaryButton
                  variant="outlined"
                  onClick={() => setAttendencePopupDisplay(true)}
                  witharrow
                  styles={{
                    border: 'none',
                    boxShadow: {
                      xs: `0px 0px ${pxToRem(11)} #00000029`,
                    },
                    color: '#333333',
                  }}
                >
                  Status of Students
                </SecondaryButton>
              ))}
          </Box>

          <Box sx={styles.rightPanel}>
            <Box sx={styles.rightPanelHeading}>
              <Typography variant="h2">Teaching Flow</Typography>
              {!base_station_details?.isOnline && (
                <Button sx={styles.editButton} onClick={editClickHandler}>
                  <EditOutlinedIcon fontSize="medium" color="secondary" />
                </Button>
              )}
            </Box>

            {lessons_content && lessons_content.resources.length !== 0 ? (
              <Box sx={styles.cardsContainer}>
                {lessons_content?.resources.map(
                  (resource: ResourceInfo, resourceIndex: number) => (
                    <ContentDetailCard
                      isLocked={
                        isContentLockFeature &&
                        lockedResourceData &&
                        lockedResourceData.lockedResourceIds.includes(
                          resource.resourceId
                        )
                      }
                      key={resourceIndex}
                      locked={
                        isContentLockFeature &&
                        lockedResourceData && (
                          <LockToggleButton
                            isLocked={
                              lockedResourceData &&
                              lockedResourceData.lockedResourceIds.includes(
                                resource.resourceId
                              )
                            }
                            handleOpenPopup={(ev) => {
                              handleOpenPopup(
                                ev,
                                lockedResourceData &&
                                  lockedResourceData.lockedResourceIds.includes(
                                    resource.resourceId
                                  )
                                  ? ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                                  : ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED,
                                lessons_content?.lessonId,
                                resource
                              );
                            }}
                          />
                        )
                      }
                      variant="large"
                      tagName={getResourceCategory(
                        resource.resourceCategoryType
                      )}
                      image={getMediaBasePath(
                        resource.posterImageUrl,
                        resource.resourceCategoryType ===
                          ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                          ? 'userDataBucket'
                          : 'processedMediaBucket'
                      )}
                      heading={resource.title}
                      iconDetails={iconDetails(resource)}
                      rootStyle={{
                        width: '100%',
                        backgroundColor: 'common.white',
                      }}
                      showCompletedRibbon={completedResourceId.includes(
                        resource.resourceId
                      )}
                      onClick={() =>
                        onLearnClicked(
                          resource.resourceId,
                          resource.resourceCategoryType
                        )
                      }
                      showVideoIcon={
                        resource.resourceType ===
                        Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                      }
                    />
                  )
                )}
              </Box>
            ) : (
              <NoContentCard
                variant="info"
                icon="cards"
                text="No Content Available"
              />
            )}

            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                position: 'fixed',
                bottom: pxToRem(10),
                right: pxToRem(15),
              }}
              onClick={() => onLearnClicked()}
            >
              <HwProceedButton
                buttonTitle="START"
                clickHandler={() => onLearnClicked()}
                tabs={[
                  {
                    quantity:
                      lessons_content?.resources.length.toString() || '0',
                    title: 'Resources',
                  },
                  {
                    quantity: totalTimeOfResources?.toString() || '0',
                    title: 'Mins',
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
        <InstructionsPopup
          popupText={lessons_content?.learningOutcomes}
          open={openIntructionsPopup}
          handleClose={() => setOpenIntructionsPopup(false)}
        />

        <ActionsPopup
          open={copyLpPopup}
          handleClose={closePopup}
          fontSmall
          iconName="edit"
          popupText={`A copy of ${selected_lessons_info?.title} will be created`}
          yesClickHandler={() => {
            if (selected_lessons_info) createLessonCopy(selected_lessons_info);
          }}
          noClickHandler={closePopup}
        />
        {!largeScreen && (
          <AttendencePopup
            open={AttendencePopupDisplay}
            handleClose={() => setAttendencePopupDisplay(false)}
          />
        )}
        {/* <InfoPopup
          iconName="homework2"
          popupText={[
            <Box
              sx={{
                width: { xs: pxToRem(192), md: pxTovW(342) },
                height: { xs: pxToRem(42), md: pxTovW(94) },
                margin: 'auto',
                // width: '90%',
              }}
            >
              <Typography variant="h2" fontWeight="regular">
                Please Select between 2 options
              </Typography>
            </Box>,
            <Typography variant="h2">Do you want to select?</Typography>,
            <Box
              sx={{
                marginTop: { xs: pxToRem(41), md: pxTovW(64) },
                display: 'flex',
                gap: { xs: pxToRem(20), md: pxTovW(20) },
              }}
            >
              <PrimaryButton
                sx={{
                  width: { xs: pxToRem(140), md: pxTovW(226) },
                  height: { xs: pxToRem(53), md: pxTovW(85) },
                }}
                onClick={() => onLearnClicked()}
              >
                <Typography variant="h2" color="white">
                  TEACH
                </Typography>
              </PrimaryButton>
              <PrimaryButton
                sx={{
                  width: { xs: pxToRem(140), md: pxTovW(226) },
                  height: { xs: pxToRem(53), md: pxTovW(85) },
                  backgroundColor: '#007CDC',
                }}
                onClick={() => onLearnClicked()}
              >
                <Typography variant="h2" color="white">
                  PREPARE
                </Typography>
              </PrimaryButton>
            </Box>,
          ]}
          background="#007CDC"
          handleClose={() => setSelectPopup(false)}
          open={selectPopup}
        /> */}
      </Box>
      <Steps
        open={instructionModal}
        handleClose={async () => {
          setInstructionModal(false);
          await interactionEvent({
            url: 'Teacher_teaching_flow',
            context: 'popup',
            name: 'CLOSE',
          });
        }}
      />

      {lockedPopupData && (
        <ContentLockPopup
          open={isContentLockPopupOpen}
          onClose={handleClosePopup}
          onSubmit={(ev?: React.MouseEvent) =>
            lockedPopupData && handleSubmit(ev)
          }
          selectedValue={lockedSelected}
          onRadioChange={handleLockedRadioChange}
          lockDataObj={lockedPopupData}
        />
      )}
    </Box>
  );
}

interface HProps {
  selectedLessonInfo?: LessonContent;
  totalResourceTime?: number;
  headingRightElement?: React.ReactNode;
}
const Heading = ({
  selectedLessonInfo,
  totalResourceTime,
  headingRightElement,
}: HProps) => {
  // console.log('selectedLessonInfo:', selectedLessonInfo);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: pxToRem(10), md: pxTovW(20) },
        width: { xs: '100%', md: '70%' },
        alignItems: 'center',
      }}
    >
      <ImageWrapper
        name="chapterImage"
        type="png"
        styles={styles.headingImage}
        parentFolder="tempAssets"
        path={getMediaBasePath(
          selectedLessonInfo?.lessonPosterImageUrl,
          'processedMediaBucket'
        )}
      />
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(5), md: pxTovW(0) },
          flexDirection: 'column',
          width: { xs: '80%', md: '80%' },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <Typography
            variant="h1"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              wordWrap: 'break-word',
            }}
          >
            {selectedLessonInfo?.lessonTitle}
          </Typography>
          {headingRightElement}
        </Box>

        <Box
          sx={{
            display: 'flex',
            // bgcolor: 'blue',
            width: '100%',
            gap: pxToRem(5),
            alignItems: 'center',
          }}
        >
          <Box sx={styles.item}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconWrapper
                name="clock"
                size="md"
                type="png"
                parentFolder="icons"
              />
              <Typography variant="smallText">
                {totalResourceTime || 0}
              </Typography>

              <Typography variant="smallText">Minutes</Typography>
            </Box>
          </Box>
          <Box sx={styles.item}>
            <Box
              sx={{
                display: 'flex',

                alignItems: 'center',
              }}
            >
              <IconWrapper
                name="questions"
                size="small"
                type="png"
                parentFolder="icons"
              />
              <Typography variant="smallText">
                {selectedLessonInfo?.resources.length || 0}
              </Typography>

              <Typography variant="smallText">Resources</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const iconDetails = (resource: ResourceInfo) => {
  const retValue = [
    {
      iconName: 'clock',
      text: `${resource.estimatedTimeInMin} Min`,
    },
    {
      iconName: resourceTypeName(resource.resourceType).icon,
      text: resourceTypeName(resource.resourceType).name,
    },
  ];

  return retValue;
};
