// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.apis.proto (package geneo.lms.assessment.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchAssessmentsByModuleRequest, FetchAssessmentsByModuleResponse, GetTeacherAssessmentsListRequest, GetTeacherAssessmentsListResponse } from "./lms.assessment.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.assessment.apis.LmsAssessmentCommonAPIServiceV1
 */
export const LmsAssessmentCommonAPIServiceV1 = {
  typeName: "geneo.lms.assessment.apis.LmsAssessmentCommonAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.assessment.apis.LmsAssessmentCommonAPIServiceV1.fetchAssessmentsByModule
     */
    fetchAssessmentsByModule: {
      name: "fetchAssessmentsByModule",
      I: FetchAssessmentsByModuleRequest,
      O: FetchAssessmentsByModuleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.apis.LmsAssessmentCommonAPIServiceV1.getTeacherAssessmentsList
     */
    getTeacherAssessmentsList: {
      name: "getTeacherAssessmentsList",
      I: GetTeacherAssessmentsListRequest,
      O: GetTeacherAssessmentsListResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

