import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FileEnum } from '@protos/content_management/content.db_pb';
import { FC, useEffect, useState } from 'react';
import FilePreviewPopup from '../../elements/FilePreviewPopup';
import getDeviceType from '../../../commonUtils/getDeviceType';

import CustomPDFRenderer from './CustomPdfRenderer';
import { shouldIncludeCredentialsInRequest } from 'libs/shared-ui/src/commonUtils/images';
interface DocumentViewerProps {
  downloadUrl?: string;
  type: FileEnum;
  fileName?: string;
  open?: boolean;
  previewUrl?: string;
  closeFile?: () => void;
  gcpUrl?: string;
}

declare global {
  interface Window {
    Android: any;
  }
}

export const DocumentViewer: FC<DocumentViewerProps> = ({
  downloadUrl,
  type,
  fileName,
  open,
  closeFile,
  previewUrl,
  gcpUrl,
}) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);
  const maxRetries = 3; // Max retries to avoid infinite loop
  const retryInterval = 10000; // 10 seconds timeout between retries
  const android = window.Android;
  const deviceType = getDeviceType();
  useEffect(() => {
    // try {
    //   if (FileEnum.FILE_TYPE_PDF) {
    //     if (deviceType === 'android' && android?.disableAppRefresh) {
    //       android.disableAppRefresh();
    //     }
    //   }
    // } catch (error) {
    //   console.log(
    //     'An error occurred while attempting to disable app refresh on Android:',
    //     error
    //   );
    // }

    if (!iframeLoaded && reloadCount < maxRetries) {
      const timeout = setTimeout(() => {
        setReloadCount(reloadCount + 1); // Increment reload count
      }, retryInterval); // Retry after 10 seconds

      return () => clearTimeout(timeout); // Clear timeout when iframe loads or component unmounts
    }
  }, [iframeLoaded, reloadCount]);

  function downloadPdfToAndroid(urlForDownload: string): void {
    console.log('Pushing user details to Android');
    try {
      if (deviceType === 'android' && android?.downloadPdf) {
        android.downloadPdf(urlForDownload);
      } else {
        console.log('Android downloadPdf function not available');
      }
    } catch (error) {
      console.log('Error pushing user details to Android', error);
    }
  }

  const handleDownload = async () => {
    console.log('Download button clicked');
    const urlForDownload = downloadUrl || gcpUrl;
    if (!urlForDownload) {
      console.error('No download link available');
      return;
    }
    try {
      const options: RequestInit = {};
      if (shouldIncludeCredentialsInRequest(urlForDownload)) {
        options.credentials = 'include';
      }
      const response = await fetch(urlForDownload, options);
      if (!response.ok) throw new Error('Network response was not ok');
      // Extract filename from Content-Disposition header if available
      if (!fileName) {
        fileName = urlForDownload.split('/').pop() || 'downloaded-file';
      }
      if (deviceType === 'android') {
        downloadPdfToAndroid(urlForDownload);
      } else {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Release the object URL
        window.URL.revokeObjectURL(url);

        console.log('Download triggered');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };
  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };
  const pdfClose = () => {
    try {
      if (deviceType === 'android' && android?.enableAppRefresh) {
        android.enableAppRefresh();
      }
    } catch (error) {
      console.log('Error in pdfClose function:', error);
    }
    if (closeFile) {
      closeFile();
    }
  };

  let viewerComponent = null;
  switch (type) {
    case FileEnum.FILE_TYPE_IMAGE:
      viewerComponent = (
        <FilePreviewPopup
          open={open || false}
          onClose={closeFile}
          onDownload={handleDownload}
          fileName={fileName || ''}
          fileType={FileEnum.FILE_TYPE_IMAGE}
        >
          <img
            src={gcpUrl}
            alt="Document Viewer"
            style={{
              width: 'max-content',
              maxWidth: '80vw',
              height: 'auto',
              maxHeight: '75vh',
              display: 'block',
              margin: '0 auto',
            }}
          />
        </FilePreviewPopup>
      );
      break;
    case FileEnum.FILE_TYPE_PDF:
      viewerComponent = (
        <FilePreviewPopup
          open={open || false}
          onClose={pdfClose}
          onDownload={handleDownload}
          fileName={fileName || ''}
        >
          {gcpUrl ? (
            <CustomPDFRenderer file={gcpUrl} layoutType="multiple" />
          ) : (
            <Typography variant="h6" color="textSecondary">
              No PDF URL provided.
            </Typography>
          )}
        </FilePreviewPopup>
      );
      break;
    case FileEnum.FILE_TYPE_PRESENTATION: // Handle ppt files
    case FileEnum.FILE_TYPE_DOCUMENT:
      viewerComponent = (
        <FilePreviewPopup
          open={open || false}
          onClose={closeFile}
          onDownload={handleDownload}
          fileName={fileName || ''}
        >
          {/* Show loader if isLoading is true */}
          {previewUrl ? (
            <CustomPDFRenderer file={previewUrl} layoutType="multiple" />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              maxHeight="100%" // Adjust the height as needed
            >
              <Typography variant="elementBodyText" textAlign="center">
                Preview not available please download the file to view
              </Typography>
            </Box>
          )}
        </FilePreviewPopup>
      );
      break;
    case FileEnum.FILE_TYPE_URL:
      viewerComponent = (
        <FilePreviewPopup
          open={open || false}
          onClose={closeFile}
          fileName={fileName || ''}
        >
          {/* Show loader if isLoading is true */}
          {downloadUrl ? (
            <>
              {!iframeLoaded && ( // Show loader if iframe is not loaded
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <iframe
                title="Document Viewer"
                src={downloadUrl}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                onLoad={handleIframeLoad}
              />
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              maxHeight="100%" // Adjust the height as needed
            >
              <Typography variant="elementBodyText">
                Preview not available please download the file to view
              </Typography>
            </Box>
          )}
        </FilePreviewPopup>
      );
      break;
    //redirect to microsoft ppt
    // window.location.href = `https://view.officeapps.live.com/op/view.aspx?src=${src}`;
    default:
      // Handle unsupported document types or provide a default viewer
      break;
  }
  return <div>{viewerComponent}</div>;
};
