// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.admin.portal.apis.proto (package geneo.lms.admin.portal.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ModuleContentLock } from "@protos/learning_management/lms.common.apis_pb";
import { SchoolContentModuleNodeLock } from "@protos/school_management/school.db_pb";
import { ContentApprovalStatusEnum, ContentDeletionStatusEnum, Module_ModuleCategoryEnum, Remark } from "@protos/content_management/content.db_pb";
import { TeachClassSubjects } from "@protos/user_management/ums.login.apis_pb";
import { APIResponse_CodeEnum, APIResponse_StatusEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";

/**
 * @generated from enum geneo.lms.admin.portal.apis.SchoolTeacherContentApprovalStatus
 */
export enum SchoolTeacherContentApprovalStatus {
  /**
   * @generated from enum value: APPROVAL_STATUS_UNDEFINED = 0;
   */
  APPROVAL_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: APPROVAL_STATUS_PENDING = 1;
   */
  APPROVAL_STATUS_PENDING = 1,

  /**
   * @generated from enum value: APPROVAL_STATUS_COMPLETED = 2;
   */
  APPROVAL_STATUS_COMPLETED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SchoolTeacherContentApprovalStatus)
proto3.util.setEnumType(SchoolTeacherContentApprovalStatus, "geneo.lms.admin.portal.apis.SchoolTeacherContentApprovalStatus", [
  { no: 0, name: "APPROVAL_STATUS_UNDEFINED" },
  { no: 1, name: "APPROVAL_STATUS_PENDING" },
  { no: 2, name: "APPROVAL_STATUS_COMPLETED" },
]);

/**
 * @generated from enum geneo.lms.admin.portal.apis.ActionTypeEnum
 */
export enum ActionTypeEnum {
  /**
   * @generated from enum value: ACTION_TYPE_UNDEFINED = 0;
   */
  ACTION_TYPE_UNDEFINED = 0,

  /**
   * @generated from enum value: ACTION_TYPE_DELETE = 1;
   */
  ACTION_TYPE_DELETE = 1,

  /**
   * @generated from enum value: ACTION_TYPE_UPLOAD = 2;
   */
  ACTION_TYPE_UPLOAD = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ActionTypeEnum)
proto3.util.setEnumType(ActionTypeEnum, "geneo.lms.admin.portal.apis.ActionTypeEnum", [
  { no: 0, name: "ACTION_TYPE_UNDEFINED" },
  { no: 1, name: "ACTION_TYPE_DELETE" },
  { no: 2, name: "ACTION_TYPE_UPLOAD" },
]);

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminContentLockUpdateRequest
 */
export class AdminContentLockUpdateRequest extends Message<AdminContentLockUpdateRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 4;
   */
  subjectId = 0;

  /**
   * @generated from field: geneo.lms.common.apis.ModuleContentLock moduleInfo = 5;
   */
  moduleInfo?: ModuleContentLock;

  /**
   * @generated from field: optional bool is_update_required_for_all_sections = 6;
   */
  isUpdateRequiredForAllSections?: boolean;

  constructor(data?: PartialMessage<AdminContentLockUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminContentLockUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "moduleInfo", kind: "message", T: ModuleContentLock },
    { no: 6, name: "is_update_required_for_all_sections", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminContentLockUpdateRequest | PlainMessage<AdminContentLockUpdateRequest> | undefined, b: AdminContentLockUpdateRequest | PlainMessage<AdminContentLockUpdateRequest> | undefined): boolean {
    return proto3.util.equals(AdminContentLockUpdateRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminContentLockUpdateResponse
 */
export class AdminContentLockUpdateResponse extends Message<AdminContentLockUpdateResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<AdminContentLockUpdateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminContentLockUpdateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminContentLockUpdateResponse | PlainMessage<AdminContentLockUpdateResponse> | undefined, b: AdminContentLockUpdateResponse | PlainMessage<AdminContentLockUpdateResponse> | undefined): boolean {
    return proto3.util.equals(AdminContentLockUpdateResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentLockModuleData
 */
export class ContentLockModuleData extends Message<ContentLockModuleData> {
  /**
   * @generated from field: geneo.school.db.SchoolContentModuleNodeLock module_updated_lock_info = 1;
   */
  moduleUpdatedLockInfo?: SchoolContentModuleNodeLock;

  constructor(data?: PartialMessage<ContentLockModuleData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentLockModuleData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_updated_lock_info", kind: "message", T: SchoolContentModuleNodeLock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJsonString(jsonString, options);
  }

  static equals(a: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined, b: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined): boolean {
    return proto3.util.equals(ContentLockModuleData, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchRequest
 */
export class AdminModuleLockInfoFetchRequest extends Message<AdminModuleLockInfoFetchRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 4;
   */
  subjectId = 0;

  /**
   * @generated from field: optional int32 module_id = 5;
   */
  moduleId?: number;

  /**
   * @generated from field: optional geneo.content.db.Module.ModuleCategoryEnum category = 6;
   */
  category?: Module_ModuleCategoryEnum;

  constructor(data?: PartialMessage<AdminModuleLockInfoFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminModuleLockInfoFetchRequest | PlainMessage<AdminModuleLockInfoFetchRequest> | undefined, b: AdminModuleLockInfoFetchRequest | PlainMessage<AdminModuleLockInfoFetchRequest> | undefined): boolean {
    return proto3.util.equals(AdminModuleLockInfoFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchResponse
 */
export class AdminModuleLockInfoFetchResponse extends Message<AdminModuleLockInfoFetchResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<AdminModuleLockInfoFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminModuleLockInfoFetchResponse | PlainMessage<AdminModuleLockInfoFetchResponse> | undefined, b: AdminModuleLockInfoFetchResponse | PlainMessage<AdminModuleLockInfoFetchResponse> | undefined): boolean {
    return proto3.util.equals(AdminModuleLockInfoFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoRequest
 */
export class FetchAdminSchoolsInfoRequest extends Message<FetchAdminSchoolsInfoRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchAdminSchoolsInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdminSchoolsInfoRequest | PlainMessage<FetchAdminSchoolsInfoRequest> | undefined, b: FetchAdminSchoolsInfoRequest | PlainMessage<FetchAdminSchoolsInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdminSchoolsInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoResponse
 */
export class FetchAdminSchoolsInfoResponse extends Message<FetchAdminSchoolsInfoResponse> {
  /**
   * Status code
   *
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * Error code
   *
   * @generated from field: int32 code = 2;
   */
  code = 0;

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.SchoolDetails data = 4;
   */
  data: SchoolDetails[] = [];

  constructor(data?: PartialMessage<FetchAdminSchoolsInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: SchoolDetails, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdminSchoolsInfoResponse | PlainMessage<FetchAdminSchoolsInfoResponse> | undefined, b: FetchAdminSchoolsInfoResponse | PlainMessage<FetchAdminSchoolsInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdminSchoolsInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.SchoolDetails
 */
export class SchoolDetails extends Message<SchoolDetails> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: string school_name = 2;
   */
  schoolName = "";

  /**
   * @generated from field: optional string school_code = 3;
   */
  schoolCode?: string;

  /**
   * @generated from field: string school_logo_image_url = 4;
   */
  schoolLogoImageUrl = "";

  constructor(data?: PartialMessage<SchoolDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.SchoolDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "school_logo_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolDetails {
    return new SchoolDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolDetails {
    return new SchoolDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolDetails {
    return new SchoolDetails().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolDetails | PlainMessage<SchoolDetails> | undefined, b: SchoolDetails | PlainMessage<SchoolDetails> | undefined): boolean {
    return proto3.util.equals(SchoolDetails, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResquest
 */
export class FetchSchoolClassSectionCoursesInfoResquest extends Message<FetchSchoolClassSectionCoursesInfoResquest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<FetchSchoolClassSectionCoursesInfoResquest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResquest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolClassSectionCoursesInfoResquest | PlainMessage<FetchSchoolClassSectionCoursesInfoResquest> | undefined, b: FetchSchoolClassSectionCoursesInfoResquest | PlainMessage<FetchSchoolClassSectionCoursesInfoResquest> | undefined): boolean {
    return proto3.util.equals(FetchSchoolClassSectionCoursesInfoResquest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResponse
 */
export class FetchSchoolClassSectionCoursesInfoResponse extends Message<FetchSchoolClassSectionCoursesInfoResponse> {
  /**
   * Status code
   *
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * Error code
   *
   * @generated from field: int32 code = 2;
   */
  code = 0;

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ums.login.apis.TeachClassSubjects data = 4;
   */
  data: TeachClassSubjects[] = [];

  constructor(data?: PartialMessage<FetchSchoolClassSectionCoursesInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeachClassSubjects, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolClassSectionCoursesInfoResponse | PlainMessage<FetchSchoolClassSectionCoursesInfoResponse> | undefined, b: FetchSchoolClassSectionCoursesInfoResponse | PlainMessage<FetchSchoolClassSectionCoursesInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchSchoolClassSectionCoursesInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListRequest
 */
export class FetchSchoolContentApprovalRequestListRequest extends Message<FetchSchoolContentApprovalRequestListRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: optional int32 school_id = 2;
   */
  schoolId?: number;

  /**
   * @generated from field: optional geneo.lms.admin.portal.apis.ApprovalRequestType approval_request_type = 3;
   */
  approvalRequestType?: ApprovalRequestType;

  /**
   * @generated from field: optional int32 page_number = 4;
   */
  pageNumber?: number;

  constructor(data?: PartialMessage<FetchSchoolContentApprovalRequestListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "approval_request_type", kind: "message", T: ApprovalRequestType, opt: true },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolContentApprovalRequestListRequest {
    return new FetchSchoolContentApprovalRequestListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListRequest {
    return new FetchSchoolContentApprovalRequestListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListRequest {
    return new FetchSchoolContentApprovalRequestListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolContentApprovalRequestListRequest | PlainMessage<FetchSchoolContentApprovalRequestListRequest> | undefined, b: FetchSchoolContentApprovalRequestListRequest | PlainMessage<FetchSchoolContentApprovalRequestListRequest> | undefined): boolean {
    return proto3.util.equals(FetchSchoolContentApprovalRequestListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ApprovalRequestType
 */
export class ApprovalRequestType extends Message<ApprovalRequestType> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ActionTypeEnum action_type = 1;
   */
  actionType = ActionTypeEnum.ACTION_TYPE_UNDEFINED;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.SchoolTeacherContentApprovalStatus status = 2;
   */
  status = SchoolTeacherContentApprovalStatus.APPROVAL_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<ApprovalRequestType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ApprovalRequestType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "action_type", kind: "enum", T: proto3.getEnumType(ActionTypeEnum) },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(SchoolTeacherContentApprovalStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApprovalRequestType {
    return new ApprovalRequestType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApprovalRequestType {
    return new ApprovalRequestType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApprovalRequestType {
    return new ApprovalRequestType().fromJsonString(jsonString, options);
  }

  static equals(a: ApprovalRequestType | PlainMessage<ApprovalRequestType> | undefined, b: ApprovalRequestType | PlainMessage<ApprovalRequestType> | undefined): boolean {
    return proto3.util.equals(ApprovalRequestType, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListResponse
 */
export class FetchSchoolContentApprovalRequestListResponse extends Message<FetchSchoolContentApprovalRequestListResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.AdminApprovalRequests data = 4;
   */
  data?: AdminApprovalRequests;

  constructor(data?: PartialMessage<FetchSchoolContentApprovalRequestListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AdminApprovalRequests },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolContentApprovalRequestListResponse {
    return new FetchSchoolContentApprovalRequestListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListResponse {
    return new FetchSchoolContentApprovalRequestListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListResponse {
    return new FetchSchoolContentApprovalRequestListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolContentApprovalRequestListResponse | PlainMessage<FetchSchoolContentApprovalRequestListResponse> | undefined, b: FetchSchoolContentApprovalRequestListResponse | PlainMessage<FetchSchoolContentApprovalRequestListResponse> | undefined): boolean {
    return proto3.util.equals(FetchSchoolContentApprovalRequestListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminApprovalRequests
 */
export class AdminApprovalRequests extends Message<AdminApprovalRequests> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentUploadRequestInfo upload_requests = 1;
   */
  uploadRequests?: ContentUploadRequestInfo;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentDeletionRequestInfo delete_requests = 2;
   */
  deleteRequests?: ContentDeletionRequestInfo;

  constructor(data?: PartialMessage<AdminApprovalRequests>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminApprovalRequests";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upload_requests", kind: "message", T: ContentUploadRequestInfo },
    { no: 2, name: "delete_requests", kind: "message", T: ContentDeletionRequestInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminApprovalRequests {
    return new AdminApprovalRequests().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminApprovalRequests {
    return new AdminApprovalRequests().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminApprovalRequests {
    return new AdminApprovalRequests().fromJsonString(jsonString, options);
  }

  static equals(a: AdminApprovalRequests | PlainMessage<AdminApprovalRequests> | undefined, b: AdminApprovalRequests | PlainMessage<AdminApprovalRequests> | undefined): boolean {
    return proto3.util.equals(AdminApprovalRequests, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentDeletionRequestInfo
 */
export class ContentDeletionRequestInfo extends Message<ContentDeletionRequestInfo> {
  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest pending_requests = 1;
   */
  pendingRequests: ContentDeletionApprovalRequest[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest completed_requests = 2;
   */
  completedRequests: ContentDeletionApprovalRequest[] = [];

  constructor(data?: PartialMessage<ContentDeletionRequestInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentDeletionRequestInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pending_requests", kind: "message", T: ContentDeletionApprovalRequest, repeated: true },
    { no: 2, name: "completed_requests", kind: "message", T: ContentDeletionApprovalRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentDeletionRequestInfo {
    return new ContentDeletionRequestInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentDeletionRequestInfo {
    return new ContentDeletionRequestInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentDeletionRequestInfo {
    return new ContentDeletionRequestInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ContentDeletionRequestInfo | PlainMessage<ContentDeletionRequestInfo> | undefined, b: ContentDeletionRequestInfo | PlainMessage<ContentDeletionRequestInfo> | undefined): boolean {
    return proto3.util.equals(ContentDeletionRequestInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentUploadRequestInfo
 */
export class ContentUploadRequestInfo extends Message<ContentUploadRequestInfo> {
  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentUploadApprovalRequest pending_requests = 1;
   */
  pendingRequests: ContentUploadApprovalRequest[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentUploadApprovalRequest completed_requests = 2;
   */
  completedRequests: ContentUploadApprovalRequest[] = [];

  /**
   * @generated from field: int32 total_pending_requests_count = 3;
   */
  totalPendingRequestsCount = 0;

  /**
   * @generated from field: int32 total_completed_requests_count = 4;
   */
  totalCompletedRequestsCount = 0;

  constructor(data?: PartialMessage<ContentUploadRequestInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentUploadRequestInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pending_requests", kind: "message", T: ContentUploadApprovalRequest, repeated: true },
    { no: 2, name: "completed_requests", kind: "message", T: ContentUploadApprovalRequest, repeated: true },
    { no: 3, name: "total_pending_requests_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_completed_requests_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentUploadRequestInfo {
    return new ContentUploadRequestInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentUploadRequestInfo {
    return new ContentUploadRequestInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentUploadRequestInfo {
    return new ContentUploadRequestInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ContentUploadRequestInfo | PlainMessage<ContentUploadRequestInfo> | undefined, b: ContentUploadRequestInfo | PlainMessage<ContentUploadRequestInfo> | undefined): boolean {
    return proto3.util.equals(ContentUploadRequestInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentUploadApprovalRequest
 */
export class ContentUploadApprovalRequest extends Message<ContentUploadApprovalRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string teacher_name = 3;
   */
  teacherName = "";

  /**
   * @generated from field: int32 class_id = 4;
   */
  classId = 0;

  /**
   * @generated from field: string class = 5;
   */
  class = "";

  /**
   * @generated from field: int32 section_id = 6;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 7;
   */
  sectionName = "";

  /**
   * @generated from field: int32 subject_id = 8;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject_name = 9;
   */
  subjectName = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject_enum = 10;
   */
  subjectEnum = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp submitted_date = 11;
   */
  submittedDate?: Timestamp;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 12;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string chapter_name = 13;
   */
  chapterName = "";

  /**
   * @generated from field: optional string topic_name = 14;
   */
  topicName?: string;

  /**
   * @generated from field: string resource_id = 15;
   */
  resourceId = "";

  /**
   * @generated from field: string title = 16;
   */
  title = "";

  /**
   * @generated from field: geneo.content.db.ContentApprovalStatusEnum status = 17;
   */
  status = ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<ContentUploadApprovalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentUploadApprovalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "subject_enum", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 11, name: "submitted_date", kind: "message", T: Timestamp },
    { no: 12, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 13, name: "chapter_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "topic_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "status", kind: "enum", T: proto3.getEnumType(ContentApprovalStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentUploadApprovalRequest {
    return new ContentUploadApprovalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentUploadApprovalRequest {
    return new ContentUploadApprovalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentUploadApprovalRequest {
    return new ContentUploadApprovalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ContentUploadApprovalRequest | PlainMessage<ContentUploadApprovalRequest> | undefined, b: ContentUploadApprovalRequest | PlainMessage<ContentUploadApprovalRequest> | undefined): boolean {
    return proto3.util.equals(ContentUploadApprovalRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest
 */
export class ContentDeletionApprovalRequest extends Message<ContentDeletionApprovalRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string teacher_name = 3;
   */
  teacherName = "";

  /**
   * @generated from field: int32 class_id = 4;
   */
  classId = 0;

  /**
   * @generated from field: string class = 5;
   */
  class = "";

  /**
   * @generated from field: int32 section_id = 6;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 7;
   */
  sectionName = "";

  /**
   * @generated from field: int32 subject_id = 8;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject_name = 9;
   */
  subjectName = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject_enum = 10;
   */
  subjectEnum = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp submitted_date = 11;
   */
  submittedDate?: Timestamp;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 12;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string resource_id = 13;
   */
  resourceId = "";

  /**
   * @generated from field: string title = 14;
   */
  title = "";

  /**
   * @generated from field: geneo.content.db.ContentDeletionStatusEnum status = 15;
   */
  status = ContentDeletionStatusEnum.CONTENT_DELETION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<ContentDeletionApprovalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "subject_enum", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 11, name: "submitted_date", kind: "message", T: Timestamp },
    { no: 12, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 13, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "status", kind: "enum", T: proto3.getEnumType(ContentDeletionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentDeletionApprovalRequest {
    return new ContentDeletionApprovalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentDeletionApprovalRequest {
    return new ContentDeletionApprovalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentDeletionApprovalRequest {
    return new ContentDeletionApprovalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ContentDeletionApprovalRequest | PlainMessage<ContentDeletionApprovalRequest> | undefined, b: ContentDeletionApprovalRequest | PlainMessage<ContentDeletionApprovalRequest> | undefined): boolean {
    return proto3.util.equals(ContentDeletionApprovalRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminRequest
 */
export class UpdateContentApprovalByAdminRequest extends Message<UpdateContentApprovalByAdminRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ActionStatus action_status = 3;
   */
  actionStatus?: ActionStatus;

  /**
   * @generated from field: optional google.protobuf.Timestamp action_date = 4;
   */
  actionDate?: Timestamp;

  /**
   * @generated from field: optional geneo.content.db.Remark remark = 5;
   */
  remark?: Remark;

  constructor(data?: PartialMessage<UpdateContentApprovalByAdminRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "action_status", kind: "message", T: ActionStatus },
    { no: 4, name: "action_date", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "remark", kind: "message", T: Remark, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateContentApprovalByAdminRequest {
    return new UpdateContentApprovalByAdminRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminRequest {
    return new UpdateContentApprovalByAdminRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminRequest {
    return new UpdateContentApprovalByAdminRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateContentApprovalByAdminRequest | PlainMessage<UpdateContentApprovalByAdminRequest> | undefined, b: UpdateContentApprovalByAdminRequest | PlainMessage<UpdateContentApprovalByAdminRequest> | undefined): boolean {
    return proto3.util.equals(UpdateContentApprovalByAdminRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ActionStatus
 */
export class ActionStatus extends Message<ActionStatus> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ActionTypeEnum action_type = 1;
   */
  actionType = ActionTypeEnum.ACTION_TYPE_UNDEFINED;

  /**
   * @generated from field: bool is_approve = 2;
   */
  isApprove = false;

  /**
   * @generated from field: bool is_approve_for_all_section = 3;
   */
  isApproveForAllSection = false;

  /**
   * @generated from field: optional int32 approve_for_section_id = 4;
   */
  approveForSectionId?: number;

  constructor(data?: PartialMessage<ActionStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ActionStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "action_type", kind: "enum", T: proto3.getEnumType(ActionTypeEnum) },
    { no: 2, name: "is_approve", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_approve_for_all_section", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "approve_for_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActionStatus {
    return new ActionStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActionStatus {
    return new ActionStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActionStatus {
    return new ActionStatus().fromJsonString(jsonString, options);
  }

  static equals(a: ActionStatus | PlainMessage<ActionStatus> | undefined, b: ActionStatus | PlainMessage<ActionStatus> | undefined): boolean {
    return proto3.util.equals(ActionStatus, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminResponse
 */
export class UpdateContentApprovalByAdminResponse extends Message<UpdateContentApprovalByAdminResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string resource_id = 4;
   */
  resourceId = "";

  /**
   * @generated from field: string action_status = 5;
   */
  actionStatus = "";

  constructor(data?: PartialMessage<UpdateContentApprovalByAdminResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "action_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateContentApprovalByAdminResponse {
    return new UpdateContentApprovalByAdminResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminResponse {
    return new UpdateContentApprovalByAdminResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminResponse {
    return new UpdateContentApprovalByAdminResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateContentApprovalByAdminResponse | PlainMessage<UpdateContentApprovalByAdminResponse> | undefined, b: UpdateContentApprovalByAdminResponse | PlainMessage<UpdateContentApprovalByAdminResponse> | undefined): boolean {
    return proto3.util.equals(UpdateContentApprovalByAdminResponse, a, b);
  }
}

