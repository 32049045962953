import {
  ClassAndSubjectPopup,
  FilterSortPopup,
  IClassAndSubjectSelected,
  IStyles,
  ManageHomeworkCard,
  PrimaryButton,
  SecondaryButton,
  SecondaryOutlinedButton,
  ShimmerManageHwCard,
  deserify,
  getDeviceType,
  getLocalStorage,
  pxToRem,
  pxTovW,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { TabComp } from '../../../components/TabComp';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  ASSESMENT_LIBRARY,
  HOME,
  HOMEWORK_CHAPTER_SELECTION,
  HOMEWORK_COMPLETED,
  HOMEWORK_CURRENT,
  HOMEWORK_ONGOING,
} from '../../../routeHandling/RoutesNomenclature';
import { getSubjectsMap, subjectsWithClass } from '../../../utils/icons';
import { interactionEvent } from '../../Auth/auth.events';
import { setClassAndSubjectInfo } from '../../Home/reducer/homeDashboard.slice';
import { convertToSequenceInfo } from '../../Homework/ReviewHomework/functions';
import {
  setCreatedHomeworkStatus,
  setFetchedHwDetails,
  setModuleFilteredQuestions,
  setSelectedTasksInfo,
} from '../../Homework/reducer/homework.slice';
import {
  setAssignedData,
  setDraftData,
  setEndedData,
  setHomeworkListsData,
  setHwInfoData,
  setSelectedHwId,
} from '../reducer/manageHomework.slice';
import AssementRedirectionComponent from './AssementRedirectionComponent';
import AssementSelectionPopup from './AssementSelectionPopup';
import { AssessmentEnum } from '@protos/learning_management/lms.db_pb';
import {
  resetSelectedChapter,
  resetSelectedTopic,
  setAssessmentData,
} from '../../Assessment/reducer/assessmentFlow.slice';
import { AssessmentCard } from '../../../../../../libs/shared-ui/src/components/composites/AssessmentCard';

declare global {
  interface Window {
    Android: any;
  }
}
const styles: IStyles = {
  root: {
    width: '100vw',
    cursor: 'pointer',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    paddingLeft: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingTop: { xs: pxToRem(20), lg: pxTovW(40) },
    paddingBottom: { xs: pxToRem(50), lg: pxTovW(40) },
    paddingRight: { xs: pxToRem(20), lg: pxTovW(241) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'hidden',
    // backgroundColor: 'red',
  },
  assessmentBtnBox: {
    paddingBottom: { xs: pxToRem(24), md: pxTovW(78) },
    display: 'flex',
    flexDirection: { xs: 'column' },
    gap: { xs: pxToRem(31), md: pxTovW(74) },
    width: '100%',
  },
  assessmentTxt: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: 'flex-start', md: 'space-between' },
    gap: { xs: pxToRem(22), md: 0 },
  },
  header: {
    width: '100%',
    // height: { md: pxTovW(25) },
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingBottom: { xs: pxToRem(20), md: pxTovW(5) },
    // paddingLeft: { xs: pxToRem(20), md: 0 },
  },
  inputBox: {
    display: 'flex',
    // flexDirection: 'column',
    // backgroundColor: 'red',
    // justifyContent: 'center',
    justifyContent: { xs: 'space-between', md: 'center' },
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
  inputFeild: {
    backgroundColor: 'red',
    display: 'flex',
    height: { xs: pxToRem(50), md: pxTovW(56) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    border: '1px solid #CCE6FE',
    width: { xs: pxToRem(311), lg: pxTovW(457) },
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  inputFeildBox: {
    // border: '1px solid black',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: { xs: pxToRem(10), md: pxTovW(10) },
  },
};

export const ManageHomework = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const teacher_profile_id = getLocalStorage('userId');
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  //Draft
  const mapData = ['Assigned', 'Ended'];
  const { isIpadOnly } = useGlobalContext();
  const subMap = getSubjectsMap();
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(0);
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const {
    homework_list_data,
    draft_homework,
    assigned_homework,
    ended_homework,
    hw_info,
  } = deserify(useAppSelector((state) => state.manageHomework));

  const { assesment_flow_data } = deserify(
    useAppSelector((state) => state.assessment)
  );
  const [value, setValue] = useState(mapData[0]);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const { setSelectedFunction } = useGlobalContext();
  const backButtonClick = async () => {
    navigate(HOME);
  };
  const deviceType = getDeviceType();
  const android = window.Android;

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    (async () => {
      await interactionEvent({
        url: 'Teacher_homework_manage',
        context: 'Manage_Homework',
        name: mapData[0].toUpperCase(),
      });
    })();
    // setCount(assignedHomework?.length || 0);
    dispatch(resetSelectedChapter());
    dispatch(resetSelectedTopic());
    return () => {
      setSelectedFunction(null);
    };
  }, []);
  useEffect(() => {
    if (teacher_profile_id) {
      getTeacherHomeworkList(teacher_profile_id);
    }
  }, [class_subject_info, pageNumber, value]);

  // useEffect(() => {
  //   fetchAssessmentsByModule(class_subject_info?.subjectId);
  // }, [class_subject_info]);

  const handleChange = async (newValue: string) => {
    await interactionEvent({
      url: 'Teacher_homework_manage',
      context: 'Manage_Homework',
      name: newValue.toUpperCase(),
    });
    setValue(newValue);
    // setCountValue(newValue);
    setPageNumber(1);
  };

  const cardClickHandler = async (homeworkId: number, moduleId?: number) => {
    // console.log('homeworkId', homeworkId);

    await interactionEvent({
      url: '',
      context: value === 'Assigned' ? 'active' : 'ended',
      name:
        value === 'Assigned' ? 'ASSIGNED_HOMEWORK_OPEN' : 'ENDED_HOMEWORK_OPEN',
      pathSegments: pathSegments,
    });

    switch (value) {
      case 'Assigned':
        dispatch(setSelectedHwId(homeworkId));
        navigate(HOMEWORK_ONGOING);
        break;
      case 'Ended':
        dispatch(setSelectedHwId(homeworkId));
        navigate(HOMEWORK_COMPLETED);
        break;
      case 'Draft':
        if (moduleId) {
          getHomeworkDetails(homeworkId, moduleId.toString());
        }
        break;
      default:
        break;
    }
  };
  const getHomeworkDetails = async (homeworkId: number, moduleId: string) => {
    try {
      const response =
        await LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler.fetchHomeworkContent(
          {
            personId: teacher_profile_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            homeworkId: homeworkId,
          }
        );
      if (response.data) {
        const HwData = response.data;
        dispatch(setCreatedHomeworkStatus(HwData.homework?.taskCreationStatus));
        dispatch(
          setModuleFilteredQuestions({ questions: HwData.homeworkContent })
        );
        dispatch(setFetchedHwDetails(HwData.homework));
        const sequenceInfo = convertToSequenceInfo(
          HwData.questionsSequenceInfo
        );
        if (sequenceInfo) {
          dispatch(setSelectedTasksInfo(sequenceInfo));
        }
        navigate(`${HOMEWORK_CURRENT}/${moduleId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAssessmentsByModule = async (subjectId?: number) => {
    try {
      setLoading(true);
      const response =
        await LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchAssessmentsByModule(
          {
            personId: teacher_profile_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: subjectId,
            assessmentType: AssessmentEnum.ASSESSMENT_HOMEWORK,
          }
        );
      if (response?.data) {
        dispatch(setAssessmentData(response.data));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //^ SubjectPopup
  const [modalState, setModalState] = useState(false);
  const [assessmentSelectionPopupDisplay, setAssessmentSelectionPopupDisplay] =
    useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState<
    AssessmentEnum | undefined
  >();
  const [isNewHomeworkPopup, setIsNewHomeworkPopup] = useState(false);

  useEffect(() => {
    if (modalState == false) {
      setIsNewHomeworkPopup(false);
    }
  }, [modalState]);

  const classAndsubjectClickHandler = (
    inputClassInfo: IClassAndSubjectSelected
  ) => {
    dispatch(setClassAndSubjectInfo(inputClassInfo));
    // dispatch(setClassAndSubjectInfo(inputClassInfo));
    setModalState(false);
    if (isNewHomeworkPopup) {
      redirectToHomeworkChapterSelection(inputClassInfo.subjectId);
    }
    setPageNumber(1);
  };

  const getTeacherHomeworkList = async (teacherId: string) => {
    try {
      if (
        (value === 'Draft' && draft_homework && !draft_homework[pageNumber]) ||
        (value === 'Assigned' &&
          assigned_homework &&
          !assigned_homework[pageNumber]) ||
        (value === 'Ended' && ended_homework && !ended_homework[pageNumber])
      ) {
        setLoading(true);
      } else {
        setLoading(false);
      }

      // const response = await LmsTeacherHWAPIsSer({
      //   teacherId: BigInt(teacherId),
      // });
      const response =
        await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.getTeacherHomeworkList(
          {
            teacherId: BigInt(teacherId),
            sectionId: class_subject_info?.sectionId,
            subjectId: class_subject_info?.subjectId,
            pageNumber: pageNumber,
          }
        );
      // const response2 =
      //   await LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler.getTeacherAssessmentsList(
      //     {
      //       teacherId: BigInt(teacherId),
      //       sectionId: class_subject_info?.sectionId,
      //       subjectId: class_subject_info?.subjectId,
      //       pageNumber: pageNumber,
      //       assessmentType: AssessmentEnum.ASSESSMENT_HOMEWORK,
      //     }
      //   );
      const data = response.data;
      // console.log(response.data);
      if (data?.homeworkList) {
        const hw_data = data.homeworkList;
        const countInPagination = hw_data.hwListInfo?.countInPagination;

        dispatch(
          setDraftData({ ...draft_homework, [pageNumber]: hw_data.draft })
        );
        dispatch(
          setAssignedData({
            ...assigned_homework,
            [pageNumber]: hw_data.assigned,
          })
        );
        dispatch(
          setEndedData({ ...ended_homework, [pageNumber]: hw_data.ended })
        );

        dispatch(
          setHwInfoData({
            ...hw_info,
            hwListInfo: hw_data.hwListInfo,
            totalDraftPages: Math.ceil(
              (hw_data.hwListInfo?.draftHwCount || 0) / (countInPagination || 1)
            ),
            totalAssignedPages: Math.ceil(
              (hw_data.hwListInfo?.activeHwCount || 0) /
                (countInPagination || 1)
            ),
            totalEndedPages: Math.ceil(
              (hw_data.hwListInfo?.endedHwCount || 0) / (countInPagination || 1)
            ),
          })
        );

        // setAssignedHomework(data.homeworkList.assigned);
        // setDraftHomework(data.homeworkList.draft);
        // setEndedHomework(data.homeworkList.ended);

        dispatch(setHomeworkListsData(hw_data));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
      console.log(err);
    }
  };
  const sortData = (data: any, sortvalue: any) => {
    switch (sortvalue) {
      case 'Score: High to Low':
        return data
          .slice()
          .sort((a: any, b: any) => (b.classScore || 0) - (a.classScore || 0));
      case 'Score: Low to High':
        return data
          .slice()
          .sort((a: any, b: any) => (a.classScore || 0) - (b.classScore || 0));
      case 'Date':
        return data
          .slice()
          .sort(
            (a: any, b: any) =>
              Number(a.homeworkTargetDate.seconds) -
              Number(b.homeworkTargetDate.seconds)
          );
        return data;
      case 'Submission':
        return data
          .slice()
          .sort(
            (a: any, b: any) =>
              (b.studentsSubmissionCount || 0) -
              (a.studentsSubmissionCount || 0)
          );
      case 'Difficulty':
        return data
          .slice()
          .sort((a: any, b: any) => b.difficultyLevel - a.difficultyLevel);
      default:
        return data;
    }
  };

  const SortFunction = async (sortvalue: string) => {
    if (value === 'Assigned' && assigned_homework) {
      const temp = assigned_homework[pageNumber].slice() || [];
      const sortedData = sortData(temp, sortvalue);
      dispatch(
        setAssignedData({ ...assigned_homework, [pageNumber]: sortedData })
      );
    } else if (value === 'Ended' && ended_homework) {
      const temp = ended_homework[pageNumber].slice() || [];
      const sortedData = sortData(temp, sortvalue);
      dispatch(setEndedData({ ...ended_homework, [pageNumber]: sortedData }));
    } else if (value === 'Draft' && draft_homework) {
      const temp = draft_homework[pageNumber].slice() || [];
      const sortedData = sortData(temp, sortvalue);
      dispatch(setDraftData({ ...draft_homework, [pageNumber]: sortedData }));
    }
    await interactionEvent({
      url: 'Teacher_homework_manage',
      context: 'Sort_by',
      name: sortvalue.toUpperCase().replace(/ /g, '_'),
    });
  };

  const showPagination = () => {
    return (
      (value === 'Draft' && hw_info && hw_info.totalDraftPages > 0) ||
      (value === 'Assigned' && hw_info && hw_info.totalAssignedPages > 0) ||
      (value === 'Ended' && hw_info && hw_info.totalEndedPages > 0)
    );
  };

  const redirectToHomeworkChapterSelection = async (subjectId: number) => {
    await interactionEvent({
      url: 'Teacher_homework_manage',
      context: 'Manage_homework',
      name: 'NEW_HOMEWORK',
    });

    navigate(`${HOMEWORK_CHAPTER_SELECTION}/${subjectId}`);
  };

  const createAssessmentNavigation = () => {
    switch (selectedAssessment) {
      case AssessmentEnum.ASSESSMENT_HOMEWORK:
        navigate(
          `${HOMEWORK_CHAPTER_SELECTION}/${class_subject_info?.subjectId}`
        );
        break;
      default:
        return;
    }
  };

  const createLibraryNavigation = () => {
    if (!class_subject_info) {
      setModalState(true);
      try {
        if (deviceType === 'android' && android?.disableAppRefresh) {
          console.log('App refresh disabled on Android for home page');
          android.disableAppRefresh();
        }
      } catch (error) {
        console.log(
          'An error occurred while attempting to enable app refresh on Android:'
        );
      }
      // dispatch(resetAssessmentFlowState());
    } else {
      navigate(`${ASSESMENT_LIBRARY}`);
    }
  };
  const optionSelectionHandler = (assessmentType: AssessmentEnum) => {
    console.log('selectedAssessment 1.0');
    setSelectedAssessment(assessmentType);
  };
  console.log('selectedAssessment', selectedAssessment);
  const openAssessmentTypePopup = () => {
    if (!class_subject_info) {
      setModalState(true);
      try {
        if (deviceType === 'android' && android?.disableAppRefresh) {
          console.log('App refresh disabled on Android for home page');
          android.disableAppRefresh();
        }
      } catch (error) {
        console.log(
          'An error occurred while attempting to enable app refresh on Android:'
        );
      }
    } else {
      setAssessmentSelectionPopupDisplay(true);
    }
  };
  return (
    <Box sx={styles.root}>
      <Box sx={styles.assessmentBtnBox}>
        <Box sx={styles.assessmentTxt}>
          <Typography variant="h1">Assessment</Typography>
          <Box
            onClick={(e) => {
              setModalState(true);
              try {
                if (deviceType === 'android' && android?.disableAppRefresh) {
                  console.log('App refresh disabled on Android for home page');
                  android.disableAppRefresh();
                }
              } catch (error) {
                console.log(
                  'An error occurred while attempting to enable app refresh on Android:'
                );
              }
            }}
            sx={{ flex: { xs: 1, md: 'unset' } }}
          >
            {largeScreen ? (
              <SecondaryOutlinedButton
                size={isIpadOnly ? 'large' : 'medium'}
                witharrow={class_subject_info ? false : true}
                onClick={async () => {
                  await interactionEvent({
                    url: 'Teacher_homework_manage',
                    context: value,
                    name: 'CHOOSE_CLASS_SUBJECT',
                  });
                }}
              >
                {class_subject_info && class_subject_info.classname ? (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h4">{`${class_subject_info?.classname} ${class_subject_info?.section} - ${class_subject_info?.subject}`}</Typography>
                    <CloseIcon
                      sx={{
                        color: 'common.black',
                        fontSize: { xs: pxToRem(14), md: pxTovW(18) },
                      }}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        dispatch(setClassAndSubjectInfo(undefined));
                        dispatch(resetSelectedChapter());
                        dispatch(resetSelectedTopic());
                        setPageNumber(1);
                      }}
                    />
                  </Box>
                ) : (
                  <Typography variant="h4">Choose Class & Subject</Typography>
                )}
              </SecondaryOutlinedButton>
            ) : (
              <SecondaryButton
                witharrow
                onClick={async () => {
                  await interactionEvent({
                    url: 'Teacher_homework_manage',
                    context: 'Assigned',
                    name: 'CHOOSE_CLASS_SUBJECT',
                  });
                }}
              >
                <Typography variant="h5" fontWeight="bold" color="white">
                  {class_subject_info
                    ? `${class_subject_info.classname}${class_subject_info.section}-${class_subject_info.subject}`
                    : 'Choose Class & Subject'}
                </Typography>
              </SecondaryButton>
            )}
          </Box>
        </Box>
        <Box>
          <AssementRedirectionComponent
            createClickHandler={openAssessmentTypePopup}
            libraryClickHandler={createLibraryNavigation}
            hwCount={
              assesment_flow_data?.assessmentListInfo?.totalAssessmentsCount
            }
          />
        </Box>
      </Box>
      <Box sx={styles.header}>
        <Typography variant="h1">Manage Previous Assessments</Typography>
      </Box>
      <Box>
        <TabComp
          handleChange={handleChange}
          mapData={mapData}
          stateValue={value}
          // count={count}
          count={
            value === 'Draft'
              ? hw_info?.hwListInfo?.draftHwCount || 0
              : value === 'Assigned'
              ? hw_info?.hwListInfo?.activeHwCount || 0
              : value === 'Ended'
              ? hw_info?.hwListInfo?.endedHwCount || 0
              : 0
          }
        />
      </Box>

      <Box sx={styles.inputFeildBox}>
        <Box sx={styles.inputBox}>
          {/* {largeScreen ? (
            <Box sx={styles.inputFeild}>
              <InputField
                value={'Daily Homework'}
                name="task_name"
                // onChange={changeDetailsHandler}
                variant="outlined"
              ></InputField>
              <ExpandMoreOutlinedIcon fontSize="medium" />
            </Box>
          ) : (
            <SecondaryButton withArrow>8A Science</SecondaryButton>
          )} */}

          {/* <FilterSortPopup
            iconName="Filter"
            title="Filter"
            options={getAssessmentTypeByValue()}
          /> */}

          <FilterSortPopup
            iconName="Sort"
            title="Sort By"
            options={[
              'Date',
              'Submission',
              'Score: Low to High',
              'Score: High to Low',
            ]}
            sortFunction={SortFunction}
          />
        </Box>
      </Box>

      <Box
        sx={{
          // backgroundColor: 'red',
          paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
          paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
          // maxHeight: '70vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading === true ? (
          // <Loader />
          <ShimmerComponent />
        ) : error === true ? (
          <Typography>Error Occurred</Typography>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: pxToRem(20), md: pxTovW(20) },
              width: { xs: '100%', md: pxTovW(1800), lg: pxTovW(1441) },
            }}
          >
            <Grid
              container
              // columns={{ xs: 1, md: 3 }}
              rowGap={3}
              // columnGap={'26px'}
              sx={{ width: { xs: '100%', md: pxTovW(1800), lg: pxTovW(1441) } }}
            >
              {value === 'Draft' &&
                draft_homework &&
                draft_homework[pageNumber]?.map((card, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <ManageHomeworkCard
                      hwDraft={card}
                      subjectName={`${card.subject}`}
                      clickHandler={() =>
                        cardClickHandler(card.homeworkId, card.moduleId)
                      }
                      hwType="Draft"
                    />
                  </Grid>
                ))}

              {value === 'Assigned' &&
                assigned_homework &&
                assigned_homework[pageNumber]?.map((card, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    {/* <ManageHomeworkCard
                      maxMarks={card.maxMarks}
                      submissionType={card.submissionType}
                      hwAssigned={card}
                      subjectName={card.subject}
                      clickHandler={() => cardClickHandler(card.homeworkId)}
                      hwType="Assigned"
                    /> */}
                    <AssessmentCard
                      homework={card}
                      assessCardType="Assigned"
                      onClick={() =>
                        cardClickHandler(card.homeworkId, card.moduleId)
                      }
                    />
                  </Grid>
                ))}

              {value === 'Ended' &&
                ended_homework &&
                ended_homework[pageNumber]?.map((card, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    {/* <ManageHomeworkCard
                      maxMarks={card.maxMarks}
                      submissionType={card.submissionType}
                      hwAssigned={card}
                      subjectName={card.subject}
                      clickHandler={() => cardClickHandler(card.homeworkId)}
                      hwType="Ended"
                    /> */}
                    <AssessmentCard
                      homework={card}
                      assessCardType="Ended"
                      onClick={() => cardClickHandler(card.homeworkId)}
                    />
                  </Grid>
                ))}
            </Grid>

            {showPagination() ? (
              <Pagination
                page={pageNumber}
                count={
                  value === 'Draft'
                    ? hw_info?.totalDraftPages
                    : value === 'Assigned'
                    ? hw_info?.totalAssignedPages
                    : value === 'Ended'
                    ? hw_info?.totalEndedPages
                    : 0
                }
                color="primary"
                onChange={async (
                  event: React.ChangeEvent<unknown>,
                  page: number
                ) => {
                  setPageNumber(page);
                  if (pageNumber !== page) {
                    await interactionEvent({
                      url: 'Teacher_homework_manage',
                      context: 'navigation_bar',
                      name: 'PAGE_CHANGE',
                    });
                  }
                }}
                sx={{
                  width: 'max-content',
                  margin: 'auto',
                  marginTop: isIpadOnly ? pxToRem(5) : 0,
                }}
              />
            ) : (
              <Typography variant="h2" fontWeight="bold" margin="auto">
                No {value} Homework
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <ClassAndSubjectPopup
        modalState={modalState}
        setModalState={setModalState}
        displayData={subjectsWithClass}
        classSubjectsList={user_info?.teachClassSubjects}
        classAndsubjectClickHandler={classAndsubjectClickHandler}
      />
      <AssementSelectionPopup
        open={assessmentSelectionPopupDisplay}
        selectedValue={selectedAssessment}
        optionClickHandler={optionSelectionHandler}
        submitClickHandler={createAssessmentNavigation}
        handleClose={() => setAssessmentSelectionPopupDisplay(false)}
        classInfo={`${class_subject_info?.classname}${class_subject_info?.section} - ${class_subject_info?.subject}`}
      />
    </Box>
  );
};

const ShimmerComponent = () => {
  return (
    <Grid container rowGap={3} sx={{ width: { md: pxTovW(1100) } }}>
      {Array.from({ length: 12 }, (_, index) => (
        <Grid item xs={12} md={4} key={index}>
          <ShimmerManageHwCard key={index} />
        </Grid>
      ))}
    </Grid>
  );
};

function getAssessmentTypeByValue() {
  return Object.keys(AssessmentEnum)
    .filter(
      (key) =>
        isNaN(Number(key)) &&
        key !== 'ASSESSMENT_UNDEFINED' &&
        key !== 'ASSESSMENT_DIAGNOSTICS'
    )
    .map((key) => key.replace(/_/g, ' ').toLowerCase())
    .map((name) => name.replace('assessment', '').trim())
    .map((name) =>
      name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' ')
    );
}
